/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */
import React from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { isEmpty } from "../../../../../../../utils/validation";
import { ActionData } from "../../../../../../../models/classes/ActionData";
import { submitActionButtonData } from "../../../common/action";
import { PanelFieldData } from "../../../../../../../models/classes/PanelFieldData";

export const confirmBeforeSubmit = async (
  message: string,
  accept: any,
  reject: any,
  acceptClassName?: string,
  header?: string,
  icon?: string,
) => {
  // const accept = () => {
    // submitActionButtonData(
    //   dispatch,
    //   action,
    //   tableName,
    //   tableFields,
    //   rowData
    // );
  // };

  //const reject = () => {};

  // if (action.RequiredConfirmation) {
    confirmDialog({
      message: !isEmpty(message) ? message : "Please Confirm!",
      header: !isEmpty(header) ? header : "Confirmation",
      icon: !isEmpty(icon) ? icon : "pi pi-exclamation-triangle",
      acceptClassName: !isEmpty(acceptClassName)
        ? acceptClassName
        : "p-button-danger",
      accept,
      reject,
    });
  // }
};
