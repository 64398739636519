/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useCallback, useEffect, useState } from "react";

import { Tooltip } from 'primereact/tooltip';
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from "primereact/multiselect";
import { Password } from "primereact/password";
import { Editor } from 'primereact/editor';
import { PanelFieldData } from "../../models/classes/PanelFieldData";
import { IFieldValue } from "../../models/interfaces/IFieldValue";
import { isEmpty, isNull } from "../../utils/validation";
import './style.css'
import { KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/reducers";
import { emitData, emitLOVData, resetEmitData, resetEmitLOVData } from "../../redux/actions/formFieldItem";
import { RESET_EMIT_FIELD_DATA } from "../../redux/constants/beacon";
import { IAPIOption } from "../../models/interfaces/IAPIOption";
import api from "../../common/api";
import classNames from "classnames";
import { ControllerRenderProps } from "react-hook-form";
import { useGlobalState } from "../../App";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Workspace from "../../containers/Workspace";
import FileUpload from "../FileUpload";
import { IConsumeAPI } from "../../models/interfaces/IConsumeAPI";
import { consumeAPI } from "../../redux/actions/panelItem";
import { ProgressBar } from "primereact/progressbar";
import moment from "moment";
import useFocus from "../../utils/hooks/useFocus";
import _ from "lodash";
export interface IFieldProps {
    fieldId: number | string,
    fieldRef: any,
    fieldIndex: number,
    field: PanelFieldData,
    fieldValues: IFieldValue[] | undefined | null,
    setFieldValues: Function,
    customProps?: any,
    debug?: boolean,
    register?: ControllerRenderProps<Record<string, any>>,
    state?: any,
    container?: any
}

const Field: React.FC<IFieldProps> = (props) => {
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const beaconSelector = useSelector((state: State) => state.formFieldItem);
    const tableSelector = useSelector((state: State) => state.beaconItem);
    const panelItemSelector = useSelector((state: State) => state.panelItem);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingBar, setIsLoadingBar] = useState<any>({});
    const [, setSelectedDropdown] = useState<any>(null);
    const [dropdownOptions, setDropdownOptions] = useState<{ LabelValue: string, LabelDescription: string, Values?: any[] }[]>([]);
    const [visible, setVisible] = useState(false);
    const [lovData, setLovData] = useState<any>(null);
    const [appData, setAppData] = useGlobalState('appData');
    const [lastValue, setLastValue] = useState("");
    const [inputRef, setInputFocus] = useFocus<any>()

    let {
        fieldId, fieldIndex, field, fieldValues, setFieldValues, customProps, register, state
    } = props;

    const { chartName, container, setError, clearErrors } = customProps;
    const { widgetName, panelName, onValidation, queueProcess, setQueueProcess, triggerSubmit } = container!

    const resetField = () => {
        const resetValue: { [key: string]: any } = {
            "": "",
            "Calendar": field.DefaultValue || null,
            "Datetime": field.DefaultValue || null,
            "Dropdown": null
        };

        const value = resetValue[field.InputType || ''] || '';
        setLastValue(value)
        onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, { value: value })
        fieldValues![fieldIndex].value = undefined;
        setSelectedDropdown(value);
        setDropdownOptions([]);
        field.Value = value
        if (register) {
            register.onChange(value)
        }
    }

    const resetSubscriber = () => {
        resetEmitData(dispatch, {
            WidgetName: widgetName || '',
            PanelName: panelName || '',
            FieldName: field.FieldName
        })
    }

    const addQueue = (FieldName: string) => {
        // Check if the field already exists in the array
        const exists = queueProcess.some((item: any) => item.FieldName === FieldName);
        if (!exists) {
            setQueueProcess((prevQueue: any[]) => {
                return [
                    ...prevQueue,
                    { FieldName }, // Assuming 'field' was meant to be '{ FieldName }'
                ];
            });
        } else {
            console.log('Field already exists in the queue');
        }
    };
    
    
    const removeQueue = (FieldName: string) => {
        setQueueProcess((prevQueue: any[]) => {
            if (!prevQueue) {
                return [];
            }
            return prevQueue.filter((item: any) => item.FieldName !== FieldName);
        });
    };
    

    useEffect(() => {
        // Early return if panelItemSelector is empty
        if (isEmpty(panelItemSelector)) return;
    
        const { isLoadingCallApi, isSuccessCallApi, isErrorCallApi, successData, errorData, loadingData } = panelItemSelector;
        const { targetPanel, data, meta } = successData ?? errorData ?? loadingData ?? {};
        const { FieldName } = meta ?? {};        
    

        // Early return if the target panel does not match the chart name and field name
        if (`${targetPanel}_${FieldName || ''}` !== `${chartName}_${field.FieldName}`) return;

        // Check if the current field is the one being loaded
        const isCurrentField = FieldName === field.FieldName;

        console.log("isLoadingBar",  isLoadingCallApi, isSuccessCallApi, isErrorCallApi, FieldName, field.FieldName)
    
        // Set loading state based on API call status
        if (isCurrentField) {
            setIsLoading(isLoadingCallApi);
            setIsLoadingBar({ [field.FieldName]: isLoadingCallApi });
            field.validationStatus = {
                ...field.validationStatus,
                isLoadingCallApi, isSuccessCallApi, isErrorCallApi
            }
        }
    
        // Early return if there is an error
        if (isErrorCallApi && isCurrentField) {
            let message = null;
            const fieldName = _.get(errorData, "meta.FieldName", field.FieldName)

            if (!isEmpty(errorData)) {
                message = _.get(errorData, "response.ReturnMessage", "")
            }

            const validateInput = async (message:string) => {
                removeQueue(fieldName);

                if (inputRef) {
                    await setInputFocus();  // Await focus before setting error
                }

                if (onValidation) {
                    onValidation({
                        fieldName,
                        message: message || "",
                        type: 'manual'
                    })
                }

                setError(fieldName, {
                    message: message || "",
                    type: 'manual',
                    widgetName,
                    panelName,
                    handleBlur
                })
 
                field.validationStatus = {
                    ...field.validationStatus,
                    message: message || "",
                    type: 'manual',
                    inputRef,
                    setInputFocus,
                }
                
            }
            validateInput(message);
            return ;
        };
    
    
        console.log(isLoadingCallApi, isSuccessCallApi, isErrorCallApi, successData, errorData, loadingData)
        // Early return if data is empty or if the API call was not successful
        if (isEmpty(data) && !isSuccessCallApi) {
            return;
        };

        removeQueue(FieldName || field.FieldName);

        field.validationStatus = null;
        // Determine the data to be published
        const publishData = Array.isArray(data) && data.length > 0 ? data[0] : data;
    
        // Publish the field value
        publishFieldValue(publishData);
    
        // Set loading to false after processing
        setIsLoading(false);
        setIsLoadingBar({ [field.FieldName]: false });

        if (triggerSubmit) {
            setTimeout(() => {
                triggerSubmit()
            }, 10);
        }
    
    }, [panelItemSelector]);

    useEffect(() => {
        if (field.InputType === "Dropdown") {
            if (field?.ListOfValues?.length > 0) {
                const options = [];
                for (const listOfValues of field.ListOfValues) {
                    const values: string[] = Object.values(listOfValues);

                    options.push({
                        LabelDescription: values.length > 1 ? values[1] : values[0],
                        LabelValue: values[0]
                    })

                    if (values[0] === (fieldValues![fieldIndex].value || "")) {
                        setSelectedDropdown({
                            LabelDescription: values.length > 1 ? values[1] : values[0],
                            LabelValue: values[0]
                        })
                        publishFieldValue(fieldValues![fieldIndex].value)
                    }
                }

                setDropdownOptions(options)
            }

        }

    }, [field]); // Only re-run the effect if field changes

    function compareObjects(source1: any, source2: any): boolean {
        
        if (isEmpty(source1) || isEmpty(source2)) {
            return false
        }

        if (isEmpty(source2.PanelName) || isEmpty(source2.WidgetName)) {
            return source1.FieldName === source2.FieldName;
        } else {
            // Compare all fields
            return JSON.stringify(source1) === JSON.stringify(source2);
        }
    }

    useEffect(() => {
        if (!isEmpty(beaconSelector) && !isEmpty(field.SubscribeComponent)) {
            field.SubscribeComponent?.forEach((subscribeInfo) => {
                if (typeof beaconSelector.source === 'object' &&
                    !Array.isArray(beaconSelector.source) &&
                    beaconSelector.source !== null) {

                    if (compareObjects(beaconSelector.source, subscribeInfo?.Source)) {

                        if (!isEmpty(beaconSelector.type) && beaconSelector.type === RESET_EMIT_FIELD_DATA) {
                            console.log("RESET ME", field)
                            resetField();
                            setTimeout(() => {resetSubscriber();}, 10);
                        } else {
                            if (!isEmpty(beaconSelector?.data)) {

                                resetField();
                                setTimeout(() => {resetSubscriber();}, 10);

                                fillValueFromSource(field, beaconSelector)

                                if (subscribeInfo?.Actions) {
                                    subscribeInfo.Actions.forEach(async (action) => {
                                        if (action.ActionType === "FetchDropdownData") {
                                            if (action?.FetchOption?.APIParameters) {
                                                let inputParameterValues: any = [];

                                                action.FetchOption.APIParameters.forEach((param) => {
                                                    let value;

                                                    if (param.DefaultValue) {
                                                        value = param.DefaultValue;
                                                    }

                                                    if (
                                                        param?.ValueSource === "FieldSelectedItem"
                                                    ) {
                                                        /**
                                                         * beaconSelector.data: [
                                                         *  {
                                                         *      DataName: "FieldSelectedItem",
                                                         *      DataSource: "LabelValue" // emmited dropdown field name
                                                         *      EmittedValue: 1 // emmited dropdown field value
                                                         *  }
                                                         * ]
                                                         */
                                                        let fetchedData;

                                                        fetchedData = beaconSelector.data.find((emittedData: any) => (
                                                            emittedData.DataName === param.ValueSource &&
                                                            emittedData.DataSource === param.FieldName
                                                        ));

                                                        value = isEmpty(fetchedData) ? null : fetchedData.EmittedValue

                                                    }

                                                    inputParameterValues.push({
                                                        fieldName: param.FieldName,
                                                        value,
                                                        dataType: param.DataType
                                                    });

                                                });

                                                let requestBody = {
                                                    APIName: action.FetchOption?.APIName,
                                                    inputParameterValues,
                                                };

                                                const apiOption: IAPIOption = {
                                                    path: "query_management",
                                                    method: "POST",
                                                    data: requestBody
                                                }

                                                field.ListOfValues = [];
                                                try {

                                                    setIsLoading(true);
                                                    var loadingItem = {
                                                        LabelDescription: "Loading...",
                                                        LabelValue: ""
                                                    };

                                                    setDropdownOptions([loadingItem]);
                                                    setSelectedDropdown(loadingItem);
                                                    fieldValues![fieldIndex].value = "";
                                                    const response = await api(apiOption);

                                                    setDropdownOptions([]);
                                                    setSelectedDropdown(null);
                                                    fieldValues![fieldIndex].value = undefined;

                                                    if (response && response.success) {
                                                        if (!isEmpty(response.data)) {
                                                            let data = response.data;
                                                            field.ListOfValues = data;
                                                            const options: { LabelValue: string, LabelDescription: string }[] = [];
                                                            if (!isEmpty(data) && Array.isArray(data)) {
                                                                for (const listOfValues of data) {
                                                                    const values: string[] = Object.values(listOfValues);

                                                                    options.push({
                                                                        LabelDescription: values.length > 1 ? values[1] : values[0],
                                                                        LabelValue: values[0]
                                                                    })
                                                                }

                                                                setDropdownOptions(options)
                                                            }
                                                        }
                                                    }
                                                    setIsLoading(false);
                                                } catch (error) {
                                                    setDropdownOptions([]);
                                                    setSelectedDropdown(null);
                                                    setIsLoading(false);
                                                }



                                            }
                                        }
                                    })
                                }
                            }
                        }

                    }
                }

            });


        }

        if (!isEmpty(tableSelector) && !isEmpty(field.FieldConfiguration)) {
            if (!isEmpty(tableSelector.source) && typeof tableSelector.source === 'string') {

                if (tableSelector.source === 'RESET_LOV') {
                    setLovData(null)
                    return;
                } else if (beaconSelector.source === 'RESET_LOV' && beaconSelector.type === 'RESET_EMIT_LOV_DATA') {

                    if (field.FieldConfiguration && field.FieldConfiguration.Workspace.Widgets) {
                        field.FieldConfiguration.Workspace.Widgets.forEach(widget => {

                            if (widget.SubscribeComponent) {
                                widget.SubscribeComponent.forEach(fieldSub => {
                                    if (fieldSub?.Source.FieldName === beaconSelector.sourceField &&
                                        field.FieldName !== beaconSelector.sourceField) {

                                        if (appData) {
                                            for (let index = 0; index < (appData[`${chartName}___LOV`] ?? []).length; index++) {
                                                const element = appData[`${chartName}___LOV`][index] as any;
                                                if (!isEmpty(element) && !isEmpty(element['LOV_' + field.FieldName])) {
                                                    delete appData[`${chartName}___LOV`][index];
                                                    setLovData(null);
                                                    onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, { value: null })
                                                    register?.onChange(null)
                                                    register!.value = null
                                                    const component = document.getElementById(field.FieldName) as HTMLInputElement
                                                    if (component) {
                                                        component.value = fieldValues![fieldIndex].value;
                                                    }
                                                    resetEmitLOVData(dispatch, 'RESET_LOV', field.FieldName)
                                                }
                                            }
                                        }

                                    }
                                })
                            }
                        });
                    }
                }

                if (!isEmpty(tableSelector.data) && Array.isArray(tableSelector.data)) {
                    setLovData(tableSelector.data)
                }
            }
        }
    }, [beaconSelector, tableSelector])


    const fillValueFromSource = (field: PanelFieldData, beaconSelector: any) => {

        let fetchedData: any;
        fetchedData = beaconSelector.data.find((emittedData: any) => (
            emittedData.DataName === "FieldSelectedItem" &&
            emittedData.DataSource === field.FieldName
        ));

        if (!isEmpty(fetchedData)) {
            let value = fetchedData!['EmittedValue'];

            if (field.InputType === "Calendar") {
                if (field.DefaultValue && !isEmpty(value)) {
                    onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, { value: moment(value, field.FieldFormat ?? "YYYY-MM-DD").toDate() });
                } else {
                    const fieldFormat = isEmpty(field.FieldFormat) ? "MM/DD/YYYY" : field.FieldFormat!.toUpperCase().replace("yyyy", "yy"); // MM/DD/YYYY to mm/dd/yy
                    let valueDate = value;
                    if (moment(valueDate, fieldFormat).isValid()) {
                        onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, { value: moment(valueDate, fieldFormat).toDate()});
                    }
                }
    
            } else if (field.InputType === "Datetime") {
                if (field.DefaultValue && !isEmpty(value)) {
                    onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, { value: moment(value, field.FieldFormat ?? "YYYY-MM-DD HH:mm:ss").toDate() });
                } else {
                    const fieldFormatDateTime = isEmpty(field.FieldFormat) ? "MM/DD/YYYY hh:mm A" : field.FieldFormat!.toUpperCase().replace("yyyy", "yy"); // MM/DD/YYYY to mm/dd/yy
                    let valueDateTime = value;
                    if (moment(valueDateTime, fieldFormatDateTime).isValid()) {
                        onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, { value: moment(valueDateTime, fieldFormatDateTime).toDate() });
                    }
                }
    
            } else {
                onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, { value });
                if (register) {
                    register.onChange(value)
                }
            }

            setTimeout(async () => {
                await handleBlur()
            }, 50);
        }

       

    }

    const publishFieldValue = async (value: any) => {
        if (isEmpty(field?.PublishComponent)) return;
    
        for (const p of field?.PublishComponent!) {
            if (p.DataName !== "FieldSelectedItem") continue;
    
            const dataToEmit:any[] = [];
    
            if (field.InputType === "Dropdown" && field?.ListOfValues?.length) {
                field.ListOfValues.forEach(listOfValues => {
                    if (Object.values(listOfValues)[0] === (value || "")) {
                        Object.keys(listOfValues).forEach(key => {
                            dataToEmit.push({
                                DataName: "FieldSelectedItem",
                                DataSource: key,
                                EmittedValue: listOfValues[key]
                            });
                        });
                    }
                });
            } else if (field.InputType === "InputText") {
                Object.keys(value).forEach(key => {
                    dataToEmit.push({
                        DataName: "FieldSelectedItem",
                        DataSource: key,
                        EmittedValue: value[key]
                    });
                });
            }
    
            if (dataToEmit.length > 0) {
                await emitData(dispatch, {
                    WidgetName: widgetName || '',
                    PanelName: panelName || '',
                    FieldName: field.FieldName
                }, dataToEmit);
            }
        }


    }

    const handleBlur = useCallback(async () => {
        if (!isEmpty(isLoadingBar[field.FieldName]) && isLoadingBar[field.FieldName]) return;
        if (!isEmpty(field)) {
            const currentValue = inputRef!.current!.value;
            console.log("currentValue", currentValue)
            if (isEmpty(currentValue)) {
                if (!field.Mandatory) {
                    field.validationStatus = null
                }
                onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, { value: "" });
                register!.value = ""
                clearErrors()
                setLastValue(currentValue);
                return;
            }

            console.log("currentValue", currentValue, "lastValue", lastValue)
            
            if (currentValue !== lastValue || !isEmpty(field.validationStatus)) {
                setTimeout(() => {resetSubscriber();}, 50);
                setLastValue(currentValue);
                onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, { value: "" });
                if (!isEmpty(field.Actions)) {
                    addQueue(field.FieldName)
                    let inputParameterValues: any = [];
                    inputParameterValues.push({
                        fieldName: field.FieldName,
                        value: currentValue,
                        dataType: field.DataType
                    });
                    console.log(chartName, "chartName")
                    field.validationStatus = null;
                    for (let index = 0; index < field.Actions!.length; index++) {
                        const action = field.Actions![index];
                        const apiOption: IConsumeAPI = {
                            targetPanel: chartName,
                            meta: {
                                FieldName: field.FieldName
                            },
                            APIName: action.APIName,
                            inputParameterValues,
                            UseQueue: false,
                            isAPIForDataTable: false,
                            isCallApiOnly: true
                        }
                        consumeAPI(dispatch, apiOption, (response:any) => {
                            removeQueue(field.FieldName)
                        });
                        field.validationStatus = {};
                    }
                }
            }
        }

    }, [lastValue, field]);

    const closeDialog = () => {
        setVisible(false);
        setLovData(null);
    };

    const onFieldValueChanged = (

        fieldValues: IFieldValue[],
        setFieldValues: Function,
        fieldIndex: number,
        event: any,
        intRange?: {
            min: number,
            max: number
        }
    ) => {
        // Copy old field values
        const newFieldValues = [...fieldValues];

        // Some field's value is comes from event.target.value, some comes from event.value
        const setNewValue = () => {
            typeof event.value !== "undefined" ?
                newFieldValues[fieldIndex].value = event.value :
                newFieldValues[fieldIndex].value = event.target.value;
            setFieldValues(newFieldValues);
        };
        if (typeof event.value === "undefined") {
            resetSubscriber();
        }

        if (intRange) {
            // If the value is integer and need to be validated by minimum and maximum value
            // If the value is string, make sure it's not null or undefined
            if (
                (parseInt(event.target.value) >= intRange.min && parseInt(event.target.value) <= intRange.max) ||
                isEmpty(event.target.value)
            ) setNewValue();
        } else setNewValue();
    };

    if (fieldValues) {
        if (isNull(fieldValues![fieldIndex].value)) {
            let defaultValue = !isNull(customProps?.defaultValue) ? customProps["defaultValue"] : null;

            if (field.InputType === "Dropdown" || field.InputType === "MultiSelect") {
                if (field.ListOfValues != null && field.ListOfValues.length > 0) {
                    let selectedValues: any[] = field.ListOfValues.filter((f) => f.hasOwnProperty("HPO_DEFAULT_VALUE") && f.HPO_DEFAULT_VALUE != null && f.HPO_DEFAULT_VALUE == 1)
                        .map((value) => {
                            const values = Object.keys(value).filter(key => key !== "HPO_DEFAULT_VALUE").map(key => value[key]);
                            return {
                                LabelDescription: values.length > 1 ? values[1] : values[0],
                                LabelValue: values[0],
                                Values: values
                            }
                        });
                    if (field.InputType === "Dropdown") {
                        if (selectedValues.length > 0) {
                            defaultValue = selectedValues[0];
                        }
                    } else {
                        defaultValue = selectedValues.map((value) => value.LabelValue);
                    }
                }
            }

            if (!isNull(defaultValue)) {
                onFieldValueChanged(fieldValues, setFieldValues, fieldIndex, { value: defaultValue });
                setSelectedItems(Array.isArray(defaultValue) ? defaultValue : [defaultValue]);
            }
        }

        if (field && !field.hasOwnProperty('Editable')) {
            Object.assign(field, { Editable : true })
        }

        switch (field.InputType) {
            case "Calendar":

                return (
                    <>
                        {!isEmpty(field.Tooltip) ? <Tooltip target={`#Calendar-${fieldId.toString().replace(",", "")}`} position={field.TooltipPosition ?? 'right'} content={field.Tooltip} /> : null}
                        <KeyboardDatePicker
                            {...register}
                            id={field.FieldName.toString().replace(",", "")}
                            className={classNames(!isEmpty(state) && { 'p-invalid': state.invalid })}
                            key={`date-${field.FieldName}`}
                            readOnly={!field.Editable}
                            //variant="inline"
                            //className={classes.dateTimePicker}
                            // id={`Calendar-${field.FieldName.toString().replace(",", "")}`}
                            name={field.FieldName}
                            value={fieldValues![fieldIndex].value ? fieldValues![fieldIndex].value : null}
                            format={field.FieldFormat || "MM/DD/YYYY"}
                            onChange={(e) => {
                                onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, {
                                    value: isEmpty(e) ? "" : e?.toDate().toLocaleDateString()
                                })
                                if (register) {
                                    register.onChange(isEmpty(e) ? "" : e?.toDate().toLocaleDateString())
                                }
                            }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            showTodayButton
                        />
                    </>
                );
            case "Datetime":
                return (
                    <>
                        <div data-tooltip={field.Tooltip ? (field.TooltipPosition || "right") : null} aria-label={field.Tooltip}>
                            <KeyboardDateTimePicker
                                {...register}
                                id={field.FieldName.toString().replace(",", "")}
                                className={classNames(!isEmpty(state) && { 'p-invalid': state.invalid })}
                                key={`DateTime-${field.FieldName}`}
                                readOnly={!field.Editable}
                                //variant="inline"
                                //className={classes.dateTimePicker}
                                //id={`DateTime-${field.FieldName.toString().replace(",", "")}`}
                                name={field.FieldName}
                                value={fieldValues![fieldIndex].value ? fieldValues![fieldIndex].value : null}
                                format={field.FieldFormat || "MM/DD/YYYY hh:mm A"}
                                onChange={(e) => {
                                    onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, {
                                        value: isEmpty(e) ? "" : e?.toDate()
                                    })

                                    if (register) {
                                        register.onChange(isEmpty(e) ? "" : e?.toDate())
                                    }

                                }}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                showTodayButton
                            />
                        </div>
                    </>
                );
            case "Dropdown":
                let showValue: boolean;

                const dropdownTemplate = (option: any) => {
                    return (
                        <div>
                            {showValue ? (
                                <>
                                    <span>{option.LabelValue}</span>
                                    <br />
                                </>
                            ) : null}
                            {
                                field.DisplayAllField && option.Values ? option.Values.map((element: any, index: any) =>
                                    index > 0 ? (<span> {element} </span>) : null
                                ) : <span>{option.LabelDescription}</span>
                            }
                        </div>
                    );
                };

                const dropdownSelectedTemplate = (option: any) => {
                    return (
                        <div>
                            {showValue && option ? (
                                <>
                                    <span>{option.LabelValue}</span>
                                    <br />
                                </>
                            ) : null}
                            <span>{option ? option.LabelDescription : ""}</span>
                        </div>
                    );
                };

                return (
                    <Dropdown
                        {...register}
                        id={field.FieldName}
                        className={classNames(!isEmpty(state) && { 'p-invalid': state.invalid })}
                        optionLabel="LabelDescription"
                        optionValue="LabelValue"
                        options={dropdownOptions}
                        onChange={(e) => {
                            publishFieldValue(e.value)
                            onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, e)
                            fieldValues![fieldIndex].value = e.value;
                            if (register) {
                                register.onChange(e.value)
                            }
                        }
                        }
                        readOnly={!field.Editable}
                        disabled={isLoading || !field.Editable}
                        virtualScrollerOptions={!isEmpty(field.VirtualScrollerOptions) && field.VirtualScrollerOptions?.Enable ? {
                            itemSize: field.VirtualScrollerOptions.ItemSize || 38
                        } : {
                            itemSize: 38
                        }}
                        style={{ ...customProps?.style }}
                        valueTemplate={dropdownSelectedTemplate}
                        itemTemplate={dropdownTemplate}
                        scrollHeight="100px"
                        showClear
                        filter
                        tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                        tooltipOptions={{
                            position: field.TooltipPosition || 'right'
                        }}
                    />
                );
            case "MultiSelect":
                let options: { label: string, value: string, values: any[] }[] = [];

                if (field.ListOfValues?.length > 0) {
                    // field.ListOfValues.forEach((value) => options.push({
                    //     label: JSON.stringify(value.LabelDescription),
                    //     value: JSON.stringify(value.LabelValue),
                    // }));

                    field.ListOfValues.forEach((listOfValues) => {
                        const values: any[] = Object.values(listOfValues);

                        options.push({
                            label: values.length > 1 ? values[1] : values[0],
                            value: values[0],
                            values: values
                        })
                    });
                }

                const multiselectTemplate = (option: any) => {
                    return (
                        <div className="p-clearfix">
                            <span style={{ fontSize: '1em', float: 'right', marginTop: '4px' }}>
                                {
                                    field.DisplayAllField && option.values ? option.values.map((element: any, index: any) =>
                                        index > 0 ? (<span> {element} </span>) : null
                                    ) : option.label
                                }
                            </span>
                        </div>
                    );
                };

                const selectedMultiselectTemplate = (value: any) => {
                    if (value) {
                        return (
                            <span
                                style={{
                                    padding: "2px",
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                    height: "1.857em",
                                }}
                            >
                                {options.find(option => option.value === value)?.label}
                            </span>
                        );
                    }
                    return <span>Choose</span>
                }

                return (
                    <MultiSelect
                        {...register}
                        id={field.FieldName.toString().replace(",", "")}
                        className={classNames(!isEmpty(state) && { 'p-invalid': state.invalid })}
                        value={selectedItems}
                        options={options}
                        maxSelectedLabels={1}
                        readOnly={!field.Editable}
                        onChange={(e) => {
                            setSelectedItems(e.value);
                            fieldValues![fieldIndex].value = e.value;
                            setSelectAll(e.value.length === options.length);
                            if (register) {
                                register.onChange(e.value)
                            }
                        }}
                        selectAll={selectAll}
                        onSelectAll={(e) => {

                            fieldValues![fieldIndex].value = e.checked ? [] : options.map(item => item.value)
                            setSelectedItems(e.checked ? [] : options.map(item => item.value));
                            setSelectAll(!e.checked);

                        }
                        }
                        virtualScrollerOptions={!isEmpty(field.VirtualScrollerOptions) && field.VirtualScrollerOptions?.Enable ? {
                            itemSize: field.VirtualScrollerOptions.ItemSize || 38
                        } : {
                            itemSize: 38
                        }}
                        placeholder="Select Item"
                        style={{ ...customProps?.style, minWidth: "15em" }}
                        filter
                        filterPlaceholder="Search"
                        optionLabel="label"
                        itemTemplate={multiselectTemplate}
                        selectedItemTemplate={selectedMultiselectTemplate}
                        tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                        tooltipOptions={{
                            position: field.TooltipPosition || 'right'
                        }}
                    />
                );
            case "DisplayText":
                return <> {!isEmpty(field.Tooltip) ? <Tooltip target={`#DisplayText-${fieldId.toString().replace(",", "")}`} position={field.TooltipPosition ?? 'right'} content={field.Tooltip} /> : null}
                    <span style={{ ...customProps.style }} id={`#DisplayText-${fieldId.toString().replace(",", "")}`} >{fieldValues![fieldIndex].value}</span>
                </>;
            case "InputText":
                if (isNull(fieldValues![fieldIndex].value)) {
                    onFieldValueChanged(fieldValues, setFieldValues, fieldIndex, { value: "" });
                }

                return (
                    <div>
                    <InputText
                        {...register}
                        id={field.FieldName}
                        ref={inputRef}
                        className={classNames(!isEmpty(state) && { 'p-invalid': state.invalid })}
                        // value={fieldValues![fieldIndex].value}
                        style={{ ...customProps?.style }}
                        readOnly={!field.Editable}
                        onChange={(e) => {
                            onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, e)
                            register?.onChange(e.target.value)
                            register!.value = e.target.value
                        }}
                        onKeyPress={(e)=> {setLastValue(inputRef!.current.value)}}
                        onFocus={(e)=> {
                            register!.value = e.target.value;
                        }}
                        onBlur={handleBlur}
                        tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                        tooltipOptions={{
                            position: field.TooltipPosition || 'right'
                        }}
                    />
                    {isLoadingBar[field.FieldName] && <ProgressBar mode="indeterminate" style={{ height: '2px' }}></ProgressBar>}
                    </div>
                );
            case "InputNumber":
                if (isNull(fieldValues![fieldIndex].value)) {
                    onFieldValueChanged(fieldValues, setFieldValues, fieldIndex, { value: "" });
                }

                return (
                    <InputNumber
                        {...register}
                        id={field.FieldName.toString().replace(",", "")}
                        className={classNames(!isEmpty(state) && { 'p-invalid': state.invalid })}
                        // value={fieldValues![fieldIndex].value}
                        readOnly={!field.Editable}
                        style={{ ...customProps?.style }}
                        onValueChange={(e) => {
                            onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, e)
                            register?.onChange(e.value)
                            register!.value = e.value
                        }}
                        // onChange={(e) => onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, e)}
                        {...(!isEmpty(field.InputNumberProps)) && { ...field.InputNumberProps }}
                        tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                        tooltipOptions={{
                            position: field.TooltipPosition || 'right'
                        }}
                    />
                );
            case "InputTextArea":
                if (isNull(fieldValues![fieldIndex].value)) {
                    onFieldValueChanged(fieldValues, setFieldValues, fieldIndex, { value: "" });
                }

                return (
                    <InputTextarea
                        {...register}
                        id={field.FieldName.toString().replace(",", "")}
                        className={classNames(!isEmpty(state) && { 'p-invalid': state.invalid })}
                        rows={2}
                        cols={30}
                        autoResize={true}
                        value={fieldValues![fieldIndex].value}
                        style={{ ...customProps?.style }}
                        readOnly={!field.Editable}
                        onChange={(e) => {
                            onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, e)
                            register?.onChange(e.target.value)
                            register!.value = e.target.value
                        }}
                        tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                        tooltipOptions={{
                            position: field.TooltipPosition || 'right'
                        }}
                    />
                );
            case "InputPassword":
                if (isNull(fieldValues![fieldIndex].value)) {
                    onFieldValueChanged(fieldValues, setFieldValues, fieldIndex, { value: "" });
                }

                return (
                    <Password
                        {...register}
                        id={field.FieldName.toString().replace(",", "")}
                        className={classNames(!isEmpty(state) && { 'p-invalid': state.invalid })}
                        // value={fieldValues![fieldIndex].value}
                        style={{ ...customProps?.style }}
                        readOnly={!field.Editable}
                        onChange={(e) => {
                            onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, e)
                            register?.onChange(e.target.value)
                            register!.value = e.target.value
                        }}
                        tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                        tooltipOptions={{
                            position: field.TooltipPosition || 'right'
                        }}
                    />
                );
            case "FloatingInputText":
            case "PositiveNumberInputText":
                if (isNull(fieldValues![fieldIndex].value)) {
                    onFieldValueChanged(fieldValues, setFieldValues, fieldIndex, { value: "" });
                }

                return (
                    <InputText
                        {...register}
                        id={field.FieldName.toString().replace(",", "")}
                        className={classNames(!isEmpty(state) && { 'p-invalid': state.invalid })}
                        keyfilter={field.InputType === "PositiveNumberInputText" ? "pint" : "num"}
                        // value={fieldValues![fieldIndex].value}
                        style={{ ...customProps?.style }}
                        readOnly={!field.Editable}
                        onChange={(e) => {
                            onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, e, field.IntRange)
                            register?.onChange(e.target.value)
                            register!.value = e.target.value
                        }}
                        tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                        tooltipOptions={{
                            position: field.TooltipPosition || 'right'
                        }}
                    />
                );
            case "InputCheckbox":
                if (isNull(fieldValues![fieldIndex].value) || isNull(field.DefaultValue)) {
                    register?.onChange(false)
                    onFieldValueChanged(fieldValues, setFieldValues, fieldIndex, { value: false });
                } else {
                    if (parseInt(fieldValues![fieldIndex].value ?? 0) === 1) {
                        register?.onChange(true)
                        onFieldValueChanged(fieldValues, setFieldValues, fieldIndex, { value: true });
                    }
                    if (parseInt(fieldValues![fieldIndex].value ?? 0) === 0) {
                        register?.onChange(false)
                        onFieldValueChanged(fieldValues, setFieldValues, fieldIndex, { value: false });
                    }
                }
                return (
                    <Checkbox
                        {...register}
                        id={field.FieldName.toString().replace(",", "")}
                        className={classNames(!isEmpty(state) && { 'p-invalid': state.invalid })}
                        onChange={(e) => {
                            e.target.value = e.target.checked;
                            e.value = e.target.checked;
                            onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, e, field.IntRange)
                            if (register) {
                                register.onChange(e.value)
                            }
                        }}
                        readOnly={!field.Editable}
                        checked={!isEmpty(fieldValues![fieldIndex].value) ? fieldValues![fieldIndex].value : field.DefaultValue}
                        tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                        tooltipOptions={{
                            position: field.TooltipPosition || 'right'
                        }}
                    />
                );
            case "Editor":
                return (
                    <Editor style={{ ...customProps?.style }}
                        readOnly={!field.Editable}
                        value={fieldValues![fieldIndex].value}
                        onTextChange={(e) => {
                            onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, e.htmlValue)
                            register?.onChange(e.htmlValue)
                            register!.value = e.htmlValue
                        }} />
                );
            case "InputLOV":
                const footerContent = lovData ? (
                    <div>
                        <Button label="Select" icon="pi pi-check" onClick={() => {
                            if (lovData) {
                                let fieldValue = null;
                                for (const emitedData of lovData) {
                                    if (emitedData.DataSource === field.FieldName && field.InputType === "InputLOV") {
                                        fieldValue = emitedData.EmittedValue
                                        break;
                                    }
                                    if (!isEmpty(field.FieldConfiguration?.FieldValue) &&
                                        (emitedData.DataSource === field.FieldName && field.InputType === "InputLOV")) {
                                        fieldValue = emitedData.EmittedValue
                                        break;
                                    }
                                }

                                const value = fieldValue
                                onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, { value })
                                register?.onChange(value)
                                register!.value = value

                                let data = appData;
                                if (isEmpty(data) || isEmpty(data[`${chartName}___LOV`])) {
                                    Object.assign(data, {
                                        [`${chartName}___LOV`]: []
                                    })
                                }

                                console.log(data);
                                

                                const index = data[`${chartName}___LOV`].findIndex((x: any) => {
                                    return x && !isEmpty(x['LOV_' + field.FieldName])
                                })

                                if (index < 0) {
                                    data[`${chartName}___LOV`].push({
                                        ['LOV_' + field.FieldName]: lovData
                                    })
                                } else {
                                    data[`${chartName}___LOV`][index] = {
                                        ['LOV_' + field.FieldName]: lovData
                                    }
                                }

                                setAppData(data)

                            }

                            resetEmitLOVData(dispatch, 'RESET_LOV', field.FieldName).then(x => {
                                emitLOVData(dispatch, `${chartName}___LOV_DATA`, lovData, field.FieldName)
                                closeDialog();
                                console.log("register!.value", register!.value)
                            });
                        }} autoFocus />
                    </div>
                ) : undefined;

                return (
                    <div className="">
                        <InputText
                            {...register}
                            id={field.FieldName}
                            name={field.FieldName}
                            className={classNames({
                                "p-inputtext p-component": true,
                                "p-invalid": state.invalid
                            })}
                            readOnly={field.EditableLOV === undefined || field.EditableLOV === null ? false : !field.EditableLOV}
                            style={{ ...customProps?.style }}
                        />
                        <Button disabled={!field.Editable} icon="pi pi-search" className="p-button-grey" type="button" onClick={() => setVisible(true)} />
                        <Dialog
                            visible={visible}
                            onHide={closeDialog}
                            style={{
                                minWidth: "250px",
                                maxWidth: "100vw",
                                maxHeight: "90%",
                                width: isEmpty(field.FieldConfiguration?.DialogWidth) ? "60%" : field.FieldConfiguration?.DialogWidth,
                                overflow: "auto"
                            }}
                            showHeader={false}
                            footer={!isEmpty(lovData) ? footerContent : undefined}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    height: "40px",
                                    position: "relative"
                                }}
                            >
                                <Button
                                    icon="pi pi-times"
                                    iconPos="right"
                                    className="p-button-secondary"
                                    label="Close"
                                    style={{
                                        position: "absolute",
                                        right: 0
                                    }}
                                    onClick={closeDialog}
                                />
                            </div>
                            {visible && <Workspace workspaceData={field.FieldConfiguration?.Workspace} />}
                        </Dialog>
                    </div>
                )
            case "FileUpload":
                return (
                    <FileUpload field={field} register={register} onFileChange={(file:File | null) => {                            
                        onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, {
                            value: file
                        })
                        register?.onChange(file)
                        register!.value = file
                    }}
                    ></FileUpload>
                )
            default:
                // Input Text
                if (isNull(fieldValues![fieldIndex].value)) {
                    onFieldValueChanged(fieldValues, setFieldValues, fieldIndex, { value: "" });
                }

                return (
                    <InputText
                        {...register}
                        id={field.FieldName.toString().replace(",", "")}
                        className={classNames(!isEmpty(state) && { 'p-invalid': state.invalid })}
                        // value={fieldValues![fieldIndex].value}
                        readOnly={!field.Editable}
                        style={{ ...customProps?.style }}
                        onChange={(e) => {
                            onFieldValueChanged(fieldValues!, setFieldValues, fieldIndex, e)
                            register?.onChange(e.target.value)
                            register!.value = e.target.value
                        }}
                        tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                        tooltipOptions={{
                            position: field.TooltipPosition || 'right'
                        }}
                    />
                );
        }
    }

    return (
        <div></div>
    );
};

export default Field;
