/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import { IAction } from "../../models/interfaces/IAction";
import { EMIT_FIELD_DATA, EMIT_LOV_DATA, RESET_EMIT_FIELD_DATA, RESET_EMIT_LOV_DATA } from "../constants/beacon";


let initialState: any = {};

const reducer =  (state = initialState, action: IAction) => {
    switch (action.type) {
        case EMIT_FIELD_DATA:
            return action.payload
        case RESET_EMIT_FIELD_DATA:
            return action.payload ? action.payload : {}
        case EMIT_LOV_DATA:
            return action.payload
        case RESET_EMIT_LOV_DATA:
            return action.payload ? action.payload : {}
        default:
            return state;
    }
};

export default reducer