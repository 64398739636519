//Function to normalize latitude or longitude from string to number
function normalizeCoordinate(coord:any) {
    return typeof coord === 'string' ? parseFloat(coord.replace(',', '.')) : coord;
}

// Function to calculate the distance between two points using Haversine formula
function calculateHaversine(lat1:number, lon1:number, lat2:number, lon2:number) {
    const R = 6371; // Earth radius in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in miles

    return distance;
}

// Function to find nearby locations
export function findNearbyLocations(userLat:number, userLon:number, locations:any[], {maxDistance = 100, latField = 'lat', lonField = 'lon'}) {
    const nearbyLocations:any[] = [];

    locations.forEach((location:any) => {
        const locationLat = normalizeCoordinate(location[latField]);
        const locationLon = normalizeCoordinate(location[lonField]);

        const distance = calculateHaversine(userLat, userLon, locationLat, locationLon);

        if (distance <= maxDistance) {
            nearbyLocations.push(location)
        }
    });

    return nearbyLocations;
}