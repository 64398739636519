/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

export function removeToken(): void {
    sessionStorage.clear()
    window.localStorage.removeItem("jwtToken");
    window.localStorage.removeItem("jwtRefreshToken");
    window.localStorage.removeItem("permissionItem");
    window.location.hash = "signin";
}