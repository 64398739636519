/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "promise-polyfill/src/polyfill";
import "unfetch/polyfill";
import "abortcontroller-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { isEmpty } from "./utils/validation";

document.title =
  process.env.REACT_APP_TITLE_BAR ?? "CircleBI.com - BI For Everyone";
if (!isEmpty(process.env.REACT_APP_FAVICON)) {
  document
    .querySelector("link[rel=icon]")!
    .setAttribute("href", `${process.env.REACT_APP_FAVICON}`);
}
if (!isEmpty(process.env.REACT_APP_FAVICON_APPLE_ICON)) {
  document
    .querySelector("link[rel=apple-touch-icon]")!
    .setAttribute("href", `${process.env.REACT_APP_FAVICON_APPLE_ICON}`);
}
if (!isEmpty(process.env.REACT_APP_TITLE)) {
  document
    .querySelector("meta[name='CircleBI.com BI For Everyone']")!
    .setAttribute("content", `${process.env.REACT_APP_TITLE ?? null}`);
}
if (!isEmpty(process.env.REACT_APP_TITLE_BAR)) {
  document
    .querySelector("meta[name='CircleBI.com BI For Everyone']")!
    .setAttribute("name", `${process.env.REACT_APP_TITLE_BAR ?? null}`);
}

function addStylesheet(id: any, href: any) {
  // Buat elemen link
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.id = id;
  link.href = href;

  // Tambahkan elemen link ke dalam head
  document.head.appendChild(link);
}

// Tambahkan stylesheet untuk login
addStylesheet('login-stylesheet', process.env.REACT_APP_CUSTOM_LOGIN_PAGE_CSS || 'default_frontend.css');

// Tambahkan stylesheet untuk custom
addStylesheet('custom-stylesheet', process.env.REACT_APP_CUSTOM_SCREEN_CSS || 'custom_frontend.css');

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
