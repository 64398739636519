/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

export const EMIT_DATA = "EMIT_DATA";
export const RESET_EMIT_DATA = "RESET_EMIT_DATA";

export const EMIT_FIELD_DATA = "EMIT_FIELD_DATA";
export const RESET_EMIT_FIELD_DATA = "RESET_EMIT_FIELD_DATA";

export const EMIT_LOV_DATA = "EMIT_LOV_DATA";
export const RESET_EMIT_LOV_DATA = "RESET_EMIT_LOV_DATA";
