/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import openSocket from 'socket.io-client';

// Components
import ContextMenu from '../../../../../../components/ContextMenu';
import ProfilePicture from '../../../../../../components/ProfilePicture';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import InfiniteScroll from 'react-infinite-scroll-component';

// Containers
import Workspace from '../../../../../Workspace';

// Assets
import userLogo from '../../../../../../assets/user_logo.png';

// Classes and interfaces
import { ActionData } from '../../../../../../models/classes/ActionData';
import { PanelFieldData } from '../../../../../../models/classes/PanelFieldData';
import { PanelData } from '../../../../../../models/classes/PanelData';
import { IAPIOption } from '../../../../../../models/interfaces/IAPIOption';
import { IConsumeAPI } from '../../../../../../models/interfaces/IConsumeAPI';
import { IFieldValue } from '../../../../../../models/interfaces/IFieldValue';
import { IPostAction } from '../../../../../../models/interfaces/IPostAction';

// Redux
import { consumeAPI } from '../../../../../../redux/actions/panelItem';
import { State } from '../../../../../../redux/reducers';

// Utils
import { isEmpty, isNull } from '../../../../../../utils/validation';

// Common
import api from '../../../../../../common/api';

interface IPanelTimelineComponent {
    widgetName: string;
    panelData: PanelData;
}

interface ITimelineItem {
    timelineData: any;
    panelData: PanelData;
    setDialogData: Function;
}

interface IComments {
    comments: any;
    panelData: PanelData;
}

interface INewComment extends IPanelTimelineComponent {
    HPOAlertRunningID: string;
}

const TimelineItem: React.FC<ITimelineItem> = (props) => {
    const {
        timelineData,
        panelData,
        setDialogData
    } = props;

    const {
        Actions: panelActions,
        OtherSettings: otherSettings
    } = panelData;

    const [isTimelineItemLoading, setIsTimelineItemLoading] = useState(false);

    const onTimelineItemClicked = (
        AlertLabel: string,
        HPOAlertRunningID: string,
        screenRoute: string,
        AlertName: string,
        openInNewTab: boolean = false,
        genericFields: any = [],
        useLazyLoad: boolean,
        requestAllData: boolean,
    ) => {
        const actionData: ActionData | undefined = panelActions.find(action => action.ActionName === "ReadAlertGroupActivity");

        if (actionData) {
            let fieldValues: IFieldValue[] = [];
            console.log('actionData.Fields', actionData.Fields)

            actionData.Fields?.forEach(field => fieldValues.push({
                fieldName: field.FieldName,
                dataType: field.DataType,
                value: field.Value
            }));

            fieldValues.push({
                fieldName: "HPOAlertRunningID",
                dataType: "Text",
                value: HPOAlertRunningID
            } as IFieldValue);

            let requestBody = {
                APIName: "ReadAlertGroupActivity",
                inputParameterValues: fieldValues
            };

            const apiOption: IAPIOption = {
                path: "query_management",
                method: "POST",
                data: requestBody
            }

        setIsTimelineItemLoading(true);
        api(apiOption).then((response: any) => {
            setIsTimelineItemLoading(false);
            if (response.success) {
                console.log("lazyload", useLazyLoad, timelineData?.UseLazyLoad, panelData);
                // Redirect
                let redirectRoute: string = "";
                let overrideWidgetConfig: any = { // TODO: Move to widget config
                    Panels: [
                        {
                            PanelName: "SearchPanel",
                            ...(isEmpty(screenRoute) && {NumberOfColumns: 2.0, NumberOfRows: 2.0}),
                            Fields: [
                                {
                                    FieldName: "HPOAlertRunningID",
                                    DefaultValue: HPOAlertRunningID,
                                    FieldLabel: "Alert ID",
                                    DataType: "Text",
                                    FieldFormat: null,
                                    InputType: "InputText",
                                    Mandatory: false,
                                    Hidden: false,
                                    ...(isEmpty(screenRoute) && {DisplayPosition: "0,0"})
                                },
                                ...[isEmpty(screenRoute) && {
                                        FieldName: "AlertName",
                                        DefaultValue: AlertName? AlertName: "",
                                        Hidden: true,
                                        DisplayPosition: "1,0"
                                    },
                                    {
                                        FieldName: "RequestAllData",
                                        FieldLabel: "Request All Data",
                                        FieldFormat: null,
                                        InputType: "Checkbox",
                                        Mandatory: true,
                                        Hidden: true,
                                        DefaultValue: `${requestAllData}`,
                                        DataType: "Boolean",
                                        DisplayPosition: "0,1",
                                    }
                                ]
                            ],
                            Buttons: [
                                {
                                    ButtonName: "Search",
                                    ClickOnInit: true,
                                    Hidden: isEmpty(screenRoute),
                                    ...(isEmpty(screenRoute) && {APIName: "GetGenericAlertsData"})
                                }
                            ]
                        },
                        ...[isEmpty(screenRoute) && {
                            PanelName: "GenericDataTable",
                            Fields: genericFields,
                            TableOption: {
                                TableType: "Generic",
                                UseLazyLoad: useLazyLoad,
                                LazyLoadOption: {
                                    APIName: "GetGenericAlertsData"
                                }
                            }
                        }]
                    ]
                };

                    if (!isEmpty(AlertLabel)) overrideWidgetConfig["Title"] = AlertLabel;

                    if (isEmpty(screenRoute)) screenRoute = "Dashboard/GenericWorkspace";

                    overrideWidgetConfig = JSON.stringify(overrideWidgetConfig);

                    if (openInNewTab) {
                        overrideWidgetConfig = encodeURIComponent(overrideWidgetConfig);
                        redirectRoute = `?OverrideWidgetConfig=${overrideWidgetConfig}`;
                    } else {
                        sessionStorage.setItem("OverrideWidgetConfig", overrideWidgetConfig);
                        redirectRoute = screenRoute;
                    }

                    openInNewTab?
                        window.open(`${window.location.origin}/#/${screenRoute}${redirectRoute}`):
                        setDialogData(redirectRoute);
                } else {
                    console.error(response);
                }
            });
        } else {
            console.error("No action data found");
        }
    };

    const menuList = [
        {
            text: "Open in new tab",
            onClick: () => {
                onTimelineItemClicked(
                    timelineData?.AlertLabel,
                    timelineData?.HPOAlertRunningID,
                    timelineData?.ScreenRoute,
                    timelineData?.AlertName,
                    true,
                    timelineData?.GenericFields,
                    timelineData?.UseLazyLoad ?? false,
                    timelineData?.UseLazyLoad ? false : true
                );
            }
        }
    ];

    return (
        <ContextMenu contextMenuItems={menuList}>
            <div
                style={{
                    cursor: isTimelineItemLoading? "wait": "pointer",
                    opacity: isTimelineItemLoading? "50%":"100%",
                    width: "100%",
                    fontSize: panelData?.PanelFontSize? panelData.PanelFontSize: "14px"
                }}
                onClick={
                    ()=>
                        onTimelineItemClicked(
                            timelineData?.AlertLabel,
                            timelineData?.HPOAlertRunningID,
                            timelineData?.ScreenRoute,
                            timelineData?.AlertName,
                            false,
                            timelineData?.GenericFields,
                            timelineData?.UseLazyLoad ?? false,
                            timelineData?.UseLazyLoad ? false : true
                        )
                }
            >
                <span>
                    {timelineData?.HPOAlertRunningID}
                </span>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: otherSettings?.Layout === 2? "column": "row" // experimental
                    }}
                >
                    <span
                        style={{
                            marginTop: "5px"
                        }}
                    >
                        {timelineData?.AlertName}
                    </span>
                    <span
                        style={{
                            marginTop: "5px"
                        }}
                    >
                        {timelineData?.TotalRowCount} Rows
                    </span>
                    <span
                        style={{
                            marginTop: "5px"
                        }}
                    >
                        {timelineData?.CreatedAt}
                    </span>
                </div>
            </div>
        </ContextMenu>
    );
};

const Comments: React.FC<IComments> = (props) => {
    const { comments, panelData } = props;
    const [loadedComments, setLoadedComments] = useState<any[]>([]);
    const [isCommentsHidden, setIsCommentsHidden] = useState<boolean>(false);

    const loadMoreComments = () => {
        setLoadedComments((loadedComments: any[]) => {
            const comments = JSON.parse(JSON.stringify(props.comments));
            return [...loadedComments, ...comments.slice(loadedComments.length, loadedComments.length + 5)]
        });
    }

    const toggleComments = () => {
        setIsCommentsHidden(!isCommentsHidden);
    };

    useEffect(() => {
        if (props?.comments) {
            const comments = JSON.parse(JSON.stringify(props.comments));
            setLoadedComments(comments.slice(0, 5));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div style={{paddingLeft: "5px"}}>
            {
                comments?.length > 0?
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <h4 style={{marginBottom: 0}}>Comments:</h4>
                        <p
                            style={{
                                marginBottom: 0,
                                cursor: "pointer",
                            }}
                            onClick={toggleComments}
                        >
                            {isCommentsHidden? "Show": "Hide"}
                            <i
                                className={classNames(
                                    'pi',
                                    {
                                        "pi-angle-down": isCommentsHidden
                                    },
                                    {
                                        "pi-angle-up": !isCommentsHidden
                                    }
                                )}
                            >
                            </i>
                        </p>
                    </div>
                    : null
            }
            {
                loadedComments?.map((comment: any, commentIdx: number) => (
                    <Card
                        key={commentIdx}
                        style={{
                            cursor: "default",
                            display: isCommentsHidden? "none":"inherit"
                        }}
                    >
                        <div className="p-grid">
                            <div
                                className="p-col"
                                style={{
                                    flexGrow:
                                        panelData?.OtherSettings?.Layout === 2?
                                            1: 0
                                }}
                            >
                                <ProfilePicture
                                    userLogo={userLogo}
                                    width="none"
                                    wrapperStyle={{
                                        textAlign: "center"
                                    }}
                                />
                            </div>
                            <div className="p-col" style={{cursor: "text"}}>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "inline-flex"
                                    }}
                                >
                                    {
                                        !!(comment.FirstName) && !!(comment.LastName)?
                                            <b style={{}}>
                                                {comment.FirstName} {comment.LastName}
                                            </b>:
                                            <b style={{}}>
                                                {comment.Username}
                                            </b>
                                    }
                                    <small
                                        style={{
                                            marginLeft: "auto",
                                            overflow: "hidden",
                                            textOverflow: "clip",
                                            whiteSpace: "nowrap"
                                        }}
                                    >
                                        {
                                            !isEmpty(comment.CreatedAt)?
                                                comment.CreatedAt: "Date not specified"
                                        }
                                    </small>
                                </div>
                                <span>{comment.Comment}</span>
                            </div>
                        </div>
                    </Card>
                ))
            }
            {
                loadedComments.length < comments?.length && !isCommentsHidden?
                    <Button
                        label="Load More Comments"
                        className="p-button-secondary"
                        style={{
                            width: "100%",
                            marginTop: "10px"
                        }}
                        onClick={loadMoreComments}
                    />: null
            }
        </div>
    );
};

const NewComment: React.FC<INewComment> = (props) => {
    const { widgetName, panelData, HPOAlertRunningID } = props;

    const timelineName: string = `${widgetName}_${panelData.PanelName}`;

    const [postComment, setPostComment] = useState<any>({});
    const [dummyState, triggerReRender] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        let newComment = postComment;

        if (isNull(newComment[HPOAlertRunningID])) {
            newComment[HPOAlertRunningID] = "";
            setPostComment(newComment);
        }
        // eslint-disable-next-line
    }, []);

    const onCommentInputChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
        timelineItemID: string
    ) => {
        let newComment = postComment;

        newComment[timelineItemID] = event.target.value;

        setPostComment(newComment);
        triggerReRender(!dummyState);
    };

    const onSubmitNewComment = (timelineItemID: string, HPOAlertRunningID: string) => {
        const actionComment = panelData.Actions!
            .find(action => action.ActionName === "AddComment");

        if (actionComment) {
            let fieldValues: IFieldValue[] = [
                {
                    fieldName: "HPOAlertRunningID",
                    dataType: "Text",
                    value: HPOAlertRunningID
                } as IFieldValue,
                {
                    fieldName: "Comment",
                    dataType: "Text",
                    value: encodeURIComponent(postComment[timelineItemID])
                } as IFieldValue
            ];

            let postActions: IPostAction[] = actionComment.PostActions? actionComment.PostActions: [];

            postActions.forEach((postAction: any, idx: number) => {
                console.log(timelineItemID);
                postActions[idx]["RefreshFieldRowInfo"] = {_id: timelineItemID};
            });

            const options: IConsumeAPI = {
                targetPanel: timelineName,
                APIName: actionComment.APIName,
                inputParameterValues: fieldValues,
                UseQueue: false,
                isAPIForDataTable: false,
                postActions: postActions
            };

            consumeAPI(dispatch, options);

            let newPostComment = postComment;
            newPostComment[timelineItemID] = "";

            setPostComment(newPostComment);
            triggerReRender(!dummyState);
        }
    };

    return (
        <div style={{width: "100%", marginTop: "25px"}}>
            <span className="p-float-label">
                <InputTextarea
                    id="comment"
                    rows={3}
                    cols={100}
                    style={{width: "100%", height: '100%'}}
                    value={postComment[HPOAlertRunningID]}
                    onChange={
                        (e:React.ChangeEvent<HTMLTextAreaElement>) =>
                            onCommentInputChange(e, HPOAlertRunningID)
                    }
                    autoResize={true}
                />
                <label htmlFor="comment">Comment</label>
            </span>
            <Button
                style={{marginLeft: "auto"}}
                label="Post"
                onClick={()=>onSubmitNewComment(HPOAlertRunningID, HPOAlertRunningID)}
            />
        </div>
    );
}

const PanelTimelineComponent: React.FC<IPanelTimelineComponent> = (props) => {
    const { widgetName, panelData } = props;

    const timelineName: string = `${widgetName}_${panelData.PanelName}`;

    const [timelineItems, setTimelineItems] = useState<any>([]);
    const [isTimelineHasNextPage, setIsTimelineHasNextPage] = useState<boolean>(true);
    const [currentTimelinePage, setCurrentTimelinePage] = useState<number>(0);
    const timelineItemsPerPage = 5;

    const [dummyState, triggerReRender] = useState(true);
    const [dialogData, setDialogData] = useState<string | null>(null);

    const timelineRef = useRef(null);

    const panelItemSelector = useSelector((state: State) => state.panelItem);

    const fetchTimelineData = () => {
        let fieldValues: IFieldValue[] = [];

        panelData?.LoadData?.APIParameters?.forEach((APIParam: PanelFieldData) => {
            fieldValues.push({
                fieldName: APIParam.FieldName,
                dataType: APIParam.DataType,
                value: APIParam.Value
            } as IFieldValue);
        });

        fieldValues.push({
            fieldName: "Page",
            dataType: "Number",
            value: currentTimelinePage
        } as IFieldValue);

        fieldValues.push({
            fieldName: "ItemsPerPage",
            dataType: "Number",
            value: timelineItemsPerPage
        } as IFieldValue);

        fieldValues.push({
            fieldName: "RequestAllData",
            dataType: "Boolean",
            value: true
        } as IFieldValue);

        let requestBody = {
            APIName: panelData?.LoadData?.APIName,
            inputParameterValues: fieldValues
        };

        const apiOption: IAPIOption = {
            path: "query_management",
            method: "POST",
            data: requestBody
        }

        api(apiOption).then((response: any) => {
            if (response.success) {
                if (!isEmpty(response.data)) {
                    if(response.data.length > 0) {
                        let newTimelineItems = timelineItems;
                        let newPage = currentTimelinePage;

                        newTimelineItems = [...newTimelineItems, ...response.data];
                        newPage += 1;

                        if(!isNull(timelineRef.current)) {
                            console.log("newTimelineItems", newTimelineItems);
                            setIsTimelineHasNextPage(true);
                            setTimelineItems(newTimelineItems);
                            setCurrentTimelinePage(newPage);
                            triggerReRender(!dummyState);
                        }
                    } else {
                        if (!isNull(timelineRef.current)) setIsTimelineHasNextPage(false);
                    }
                } else {
                    if (!isNull(timelineRef.current)) setIsTimelineHasNextPage(false);
                }
            }
        });
    };

    const closeDialog = () => {
        setDialogData(null);
        sessionStorage.removeItem("OverrideWidgetConfig");
    };

    const refreshTimelineData = (data: any) => {
        if (timelineItems) {
            setTimeout(() => {
                setTimelineItems((timelineItems: any) => {
                    const fieldIndexToEdit = timelineItems
                        .findIndex((row: any) => row._id === data._id);

                    let newDT = JSON.parse(JSON.stringify(timelineItems));
                    newDT[fieldIndexToEdit] = data;

                    return newDT
                });

                triggerReRender(!dummyState);
            }, 100);
        }
    };

    useEffect(()=> {
        let sockets: SocketIOClient.Socket[] = [];

        fetchTimelineData();

        panelData?.SubscribeComponents?.forEach((subscribeInfo) => {
            if (subscribeInfo?.SourceType === "Socket") {
                subscribeInfo?.SocketTopics?.forEach((topic) => {
                    const socket = openSocket(
                        process.env.REACT_APP_WS_URL!,
                        {
                            path: process.env.REACT_APP_WS_PATH ?? '/circlebi.io',
                            transports: ['websocket', 'polling'],
                            query: `TopicName=${topic}`
                        }
                    );

                    socket.on('connect', () => {
                        socket.emit('subscribeToEvent', 1000);
                    });

                    socket.on('RealTimeDataPayload', (data: any) => {
                        console.log(`TopicName=${topic}`);
                        console.log("New Data", data, data?._id, typeof data?._id);

                        if (Array.isArray(data)) {
                            // push new data to the beginning of timeline
                            if (timelineItems) {
                                setTimeout(() => {
                                    setTimelineItems((timelineItems: any) => {
                                        let newDT = JSON.parse(JSON.stringify(timelineItems));

                                        console.log("New items", [...data, ...newDT]);

                                        return [...data, ...newDT];
                                    });
                                    triggerReRender(!dummyState);
                                }, 100);
                            }
                        } else {
                            // update timeline item data
                            refreshTimelineData(data);
                            setTimeout(() => triggerReRender(!dummyState), 250);
                        }
                    });

                    sockets.push(socket);
                });
            }
        });

        return () => {
            // close socket on component unMount
            if (!isEmpty(sockets)) {
                sockets.forEach((socket) => {
                    try {
                        socket.close();
                    } catch (e) {
                        console.error("error closing socket", e);
                    }
                });
            }
        }

        // eslint-disable-next-line
    },[panelData]);

    useEffect(()=>{
        if (
            panelItemSelector?.isSuccessGetTableData &&
            panelItemSelector?.successData?.targetPanel?.includes(timelineName)
        ) {
            setTimelineItems(panelItemSelector?.successData?.data);
        }

        // Post Action to refresh timeline item data
        if (
            panelItemSelector?.successData?.targetPanel?.includes(timelineName) &&
            panelItemSelector?.isSuccessSubmitActionData &&
            !isEmpty(panelItemSelector?.successData)
        ) {
            panelItemSelector?.successData?.postActions?.forEach((postAction: IPostAction) => {
                if (postAction?.RefreshField) {
                    let fieldValues: IFieldValue[] = [
                        {
                            fieldName: "_id",
                            dataType: "Text",
                            value: postAction?.RefreshFieldRowInfo?._id
                        } as IFieldValue
                    ];

                    let requestBody = {
                        APIName: postAction?.RefreshFieldAPI,
                        inputParameterValues: fieldValues
                    };

                    const apiOption: IAPIOption = {
                        path: "query_management",
                        method: "POST",
                        data: requestBody
                    }

                    api(apiOption).then((response: any) => {
                        if (response.success) {
                            if (!isEmpty(response.data)) {
                                if (timelineItems) {
                                    const fieldIndexToEdit = timelineItems
                                        .findIndex((row: any) => row._id === response.data._id);

                                    if(timelineItems.length >= 0) {
                                        let newDT = timelineItems;

                                        newDT[fieldIndexToEdit] = response.data;

                                        if(!isNull(timelineRef.current)) {
                                            setTimelineItems(newDT);
                                            triggerReRender(!dummyState);
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            });
        }
    // eslint-disable-next-line
    },[panelItemSelector]);

    useEffect(() => {
        return () => {
            timelineRef.current = null;
        }
    }, []);

    return (
        <React.Fragment>
            <InfiniteScroll
                ref={timelineRef}
                scrollableTarget="content"
                dataLength={timelineItems.length}
                next={fetchTimelineData}
                hasMore={isTimelineHasNextPage}
                loader={
                    <h4 style={{textAlign: 'center'}}>Loading...</h4>
                }
                endMessage={
                    <p style={{textAlign: 'center'}}>
                        {
                            timelineItems.length > 0?
                                <b>No more data</b>:
                                <b>No data</b>
                        }
                    </p>
                }
            >
                {
                    timelineItems.map((timelineItem: any, timelineItemIndex: number) => (
                        <Card
                            key={timelineItem._id?timelineItem._id:timelineItemIndex}
                            style={{margin: "10px -10px"}}
                        >
                            <TimelineItem
                                timelineData={timelineItem}
                                panelData={panelData}
                                setDialogData={setDialogData}
                            />

                            <Comments
                                comments={timelineItem?.Comments}
                                panelData={panelData}
                            />

                            <NewComment
                                panelData={panelData}
                                widgetName={widgetName}
                                HPOAlertRunningID={timelineItem.HPOAlertRunningID}
                            />
                        </Card>
                    ))
                }
            </InfiniteScroll>
            <Dialog
                visible={!!dialogData}
                onHide={closeDialog}
                style={{
                    minWidth: "250px",
                    maxWidth: "100vw",
                    maxHeight: "90%",
                    width: "95%",
                    overflow: "auto"
                }}
                showHeader={false}
            >
                <div
                    style={{
                        width: "100%",
                        height: "40px",
                        position: "relative"
                    }}
                >
                    <Button
                        icon="pi pi-times"
                        iconPos="right"
                        className="p-button-secondary"
                        label="Close"
                        style={{
                            position: "absolute",
                            right: 0
                        }}
                        onClick={closeDialog}
                    />
                </div>
                {dialogData && <Workspace jsonPath={dialogData.split("/")} />}
            </Dialog>
        </React.Fragment>
    );
};

export default PanelTimelineComponent;
