/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import {
    LoginSocialGoogle,
    IResolveParams,
} from 'reactjs-social-login'

// CUSTOMIZE ANY UI BUTTON
import {
    GoogleLoginButton, MicrosoftLoginButton
} from 'react-social-login-buttons'

import { authConfig, googleCallback, login, samlCallback, verify } from '../../redux/actions/auth';
import { State } from '../../redux/reducers';

import './styles.css';

import { isEmpty } from '../../utils/validation';
import AzureAD, { AuthenticationState, IAzureADFunctionProps, MsalAuthProvider } from 'react-aad-msal';
import { authProvider } from '../../common/authProvider';
import store from '../../redux';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { isUrlParamExist, removeParam } from '../../utils/url';
const CryptoJS = require('crypto-js');


interface ILoginType {
    loginType: String;
    token?: String;
}

const Title: React.FC = () => {
    return (
        <div className="title">
            <span></span>
        </div>
    );
};

const LoginForm: React.FC = (props) => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const toastRef = useRef<Toast>(null);
    const history = useHistory();

    const dispatch = useDispatch();
    const authItemSelector = useSelector((state: State) => state.authItem);

    useEffect(() => {
        if (!isEmpty(authItemSelector)) {
            if (authItemSelector.isLoadingLogin) setIsLoggingIn(true);
            else setIsLoggingIn(false);

            if (authItemSelector.isSuccessLogin) {
                if (!isEmpty(authItemSelector.successData)) {
                    const successData = authItemSelector.successData;
                    if (successData?.ReturnMessage) {
                        if (toastRef) {
                            toastRef.current!.show({
                                severity: "success",
                                summary: "Login success",
                                detail: successData.ReturnMessage
                            });
                        }
                    }

                    if (!isEmpty(successData.data) && successData?.data.payload) {
                        window.localStorage.setItem("Language", _.get(successData?.data.payload, "Language", ""));
                    }

                    if (!isEmpty(successData.data) && successData?.data?.token) {
                        window.localStorage.setItem("jwtToken", successData.data.token);
                        window.localStorage.setItem("jwtRefreshToken", successData.data.refreshToken);

                        history.replace("dashboard");
                    }

                    if (!isEmpty(successData.data) && successData?.data?.permissions) {
                        window.localStorage.setItem("permissionItem", JSON.stringify(successData?.data?.permissions));
                        history.replace("dashboard");
                    }
                }
            }

            if (authItemSelector.isErrorLogin) {
                if (!isEmpty(authItemSelector.errorData)) {
                    const errorData = authItemSelector.errorData;
                    if (errorData?.ReturnMessage) {
                        toastRef!.current!.show({
                            severity: "error",
                            icon: <></>,
                            summary: "Login failed",
                            detail: errorData.ReturnMessage
                        });
                    }

                    if (errorData?.data) {
                        if (errorData.data?.validation?.length) {
                            errorData.data.validation.forEach((valItem: any) => {
                                toastRef!.current!.show({
                                    severity: "error",
                                    icon: <></>,
                                    summary: `Validation Error: ${valItem.dataPath.replace(".", "")}`,
                                    detail: valItem.message
                                });
                            });
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [authItemSelector]);

    useEffect(() => document.addEventListener("keyup", doc_keyUp, false), []);

    const submitForm = () => {
        const ciphertext = CryptoJS.AES.encrypt(password.replace(/\s/g, ''), process.env.REACT_APP_CLIENT_HEX || '').toString();
        const userData = {
            Username: username.replace(/\s/g, ''),
            Password: ciphertext
        };
        sessionStorage.clear()
        login(dispatch, userData);
    };

    function doc_keyUp(event: KeyboardEvent) {
        let e = event || window.event; // for IE to cover IEs window event-object

        if (e.keyCode === 13) { // if user click enter key
            let button: HTMLElement = document
                ?.getElementsByClassName("sign-in-btn")[0]
                ?.firstChild as HTMLElement;
            button?.click();
        }
    }

    return (
        <div className="form">
            <Toast ref={toastRef} />
            <span className="p-float-label username">
                <InputText id="username"
                    value={username}
                    disabled={isLoggingIn}
                    onChange={(e: any) => setUsername(e.target.value)} />
                <label htmlFor="username">Username</label>
            </span>

            <span className="p-float-label password">
                <Password id="password"
                    style={{ width: '100%' }}
                    feedback={false}
                    value={password}
                    disabled={isLoggingIn}
                    onChange={(e: any) => setPassword(e.target.value)} />
                <label htmlFor="password">Password</label>
            </span>

            <div className="sign-in-btn">
                <Button
                    className="p-button-secondary p-button-raised p-button-rounded"
                    label={isLoggingIn ? "Logging in..." : "Login"}
                    disabled={isLoggingIn}
                    onClick={submitForm}
                />
            </div>
        </div>
    );
};


const MSALLogin: React.FC = (props) => {

    const [, setIsLoggingIn] = useState<boolean>(false);
    const [msalAuthProvider, setMsalAuthProvider] = useState<MsalAuthProvider>();

    const dispatch = useDispatch();
    const authItemSelector = useSelector((state: State) => state.authItem);
    const msalItemSelector = useSelector((state: State) => state.msalItem);
    const toastRef = useRef<Toast>(null);
    const history = useHistory();


    useEffect(() => {
        if (msalAuthProvider === null || msalAuthProvider === undefined) {
            authProvider().then((value) => setMsalAuthProvider(value))
        }

        if (!isEmpty(authItemSelector)) {
            if (authItemSelector.isLoadingLogin) setIsLoggingIn(true);
            else { setIsLoggingIn(false); sessionStorage.removeItem("isVerifying"); }

            if (authItemSelector.isSuccessLogin) {
                sessionStorage.removeItem("isVerifying");
                if (!isEmpty(authItemSelector.successData)) {
                    const successData = authItemSelector.successData;
                    if (successData?.ReturnMessage) {
                        if (toastRef) {
                            toastRef.current!.show({
                                severity: "success",
                                summary: "Login success",
                                detail: successData.ReturnMessage
                            });
                        }
                    }

                    if (successData?.data.payload) {
                        window.localStorage.setItem("Language", _.get(successData?.data.payload, "Language", ""));
                    }

                    if (successData?.data?.token) {
                        window.localStorage.setItem("jwtToken", successData.data.token);
                        window.localStorage.setItem("jwtRefreshToken", successData.data.refreshToken);

                        history.replace("dashboard");
                    }

                    if (successData?.data?.permissions) {
                        window.localStorage.setItem("permissionItem", JSON.stringify(successData?.data?.permissions));
                        history.replace("dashboard");
                    }
                }
            }

            if (authItemSelector.isErrorLogin) {
                sessionStorage.removeItem("isVerifying");
                if (!isEmpty(authItemSelector.errorData)) {
                    const errorData = authItemSelector.errorData;
                    if (errorData?.ReturnMessage) {
                        toastRef.current!.show({
                            severity: "error",
                            icon: <></>,
                            summary: "Login failed",
                            detail: errorData.ReturnMessage
                        });
                    }
                    if (errorData?.data) {
                        if (errorData.data?.validation?.length) {
                            errorData.data.validation.forEach((valItem: any) => {
                                toastRef!.current!.show({
                                    severity: "error",
                                    icon: <></>,
                                    summary: `Validation Error: ${valItem.dataPath.replace(".", "")}`,
                                    detail: valItem.message
                                });
                            });
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [authItemSelector, msalItemSelector]);

    React.useMemo(
        async () => {
            if (!isEmpty(msalItemSelector)) {
                const isAuthenticated =
                    msalItemSelector.state === AuthenticationState.Authenticated;
                if (isAuthenticated && sessionStorage.getItem("isVerifying") == null && (msalItemSelector.idToken != null && msalItemSelector.idToken.idToken != null)) {
                    sessionStorage.setItem("isVerifying", '1')
                    const request = {
                        scopes: msalAuthProvider?.getAuthenticationParameters()?.scopes
                    };
                    try {
                        var response = await msalAuthProvider!.acquireTokenSilent(request)
                        verify(dispatch, response.accessToken)
                    } catch (error) {
                        msalAuthProvider!.logout()
                        window.sessionStorage.clear();
                        console.log("ERROR", error)
                    }
                }
            }
        },
        [msalItemSelector]
    );

    return (
        <div className="form-msal">
            <Toast ref={toastRef} />
            <div className="sign-in-btn" style={{ margin: '0px !important', height: 'auto !important' }}>

                {msalAuthProvider != null ? <AzureAD provider={msalAuthProvider!} reduxStore={store}>
                    {({
                        accountInfo,
                        authenticationState,
                        error,
                        login
                    }: IAzureADFunctionProps) => {
                        const isInProgress =
                            authenticationState === AuthenticationState.InProgress;

                        const isLoading = error == null && (isInProgress || sessionStorage.getItem("isVerifying") != null);
                        return <Button
                            className="p-button-secondary p-button-raised p-button-rounded"
                            style={{ justifyContent: 'center', marginTop: "100px !important" }}
                            onClick={login}
                            disabled={isLoading}
                            label={isLoading ? 'Logging in...' : 'Login with Microsoft Account'}
                        >
                        </Button>
                    }}
                </AzureAD> : "Initializing Configuration..."}
            </div>
        </div>
    );
};

const SocialLogin: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const authItemSelector = useSelector((state: State) => state.authItem);
    const toastRef = useRef<Toast>(null);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [config, setConfig] = useState<any>(null);
    const { token } = props;
    const history = useHistory();

    useEffect(() => {
        if (!isEmpty(authItemSelector)) {

            if (authItemSelector.isSuccessLoginConfig) {
                if (!isEmpty(authItemSelector.successData)) {
                    if (!isEmpty(authItemSelector.successData)) {
                        setConfig(authItemSelector.successData)
                    }
                }
            }

            if (authItemSelector.isLoadingLogin) setIsLoggingIn(true);
            else { setIsLoggingIn(false); sessionStorage.removeItem("isVerifying"); }

            if (authItemSelector.isSuccessLogin) {
                sessionStorage.removeItem("isVerifying");
                if (!isEmpty(authItemSelector.successData)) {
                    const successData = authItemSelector.successData;
                    if (successData?.ReturnMessage) {
                        if (toastRef) {
                            toastRef.current!.show({
                                severity: "success",
                                summary: "Login success",
                                detail: successData.ReturnMessage
                            });
                        }
                    }

                    if (!isEmpty(successData.data) && successData?.data.payload) {
                        window.localStorage.setItem("Language", _.get(successData?.data.payload, "Language", ""));
                    }

                    if (!isEmpty(successData.data) && successData?.data?.token) {
                        window.localStorage.setItem("jwtToken", successData.data.token);
                        window.localStorage.setItem("jwtRefreshToken", successData.data.refreshToken);
                        history.replace("dashboard");
                    }

                    if (!isEmpty(successData.data) && successData?.data?.permissions) {
                        window.localStorage.setItem("permissionItem", JSON.stringify(successData?.data?.permissions));
                        history.replace("dashboard");
                    }
                }
            }

            if (authItemSelector.isErrorLogin) {
                sessionStorage.removeItem("isVerifying");
                if (!isEmpty(authItemSelector.errorData)) {
                    const errorData = authItemSelector.errorData;
                    if (errorData?.ReturnMessage) {
                        toastRef.current!.show({
                            severity: "error",
                            icon: <></>,
                            summary: "Login failed",
                            detail: errorData.ReturnMessage
                        });
                    }
                    if (errorData?.data) {
                        if (errorData.data?.validation?.length) {
                            errorData.data.validation.forEach((valItem: any) => {
                                toastRef!.current!.show({
                                    severity: "error",
                                    icon: <></>,
                                    summary: `Validation Error: ${valItem.dataPath.replace(".", "")}`,
                                    detail: valItem.message
                                });
                            });
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [authItemSelector]);

    useMemo(()=> {
        if (!isEmpty(token)) {
            setIsLoggingIn(true)
            samlCallback(dispatch, token)   
        }
    },[token])

    const getSocialAuth = (name: string) => {
        switch (name) {
            case 'GOOGLE_AUTH':
                return <LoginSocialGoogle
                    key={'GOOGLE'}
                    isOnlyGetToken
                    client_id={config['GOOGLE_AUTH'].CLIENT_ID || ''}
                    scope={(Array.isArray(config['GOOGLE_AUTH'].SCOPES) ? config['GOOGLE_AUTH'].SCOPES.join(' ') : config['GOOGLE_AUTH'].SCOPES) || ''}
                    onLoginStart={() => {
                        console.log("SOCIAL LOGIN START")
                    }}
                    onResolve={({ provider, data }: IResolveParams) => {
                        console.log("provider", provider);
                        console.log("data", data);
                        setIsLoggingIn(true)
                        googleCallback(dispatch, data?.access_token)
                    }}
                    onReject={(err) => {
                        console.log(err)
                    }}
                >
                    <Toast ref={toastRef} />
                    <GoogleLoginButton disabled={isLoggingIn}
                        text={isLoggingIn ? 'Verifying...' : 'Log in with Google'}
                        style={{ borderRadius: '25px' }} />
                </LoginSocialGoogle>

            case 'MICROSOFT_SAML_AUTH':
                return <MicrosoftLoginButton 
                    key={'SAML'}
                    activeStyle={{ background: 'whitesmoke' }} style={{ background: 'white', color: 'black', borderRadius: '25px' }} 
                    disabled={isLoggingIn}
                    text={isLoggingIn ? 'Verifying...' : 'Log in with Microsoft'}
                    onClick={()=> {
                        const redirectUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
                        window.location.replace(process.env.REACT_APP_API_URL + `/users/saml/login?redirectUrl=${redirectUrl}`);
                    }}
                >

                </MicrosoftLoginButton>

            default:
                break;
        }
    }
   
    return (
        <div>
            {config ? Object.keys(config).map((key, item) => {
                console.log("ITEM", key, item);

                return getSocialAuth(key)
            }) : <></>}
        </div>
    )
}

const Signin: React.FC<ILoginType> = (props) => {
    const dispatch = useDispatch();
    const [config, setConfig] = useState<any>(null);
    const { token } = props;

    useEffect(() => {
        authConfig(dispatch).then((response) => {
            console.log(response)
            if (!isEmpty(response.data)) {
                setConfig(response.data)
            }
        })
    }, []);

    return (
        <div className="sign-in">
            <div className="container">
                <Title />
                {props.loginType === "MSAL" ? (
                    <MSALLogin />
                ) : (
                    <LoginForm />
                )}
                {
                    !isEmpty(config) || !isEmpty(token) ? (
                        <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                            <hr></hr>
                            <SocialLogin token={token}/>
                        </div>
                    ) : null
                }
            </div>
        </div>
    );
};

export default Signin;
