/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import { ObjectID } from 'bson';
import { EMIT_FIELD_DATA, EMIT_LOV_DATA, RESET_EMIT_FIELD_DATA, RESET_EMIT_LOV_DATA } from "../constants/beacon";

export function emitData(dispatch: Function, sourcePanel: any, data: any) {
    return new Promise(async (resolve, reject) => {
        resolve(
            dispatch({
            type: EMIT_FIELD_DATA,
            payload: {
                emitKey: new ObjectID().toString(),
                source: sourcePanel,
                data
            }
        }))
    });
}

export const resetEmitData = (dispatch: Function, sourcePanel?: any) : Promise<any> => {

    return new Promise(async (resolve, reject) => {
        resolve(
            dispatch({
                ...{type: RESET_EMIT_FIELD_DATA },
                ...(sourcePanel && {
                    payload: {
                        emitKey: new ObjectID().toString(),
                        source: sourcePanel,
                        type: RESET_EMIT_FIELD_DATA
                    }
                })
            })
        )
    });
};


export function emitLOVData(dispatch: Function, sourcePanel: any, data: any, sourceField: string) {
    return new Promise(async (resolve, reject) => {  
        resolve(
            dispatch({
            type: EMIT_LOV_DATA,
            payload: {
                emitKey: new ObjectID().toString(),
                source: sourcePanel,
                sourceField,
                data
            }
        }))
    });
}

export const resetEmitLOVData = (dispatch: Function, sourcePanel?: any, sourceField?: string) : Promise<any> => {

    return new Promise(async (resolve, reject) => {
        resolve(
            dispatch({
                ...{type: RESET_EMIT_LOV_DATA },
                ...(sourcePanel && {
                    payload: {
                        emitKey: new ObjectID().toString(),
                        source: sourcePanel,
                        sourceField,
                        type: RESET_EMIT_LOV_DATA
                    }
                })
            })
        )
    });
};
