/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PanelButtonData, IPanelButtonDataOnClickAction, IPanelButtonPreAction } from '../../../../models/classes/PanelButtonData';
import { IConsumeAPI } from '../../../../models/interfaces/IConsumeAPI';
import { IFieldValue } from '../../../../models/interfaces/IFieldValue';

import PanelSearchButtonComponent from './PanelSearchButtonComponent';
import PanelSearchFieldComponent from './PanelSearchFieldComponent';

import { exportDataTrigger, consumeAPI, consumeAPISuccess, consumeAPIError } from '../../../../redux/actions/panelItem';
import { parseInputParameterValues } from '../../../../redux/utils/parser';

import { isUrlParamExist, removeParam } from '../../../../utils/url';
import { isEmpty } from '../../../../utils/validation';
import {PanelData} from '../../../../models/classes/PanelData';
import { emitData } from '../../../../redux/actions/beacon';
import { Control, FieldValues } from 'react-hook-form';
import { PanelFieldData } from '../../../../models/classes/PanelFieldData';
import { State } from '../../../../redux/reducers';
import _ from 'lodash';
import { IPostAction } from '../../../../models/interfaces/IPostAction';

interface Props {
    widgetName: string;
    numberOfRows: number;
    numberOfColumns: number;
    panelData: PanelData;
    panelButtons: PanelButtonData[];
}

const PanelSearchComponent: React.FC<Props> = (props) => {
    const {
        widgetName,
        numberOfColumns,
        numberOfRows,
        panelButtons,
        panelData
    } = props;

    const chartName = `${widgetName}_${panelData.PanelName}`;

    const panelItemSelector = useSelector((state: State) => state.panelItem);
    const beaconSelector = useSelector((state: State) => state.beaconItem);

    const { Fields: panelFields } = panelData;

    const [searchFieldValues, setSearchFieldValues] = useState<IFieldValue[] | null | undefined>(null);
    const [queueProcess, setQueueProcess] = useState<any[]>([]);

    const [control, setControl] = useState<Control<FieldValues>>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [panelFieldsData, setPanelFieldsData] = useState<PanelFieldData[]>(panelFields)
    const [keyState, setKeyState] = useState(Date.now());
    const[buttonRef, setButtonRef] = useState(null)
    const[isNeedResubmit, setIsNeedResubmit] = useState(false)
    const isMountedRef = useRef(true);

    const dispatch = useDispatch();
    const history = useHistory();
    const tableRowsPerPageOptions = window.localStorage.getItem("REACT_APP_ROWS_PER_PAGE") ? window.localStorage.getItem("REACT_APP_ROWS_PER_PAGE")?.toString().split(",").map(Number) : [5000,10000, 20000]

    useEffect(() => {
        // Set the ref to false when the component unmounts
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const onButtonClickAction = async (
        targetPanel: string | string[],
        onClickAction: string | IPanelButtonDataOnClickAction[],
        APIName: string,
        buttonName: string,
        targetWidget?: string
    ) => {
        const callAPI = async (targetPanel: string | string[], APIName: string, customInputParameters?: IFieldValue[], targetWidget?: string, action?: string | IPanelButtonDataOnClickAction) => {
            const widget = targetWidget? targetWidget: widgetName;

            console.log("control", control)

            const fieldValues:IFieldValue[] = [];

            panelFields.forEach(f => {
                fieldValues.push({
                    fieldName: f.FieldName,
                    fieldType: f.InputType,
                    value: control!._formValues![f.FieldName],
                    dataType: f.DataType
                })    
            })

            if (isMountedRef.current) {
                setSearchFieldValues(fieldValues);
            }

            let inputParameterValues = parseInputParameterValues(fieldValues); //parseInputParameterValues(searchFieldValues);
            let buttonIdx: number = -1;
            let parsedTargetPanel: string | string[];
            let itemsPerPage: number = parseInt((tableRowsPerPageOptions ? tableRowsPerPageOptions[0] : 5000).toString());

            if (panelButtons) {
                buttonIdx = panelButtons.findIndex(btn => btn.ButtonName === buttonName);
            }

            let postActions : IPostAction[] | undefined = undefined;

            if (typeof action === 'object' && 'PostActions' in action) {
                // action is of type IPanelButtonDataOnClickAction
                postActions = (action as IPanelButtonDataOnClickAction).PostActions;
            }

            if (Array.isArray(targetPanel)) {
                targetPanel.forEach((target) => {
                    var rowsCount: number =  parseInt((window.localStorage.getItem(target + "_ItemsPerPage") || 0).toString())
                    if (rowsCount > 0 ) {
                        itemsPerPage = rowsCount
                    }
                });
            } else {
                var rowsCount: number =  parseInt((window.localStorage.getItem(targetPanel + "_ItemsPerPage") || 0).toString())
                if (rowsCount > 0 ) {
                    itemsPerPage = rowsCount
                }
            }

            if (panelButtons && panelButtons[buttonIdx]?.isTargetTableUseLazyLoad) {
                inputParameterValues.push({
                    fieldName:"Page",
                    fieldType:"PositiveNumberInputText",
                    value:0,
                    dataType:"Number"
                });

                inputParameterValues.push({
                    fieldName:"ItemsPerPage",
                    fieldType:"PositiveNumberInputText",
                    value: itemsPerPage,
                    dataType:"Number"
                });

                inputParameterValues.push({
                    fieldName:"RequestAllData",
                    fieldType:"InputCheckbox",
                    value:false,
                    dataType:"Boolean"
                });

                let newParams = history.location.search;

                if (isUrlParamExist("InputParameterValues")) {
                    newParams = removeParam("InputParameterValues", newParams);
                }

                if (!isEmpty(newParams)) {
                    newParams = `${newParams}&InputParameterValues=${encodeURIComponent(JSON.stringify(inputParameterValues))}`;
                } else {
                    newParams = `?InputParameterValues=${encodeURIComponent(JSON.stringify(inputParameterValues))}`;
                }

                history.push({
                    search: newParams
                });
            }

            if (customInputParameters) {
                for (const inputParameter of customInputParameters) {
                    if (inputParameter.Value) {
                        // Override existing inputParameterValues field value
                        const fieldToModifyIdx = inputParameterValues.findIndex(field => field.fieldName === inputParameter.FieldName);

                        if (fieldToModifyIdx > -1) {
                            inputParameterValues[fieldToModifyIdx].value = inputParameter.Value;
                        } else {
                            inputParameterValues.push({
                                fieldName: inputParameter.fieldName? inputParameter.fieldName: inputParameter.FieldName? inputParameter.FieldName: "",
                                dataType: inputParameter.dataType? inputParameter.dataType: inputParameter.DataType? inputParameter.DataType: "",
                                value: inputParameter.value? inputParameter.value: inputParameter.Value? inputParameter.Value: "",
                            });
                        }
                    }
                }
            }

            if (Array.isArray(targetPanel)) {
                let targetPanels: string[] = [];

                targetPanel.forEach(target => { 
                    targetPanels.push(`${widget}_${target}`)
                    sessionStorage.setItem(`${widget}_${target}`, JSON.stringify(inputParameterValues));
                });

                parsedTargetPanel = targetPanels.reverse();
            } else {
                sessionStorage.setItem(`${widget}_${targetPanel}`, JSON.stringify(inputParameterValues));
                parsedTargetPanel = `${widget}_${targetPanel}`;
            }

            const button = panelButtons[buttonIdx];

            const options: IConsumeAPI = {
                targetPanel: parsedTargetPanel,
                APIName,
                inputParameterValues,
                UseQueue: false,
                isAPIForDataTable: true,
                responseType: button!.ResponseType
            };

            emitData(dispatch, "UniversalSearchPanel", options)
            
            consumeAPI(dispatch, options, (params: any) => {
                if (!isEmpty(params)) {
                    
                    const {
                        success,
                        data,
                        response,
                        rawResponse
                    } = params
                    
                    if (success) {
                        setIsNeedResubmit(false)
                        const targetPanel = (postActions ??[]).map((p:any) => (!isEmpty(p.TargetWidget) ? p.TargetWidget : widget) + '_' + p.TargetPanel)

                        for (let index = 0; index < targetPanel.length; index++) {
                            const panel = targetPanel[index];
                            const actions = (postActions ?? []).filter(pa => ((!isEmpty(pa.TargetWidget) ? pa.TargetWidget : widget) + '_' + pa.TargetPanel) === panel)
                            const isForDataTable = (actions ?? []).filter(a => a.AddRowData || a.UpdateRowData)
                            dispatch(
                                consumeAPISuccess(
                                    panel,
                                    data,
                                    (isForDataTable.length <= 0),
                                    -1,
                                    actions,
                                    response,
                                    rawResponse
                                )
                            );  
                        }
                         
                    } else {
                        const targetPanel =  (postActions ??[]).map((p: any) => (!isEmpty(p.TargetWidget) ? p.TargetWidget : widget) + '_' + p.TargetPanel)

                        for (let index = 0; index < targetPanel.length; index++) {
                            const panel = targetPanel[index];
                            dispatch(
                                consumeAPIError(
                                    panel,
                                    // targetDialog,
                                    !isEmpty(rawResponse) ? (typeof rawResponse.message === "string" ? JSON.stringify(rawResponse.message) : rawResponse.message) : "Internal Server Error",
                                    rawResponse.hasOwnProperty("data") ? rawResponse.data : null,
                                    undefined,
                                    undefined,
                                    postActions,
                                    rawResponse.hasOwnProperty("response") ? rawResponse.response : null,
                                )
                            );
                        }
                    }
                }
            })
        };

        if (Array.isArray(onClickAction)) {
            for (const clickAction of onClickAction.reverse()) {
              await callAPI(clickAction.TargetPanel, clickAction.APIName, clickAction.InputParameters, clickAction.TargetWidget, clickAction);
            }
        } else {
            if (onClickAction === "CallAPI") callAPI(targetPanel, APIName, [], widgetName, onClickAction);
            else if (onClickAction === "ExportData") exportDataTrigger(dispatch, `${widgetName}_${targetPanel}`);
        }
    };

    const callConditionalGroup = async (APIName: string, callback:(data: any) => void) => {
        const fieldValues:IFieldValue[] = [];

        panelFields.forEach(f => {
            fieldValues.push({
                fieldName: f.FieldName,
                fieldType: f.InputType,
                value: control!._formValues![f.FieldName],
                dataType: f.DataType
            })    
        })

        setSearchFieldValues(fieldValues);

        let inputParameterValues = parseInputParameterValues(fieldValues);
        let buttonIdx: number = -1;
        let itemsPerPage: number = parseInt((tableRowsPerPageOptions ? tableRowsPerPageOptions[0] : 5000).toString());

        if (panelButtons && panelButtons[buttonIdx]?.isTargetTableUseLazyLoad) {
            inputParameterValues.push({
                fieldName:"Page",
                fieldType:"PositiveNumberInputText",
                value:0,
                dataType:"Number"
            });

            inputParameterValues.push({
                fieldName:"ItemsPerPage",
                fieldType:"PositiveNumberInputText",
                value: itemsPerPage,
                dataType:"Number"
            });

            inputParameterValues.push({
                fieldName:"RequestAllData",
                fieldType:"InputCheckbox",
                value:false,
                dataType:"Boolean"
            });

            let newParams = history.location.search;

            if (isUrlParamExist("InputParameterValues")) {
                newParams = removeParam("InputParameterValues", newParams);
            }

            if (!isEmpty(newParams)) {
                newParams = `${newParams}&InputParameterValues=${encodeURIComponent(JSON.stringify(inputParameterValues))}`;
            } else {
                newParams = `?InputParameterValues=${encodeURIComponent(JSON.stringify(inputParameterValues))}`;
            }

            history.push({
                search: newParams
            });
        }

        const options: IConsumeAPI = {
            targetPanel: "",
            APIName,
            inputParameterValues,
            UseQueue: false,
            isAPIForDataTable: true
        };

        emitData(dispatch, "UniversalSearchPanel", options)
        
        consumeAPI(dispatch, options, (params: any) => {
            if (!isEmpty(params)) {
                
                const {
                    success,
                    data,
                    response,
                    rawResponse
                } = params

                if (success) {
                    callback(rawResponse.data)    
                }
            }
        })
    };

    useEffect(() => {
        let newParams = history.location.search;

        if (isUrlParamExist("InputParameterValues")) {
            newParams = removeParam("InputParameterValues", newParams);
            history.push({
                search: newParams
            });
        }

        if (!isEmpty(panelData?.LoadData) && !isEmpty(panelData?.LoadData.APIName)) {
            // Load data
            let inputParameterValues: IFieldValue[] = [];

            panelData?.LoadData?.APIParameters?.forEach(
                (APIParam: PanelFieldData) => {
                    inputParameterValues.push({
                        fieldName: APIParam.FieldName,
                        dataType: APIParam.DataType,
                        value: APIParam.Value,
                    } as IFieldValue);
                }
            );

            const options: IConsumeAPI = {
                targetPanel: chartName,
                APIName: panelData.LoadData.APIName!,
                inputParameterValues,
                UseQueue: false,
                isAPIForDataTable: false
            };

            setIsLoading(true)

            setTimeout(() => {
                consumeAPI(dispatch, options);
            }, 500);

            setTimeout(() => {
                if (panelData.LoadData?.RefreshInterval) {
                    // RefreshInterval is in second, so we need to times it with 1000
                    setInterval(() => {
                        setIsLoading(true)
                        consumeAPI(dispatch, options);
                    }, panelData.LoadData?.RefreshInterval * 1000);
                }
            }, 1000);
        }
        // eslint-disable-next-line
    }, [chartName]);

    useEffect(() => {
        if (!isEmpty(panelItemSelector)) {
            
            // TODO: Implement this code when publish/subscribe component is available
            // if (
            //     (
            //         panelItemSelector?.isLoadingGetTableData &&
            //         (
            //             Array.isArray(panelItemSelector?.loadingData?.targetPanel.includes(chartName)) ?
            //                 panelItemSelector?.loadingData?.targetPanel.includes(chartName) :
            //                 panelItemSelector?.loadingData?.targetPanel === chartName
            //         )
            //     ) ||
            //     (
            //         panelItemSelector?.isResettingGetTableData &&
            //         (
            //             Array.isArray(panelItemSelector?.resetData?.targetPanel.includes(chartName)) ?
            //                 panelItemSelector?.resetData?.targetPanel.includes(chartName) :
            //                 panelItemSelector?.resetData?.targetPanel === chartName
            //         )
            //     )
            // ) {
            //     // resetSubscriber()
            //     // resetData();
            // }

            const getValueIgnoreCase = (obj:any, propName: string) => {
                for (let key in obj) {                    
                  if (key.toLowerCase() === propName.toLowerCase()) {
                    return obj[key];
                  }
                }
                return null;
            }


            if (
                panelItemSelector.isSuccessSubmitActionData &&
                panelItemSelector.successData.targetPanel === chartName
            ) {
                
                // Receive data from a LoadData Action
                
                if (panelItemSelector.successData) {
                    if (panelItemSelector.successData.done) {

                    } else {
                        if ((panelItemSelector.successData.data ?? []).length === 0) {
                            //panelMessageSetValue("Empty data");
                        } else {
                            if (Array.isArray(panelItemSelector.successData.data) && panelItemSelector.successData.data.length > 0) {
                                const data = panelItemSelector.successData.data[0];
                                if(!isEmpty(data)) {
                                    const LABEL_COLOR_FLAG = '_HPO_LABEL_COLOR'    
                                    const VALUE_COLOR_FLAG = '_HPO_VALUE_COLOR'    
                                    
                                    setPanelFieldsData([])
    
                                    Object.keys(data).forEach(field => {
                                        panelFields.forEach(panelField => {
    
                                            const labelColorFieldName = panelField.FieldName.toUpperCase() + LABEL_COLOR_FLAG;
                                            const valueColorFieldName = panelField.FieldName.toUpperCase() + VALUE_COLOR_FLAG;
                                            const labelColor = getValueIgnoreCase(data, labelColorFieldName) || _.get(panelField.LabelFormatStyle, 'color', null);
                                            const valueColor = getValueIgnoreCase(data, valueColorFieldName) || _.get(panelField.ValueFormatStyle, 'color', null);
    
                                            if (!isEmpty(labelColor)) {
                                                if (isEmpty(panelField.LabelFormatStyle)) {
                                                    panelField.LabelFormatStyle = {}
                                                }                                            
                                                Object.assign(panelField.LabelFormatStyle, { color: labelColor })
                                            }
    
                                            if (!isEmpty(valueColor)) {
                                                if (isEmpty(panelField.ValueFormatStyle)) {
                                                    panelField.ValueFormatStyle = {}
                                                }                                            
                                                Object.assign(panelField.ValueFormatStyle, { color: valueColor })
                                            }
    
                                            if (panelField.FieldName === field) {
                                                panelField.Value = data[field]
                                                panelField.DefaultValue = data[field]
                                            }
                                        });                                    
                                        setKeyState(Date.now())
                                        setPanelFieldsData(panelFields)
                                    });
                                }
                                
                            }
                            
                        }
                    }
                }
                setIsLoading(false)

            }
        }
        // eslint-disable-next-line
    }, [panelItemSelector, chartName]);


    useEffect(() => {
        if (!isEmpty(beaconSelector)) {
            if (
                beaconSelector.source === "UniversalSearchPanel" &&
                beaconSelector.data?.hasOwnProperty("inputParameterValues") &&
                beaconSelector.data?.targetPanel === chartName
            ) {

                const options: IConsumeAPI = {
                    targetPanel: chartName,
                    APIName: beaconSelector.data.APIName!,
                    inputParameterValues: beaconSelector.data.inputParameterValues as IFieldValue[],
                    UseQueue: false,
                    isAPIForDataTable: false
                };
    
                setIsLoading(true)
    
                setTimeout(() => {
                    consumeAPI(dispatch, options);
                }, 500);
              
                //resetSubscriber()
            }

            if (!isEmpty(panelData.SubscribeComponents) && Array.isArray(panelData.SubscribeComponents)) {

                for (let index = 0; index < panelData.SubscribeComponents.length; index++) {
                    const subcriber = panelData.SubscribeComponents[index];
                    if (!isEmpty(subcriber.Source)) {
                        if (!isEmpty(subcriber.Source.PanelName) && !isEmpty(subcriber.Source.WidgetName)) {
                            const source = `${subcriber.Source.WidgetName}_${subcriber.Source.PanelName}`
                            if (source === beaconSelector.source) {
                                if (!isEmpty(subcriber.Actions)) {
                                    subcriber.Actions.forEach((action) => {
                                        if (action.ActionType === 'FetchData') {
                                            if (action?.FetchOption?.APIParameters) {
                                                let inputParameterValues: any = [];
                                                let APIParameters: any = []

                                                const options: IConsumeAPI = {
                                                    targetPanel: chartName,
                                                    APIName: action.FetchOption.APIName,
                                                    inputParameterValues: inputParameterValues as IFieldValue[],
                                                    UseQueue: false,
                                                    isAPIForDataTable: false
                                                };

                                                action.FetchOption.APIParameters.forEach((param) => {
                                                    let value;
        
                                                    APIParameters.push({
                                                        FieldName: param.FieldName,
                                                        DataType: param.DataType,
                                                        DefaultValue: param.DefaultValue
                                                    } as  PanelFieldData)
        
                                                    if (param.DefaultValue) {
                                                        value = param.DefaultValue;
                                                    }
        
                                                    if (param?.ValueSource === "TableSelectedItem" ||
                                                        param?.ValueSource === "MapSelectedItem" ||
                                                        param?.ValueSource === "ChartSelectedItem" || 
                                                        param?.ValueSource === "PieChartSelectedItem" ||
                                                        param?.ValueSource === "SunburstSelectedItem") {
                                                        let fetchedData;

                                                        if (param?.ValueSource === "MapSelectedItem" && _.get(beaconSelector.data, "UseArray", false)) {
                                                            options.UseArray = true;
                                                            inputParameterValues = beaconSelector.data.data.map((dataItem:any) => {
                                                                const convertedData = action.FetchOption.APIParameters.map((p:any) => {
                                                                  return {
                                                                    fieldName: p.FieldName,
                                                                    value: dataItem[p.FieldName],
                                                                    dataType: p.DataType
                                                                  };
                                                                });
                                                                return convertedData;
                                                              })    
                                                        } else {
                                                            fetchedData = beaconSelector.data.find((emittedData: any) => (
                                                                emittedData.DataName === param.ValueSource &&
                                                                emittedData.DataSource === param.FieldName
                                                            ));
            
                                                            if (fetchedData) {
                                                                value = fetchedData.EmittedValue;
                                                                if (param.DefaultValue) {
                                                                    value = param.DefaultValue;
                                                                }
                                                            }

                                                            inputParameterValues.push({
                                                                fieldName: param.FieldName,
                                                                value,
                                                                dataType: param.DataType
                                                            });
                                                        }
                                                    }
                                                  
                                                });

                                                options.inputParameterValues = inputParameterValues;
                                    
                                                setIsLoading(true)
                                    
                                                setTimeout(() => {
                                                    consumeAPI(dispatch, options);
                                                }, 500);
                                            }
                                            
                                        }
                                    })
                                }
                            }
                        }
                    }
                    
                }
                
            }

        }

        // eslint-disable-next-line
    }, [beaconSelector]);

    const triggerSubmit = () => {
        if (buttonRef && isNeedResubmit) {
            setTimeout(() => {
                (buttonRef! as React.MutableRefObject<any>)?.current?.click()    
            }, 200);
        }
    };

    return (
        <div className="panel-search">
            {
               !isLoading && panelFieldsData ?
                    <PanelSearchFieldComponent
                        key={keyState}
                        numberOfRows={numberOfRows}
                        numberOfColumns={numberOfColumns}
                        panelFields={panelFieldsData}
                        onSearchFieldValuesChanged={setSearchFieldValues}
                        onSubmit={(_: any)=> {}}
                        onControlSet={(control:  Control<FieldValues>) => {
                            setControl(control)
                        }}
                        panelSubscribeComponents={panelData.SubscribeComponents}
                        chartName={chartName}
                        container={{
                            widgetName,
                            panelName: panelData.PanelName,
                            queueProcess,
                            setQueueProcess,
                            triggerSubmit
                        }}
                    /> : "Loading Data..."
            }
            <PanelSearchButtonComponent
                panelButtons={panelButtons}
                setButtonRef={setButtonRef}
                onButtonClicked={async (
                    targetPanel: string,
                    onClickAction: any,
                    apiName: string,
                    buttonName: string,
                    targetWidget: string) =>{
                        setIsNeedResubmit(true);
                        if (!isEmpty(queueProcess) && queueProcess?.length > 0) {
                            return;
                        }

                        if (!isEmpty(panelFieldsData)) {
                            for (let index = 0; index < (panelFieldsData ?? []).length; index++) {
                                const data = (panelFieldsData ?? [])[index];
                                if (!isEmpty(data) && !isEmpty(data.validationStatus!)) {
                                    const { isLoadingCallApi, isSuccessCallApi, inputRef, setInputFocus } = data.validationStatus
                                    if (isLoadingCallApi) return;
        
                                    if (inputRef && setInputFocus) {
                            
                                        await setInputFocus();  // Await focus before setting error
                                        
                                        if (inputRef.current) {
                                            inputRef.current.classList.add(
                                                'p-invalid', 
                                                'p-inputtext', 
                                                'p-component');
                    
                                            setTimeout(() => {
                                                if (!isEmpty((control ?? {})._formValues![data.FieldName])) {
                                                    inputRef.current.blur()
                                                }
                                            }, 500);
                                        } 
                                    }
                    
                                    if(!isSuccessCallApi) return;    
                                }
                            }
                        }

                        if (!control || Object.keys((control ?? {})?._formState?.errors!).length <= 0) {
                            onButtonClickAction(targetPanel, onClickAction, apiName, buttonName, targetWidget)
                        } else {
                            for (const key of Object.keys((control ?? {})?._formState?.errors!)) {
                                const field = (control ?? {})?._formState?.errors[key] as any;
                                const { handleBlur } = field!;
                                if (handleBlur) {
                                    await handleBlur()
                                }
                            }
                        }                  
                }}
                conditionalGroupAction={(preAction: IPanelButtonPreAction, callback: (data: any) => void) => {
                    if (preAction) {
                        callConditionalGroup(preAction.APIName, (data: any) => {
                            callback(data);
                        })
                    }
                }}
            />
        </div>
    );
};

export default PanelSearchComponent;
