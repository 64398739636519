/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";

import { DialogField } from "../models/classes/DialogField";
import { isEmpty, isNull } from "./validation";
import { Editor } from "primereact/editor";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { ControllerRenderProps } from "react-hook-form";
import { KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import { Tooltip } from "primereact/tooltip";
import { getTodayDate } from "./date";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../redux/reducers";
import { emitData, emitLOVData, resetEmitData, resetEmitLOVData } from "../redux/actions/formFieldItem";
import { IAPIOption } from "../models/interfaces/IAPIOption";
import api from "../common/api";
import { RESET_EMIT_FIELD_DATA } from "../redux/constants/beacon";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Workspace from "../containers/Workspace";
import _, { lowerCase } from "lodash";
import { useGlobalState } from "../App";
import FileUpload from "../components/FileUpload";
import { IConsumeAPI } from "../models/interfaces/IConsumeAPI";
import { consumeAPI, resetForm } from "../redux/actions/panelItem";
import { ProgressBar } from "primereact/progressbar";
import useFocus from "./hooks/useFocus";
import numeral from 'numeral';
import { ColorPicker } from 'primereact/colorpicker';
export interface IFieldGeneratorProps {
    reference: ControllerRenderProps<Record<string, any>>,
    field: DialogField,
    ref?: any,
    customProps?: any,
    fieldState: any,
    onChange?: (data: any) => void
    onValidated?: (data: any) => void
    onLOVSelected?: (data: any) => void
}

type RGB = {
    r: number;
    g: number;
    b: number;
};

const FieldGenerator: React.FC<IFieldGeneratorProps> = (props) => {
    let reference = props.reference, field = props.field, customProps = props.customProps, fieldState = props.fieldState;
    let onFieldChange = props.onChange;
    let onValidated = props.onValidated;

    const beaconSelector = useSelector((state: State) => state.formFieldItem);
    const tableSelector = useSelector((state: State) => state.beaconItem);
    const panelItemSelector = useSelector((state: State) => state.panelItem);

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingBar, setIsLoadingBar] = useState<any>({});
    const [selectedDropdown, setSelectedDropdown] = useState<any>(null);
    const [selectedDate, handleDateChange] = useState<any>(null);
    const [dropdownOptions, setDropdownOptions] = useState<{ LabelValue: string, LabelDescription: string }[]>([]);
    const [visible, setVisible] = useState(false);
    const [lovData, setLovData] = useState<any>(null);
    const [selectedLovData, setSelectedLovData] = useState<{ FieldValue: any } | null>(null);
    const [appData, setAppData] = useGlobalState('appData');
    const [lastValue, setLastValue] = useState("");

    const { chartName, container, setDialog, dialog } = customProps;
    const { form } = container;
    const { reset, setFocus, clearErrors, setValue } = form;
    const [inputRef, setInputFocus] = useFocus<any>()


    useEffect(() => {

        if (selectedDropdown === undefined) { // Selected dropdown values have been cleared
            resetEmitData(dispatch, {
                WidgetName: _.get(container, "widgetName", ""),
                PanelName: _.get(container, "panelName", ""),
                FieldName: field.FieldName
            })
        }
    }, [selectedDropdown]);

    useEffect(() => {
        if (field.InputType == "Dropdown") {
            if (field?.ListOfValues?.length > 0) {
                const options = [];
                for (const listOfValues of field.ListOfValues) {
                    const values: string[] = Object.values(listOfValues);

                    options.push({
                        LabelDescription: values.length > 1 ? values[1] : values[0],
                        LabelValue: values[0]
                    })

                    if (values[0] === (field.value || "")) {
                        setSelectedDropdown({
                            LabelDescription: values.length > 1 ? values[1] : values[0],
                            LabelValue: values[0]
                        })
                        publishFieldValue(field.value)
                    }
                }

                setDropdownOptions(options)
            }

        }
        if (field.InputType == "Calendar") {
            if (field.DefaultValue && !isEmpty(field.value)) {
                handleDateChange(moment(field.value, field.FieldFormat ?? "YYYY-MM-DD").toDate())
            } else {
                const fieldFormat = isEmpty(field.FieldFormat) ? "MM/DD/YYYY" : field.FieldFormat!.toUpperCase().replace("yyyy", "yy"); // MM/DD/YYYY to mm/dd/yy
                let valueDate = field.value;
                if (moment(valueDate, fieldFormat).isValid()) {
                    handleDateChange(moment(valueDate, fieldFormat).toDate())
                }
            }
        }
        if (field.InputType == "Datetime") {
            if (field.DefaultValue && !isEmpty(field.value)) {
                handleDateChange(moment(field.value, field.FieldFormat ?? "YYYY-MM-DD HH:mm:ss").toDate())
            } else {
                const fieldFormatDateTime = isEmpty(field.FieldFormat) ? "MM/DD/YYYY hh:mm A" : field.FieldFormat!.toUpperCase().replace("yyyy", "yy"); // MM/DD/YYYY to mm/dd/yy
                let valueDateTime = field.value;
                if (moment(valueDateTime, fieldFormatDateTime).isValid()) {
                    handleDateChange(moment(valueDateTime, fieldFormatDateTime).toDate())
                }
            }
        }
    }, [field]); // Only re-run the effect if field changes

    const resetFieldState = async (isManualReset: boolean) => {
        const resetValue: { [key: string]: any } = {
            "": "",
            "Calendar": null,
            "Datetime": null
        };
    
        if (isManualReset) {
            setTimeout(async () => {
                const value = resetValue[field.InputType || ''] || '';
    
                const copyFields = dialog.fields?.map((f:any) => 
                    f.FieldName === field.FieldName ? { ...f, value } : f
                ) ?? [];
    
                await setDialog({ ...dialog, fields: copyFields });
    
                if (field.InputType === 'Calendar' || 
                    field.InputType === 'Datetime' || 
                    field.InputType === 'Date'
                ) {
                    handleDateChange(null);
                }
    
                setValue(field.FieldName, value); // This will update the value of the specific field without affecting others
    
                field.value = null;
            }, 50);
        }
    
        setSelectedDropdown(null);
        setDropdownOptions([]);
    
        // Notify all subscriber fields that parents have been reset
        resetEmitData(dispatch, {
            WidgetName: _.get(container, "widgetName", ""),
            PanelName: _.get(container, "panelName", ""),
            FieldName: field.FieldName
        });
    };
    

    function compareObjects(source1: any, source2: any): boolean {
        
        if (isEmpty(source1) || isEmpty(source2)) {
            return false
        }

        if (isEmpty(source2.PanelName) || isEmpty(source2.WidgetName)) {
            return source1.FieldName === source2.FieldName;
        } else {
            // Compare all fields
            return JSON.stringify(source1) === JSON.stringify(source2);
        }
    }

    useEffect(() => {
        if (!isEmpty(beaconSelector) && !isEmpty(field.SubscribeComponent)) {
            field.SubscribeComponent.forEach((subscribeInfo) => {
                if (typeof beaconSelector.source === 'object' &&
                    !Array.isArray(beaconSelector.source) &&
                    beaconSelector.source !== null) {
                        
                    if (compareObjects(beaconSelector.source, subscribeInfo?.Source)) {

                        if (!isEmpty(beaconSelector.type) && beaconSelector.type === RESET_EMIT_FIELD_DATA) {
                            resetFieldState(true);
                        } else {
                            if (!isEmpty(beaconSelector?.data)) {
                                   
                                resetFieldState(false);

                                const copyFields = [...(dialog.fields ?? [])]

                                let fetchedData: any;
                                fetchedData = beaconSelector.data.find((emittedData: any) => (
                                    emittedData.DataName === "FieldSelectedItem" &&
                                    emittedData.DataSource === field.FieldName
                                ));
                                
                                if (fetchedData) {
                                    copyFields.forEach(cf => {
                                        if (cf.FieldName === field.FieldName) {
                                            let value = fetchedData!['EmittedValue'];
    
                                            if (field.InputType === "Calendar") {
                                                if (field.DefaultValue && !isEmpty(value)) {
                                                    handleDateChange(moment(value, field.FieldFormat ?? "YYYY-MM-DD").toDate())
                                                } else {
                                                    const fieldFormat = isEmpty(field.FieldFormat) ? "MM/DD/YYYY" : field.FieldFormat!.toUpperCase().replace("yyyy", "yy"); // MM/DD/YYYY to mm/dd/yy
                                                    let valueDate = value;
                                                    if (moment(valueDate, fieldFormat).isValid()) {
                                                        handleDateChange(moment(valueDate, fieldFormat).toDate())
                                                    }
                                                }
    
                                            }
                                            if (field.InputType === "Datetime") {
                                                if (field.DefaultValue && !isEmpty(value)) {
                                                    handleDateChange(moment(value, field.FieldFormat ?? "YYYY-MM-DD HH:mm:ss").toDate())
                                                } else {
                                                    const fieldFormatDateTime = isEmpty(field.FieldFormat) ? "MM/DD/YYYY hh:mm A" : field.FieldFormat!.toUpperCase().replace("yyyy", "yy"); // MM/DD/YYYY to mm/dd/yy
                                                    let valueDateTime = field.value;
                                                    if (moment(valueDateTime, fieldFormatDateTime).isValid()) {
                                                        handleDateChange(moment(valueDateTime, fieldFormatDateTime).toDate())
                                                    }
                                                }
    
                                            }
    
                                            cf.value = fetchedData!['EmittedValue']
                                            field.value = fetchedData!['EmittedValue']
                                            reference.onChange(fetchedData!['EmittedValue'])
                                            reference.value = fetchedData!['EmittedValue'];
                                        }
                                    })
    
                                    setDialog({ ...dialog, fields: copyFields })
    
                                }
                             

                                if (subscribeInfo?.Actions) {
                                    subscribeInfo.Actions.forEach(async (action) => {
                                        if (action.ActionType === "FetchDropdownData") {
                                            if (action?.FetchOption?.APIParameters) {
                                                let inputParameterValues: any = [];

                                                action.FetchOption.APIParameters.forEach((param) => {
                                                    let value;

                                                    if (param.DefaultValue) {
                                                        value = param.DefaultValue;
                                                    }

                                                    if (
                                                        param?.ValueSource === "FieldSelectedItem"
                                                    ) {
                                                        /**
                                                         * beaconSelector.data: [
                                                         *  {
                                                         *      DataName: "FieldSelectedItem",
                                                         *      DataSource: "LabelValue" // emmited dropdown field name
                                                         *      EmittedValue: 1 // emmited dropdown field value
                                                         *  }
                                                         * ]
                                                         */
                                                        let fetchedData;

                                                        fetchedData = beaconSelector.data.find((emittedData: any) => (
                                                            emittedData.DataName === param.ValueSource &&
                                                            emittedData.DataSource === param.FieldName
                                                        ));

                                                        value = isEmpty(fetchedData) ? null : fetchedData.EmittedValue

                                                    }

                                                    inputParameterValues.push({
                                                        fieldName: param.FieldName,
                                                        value,
                                                        dataType: param.DataType
                                                    });

                                                });

                                                let requestBody = {
                                                    APIName: action.FetchOption?.APIName,
                                                    inputParameterValues,
                                                };

                                                const apiOption: IAPIOption = {
                                                    path: "query_management",
                                                    method: "POST",
                                                    data: requestBody
                                                }

                                                field.ListOfValues = [];
                                                try {

                                                    setIsLoading(true);
                                                    var loadingItem = {
                                                        LabelDescription: "Loading...",
                                                        LabelValue: ""
                                                    };

                                                    setDropdownOptions([loadingItem]);
                                                    setSelectedDropdown(loadingItem);

                                                    const response = await api(apiOption);

                                                    setDropdownOptions([]);
                                                    setSelectedDropdown(null);

                                                    if (response && response.success) {
                                                        if (!isEmpty(response.data)) {
                                                            let data = response.data;
                                                            field.ListOfValues = data;
                                                            const options: { LabelValue: string, LabelDescription: string }[] = [];
                                                            if (!isEmpty(data) && Array.isArray(data)) {
                                                                for (const listOfValues of data) {
                                                                    const values: string[] = Object.values(listOfValues);

                                                                    options.push({
                                                                        LabelDescription: values.length > 1 ? values[1] : values[0],
                                                                        LabelValue: values[0]
                                                                    })
                                                                }

                                                                setDropdownOptions(options)
                                                            }
                                                        }
                                                    }
                                                    setIsLoading(false);
                                                } catch (error) {
                                                    setDropdownOptions([]);
                                                    setSelectedDropdown(null);
                                                    setIsLoading(false);
                                                }



                                            }
                                        }
                                    })
                                }
                            }
                        }

                    }
                }

            });
        } else if(!isEmpty(beaconSelector) && beaconSelector.source === `${chartName}___LOV_DATA`) {
            
            if (!isEmpty(beaconSelector?.data)) {
                for (const item of beaconSelector.data) {
                    if (field.FieldName === item.DataSource) {
                        
                        field.value = item.EmittedValue 

                        if (field.InputType === "InputCheckbox") {

                            if (isNull(field.value)) {
                                field.value = false;
                            } else {
                                if (parseInt(field.value ?? 0) === 1) {
                                    field.value = true;
                                }
                                if (parseInt(field.value ?? 0) === 0) {
                                    field.value = false;
                                }
                                if (lowerCase(field.value) === 'true') {
                                    field.value = true;    
                                }
                            }   
                        }

                        if (reference) {
                            reference.onChange(field.value)
                            reference.value = field.value
                        }
                    }
                }
            }
        }

        if (!isEmpty(tableSelector) && !isEmpty(field.FieldConfiguration)) {
            if (!isEmpty(tableSelector.source) && typeof tableSelector.source === 'string') {
                
                if (tableSelector.source === 'RESET_LOV') {
                    setLovData(null)
                    return;
                } else if (beaconSelector.source === 'RESET_LOV' && beaconSelector.type === 'RESET_EMIT_LOV_DATA') {
                    
                    if (field.FieldConfiguration && field.FieldConfiguration.Workspace.Widgets) {
                        field.FieldConfiguration.Workspace.Widgets.forEach(widget => {

                            if (widget.SubscribeComponent) {
                                widget.SubscribeComponent.forEach(fieldSub => {
                                    if (fieldSub?.Source.FieldName === beaconSelector.sourceField && 
                                        field.FieldName !== beaconSelector.sourceField) {
                                        
                                        if (appData) {
                                            for (let index = 0; index < (appData[`${chartName}___LOV`] ??[]).length; index++) {
                                                const element = appData[`${chartName}___LOV`][index] as any;
                                                if (!isEmpty(element) && !isEmpty(element['LOV_' + field.FieldName])) {
                                                    delete appData[`${chartName}___LOV`][index];
                                                    setLovData(null);
                                                    field.value = null;
                                                    if (reference) {
                                                        reference.onChange(field.value)
                                                        reference.value = field.value
                                                    }
                                                    const component = document.getElementById(field.FieldName) as HTMLInputElement
                                                    if (component) {
                                                        component.value = field.value;
                                                    }
                                                    resetEmitLOVData(dispatch, 'RESET_LOV', field.FieldName)
                                                } else {
                                                    if (reference) {
                                                        reference.onChange("")
                                                        reference.value = ""
                                                    }
                                                    resetEmitLOVData(dispatch, 'RESET_LOV', field.FieldName)
                                                }
                                            }
                                        }
                                         
                                    }
                                })
                            }
                        });
                    }
                }

                if (!isEmpty(tableSelector.data) && Array.isArray(tableSelector.data)) {
                    setLovData(tableSelector.data)
                }
            }
        }

    }, [beaconSelector, tableSelector])

    const addQueue = (FieldName: string) => {
        // Ensure dialog and progressQueue are properly initialized
        if (!dialog || !dialog.progressQueue) {
            console.error('Dialog or progressQueue is not initialized.');
            return;
        }
    
        // Check if the field already exists in the array
        const exists = dialog.progressQueue.some((item: any) => item.FieldName === FieldName);
    
        if (!exists) {
            setDialog((prevDialog: any) => {
                // Ensure prevDialog and prevDialog.progressQueue are properly initialized
                if (!prevDialog || !prevDialog.progressQueue) {
                    console.error('prevDialog or prevDialog.progressQueue is not initialized.');
                    return prevDialog; // Or handle this case as necessary
                }
    
                return {
                    ...prevDialog,
                    progressQueue: [...prevDialog.progressQueue, field],
                };
            });
        } else {
            console.log('Field already exists in the queue');
        }
    };
    

    const removeQueue = (FieldName: string) => {
        setDialog((prevDialog: any) => {
            if (!prevDialog || !prevDialog.progressQueue) {
                // Handle the case where prevDialog or prevDialog.progressQueue is null/undefined
                return prevDialog; // Or return some default state if necessary
            }
    
            return {
                ...prevDialog,
                progressQueue: prevDialog.progressQueue.filter((item: any) => item.FieldName !== FieldName),
            };
        });
    };    

    useEffect(() => {
        // Early return if panelItemSelector is empty
        if (isEmpty(panelItemSelector)) return;
    
        const { isLoadingCallApi, isSuccessCallApi, isErrorCallApi, successData, errorData, loadingData } = panelItemSelector;
        const { targetPanel, data, meta } = successData ?? errorData ?? loadingData ?? {};
        const { FieldName } = meta ?? {};        
    


        // Early return if the target panel does not match the chart name and field name
        if (`${targetPanel}_${FieldName || ''}` !== `${chartName}_${field.FieldName}`) return;
        
        // Check if the current field is the one being loaded
        const isCurrentField = FieldName === field.FieldName;
    
        // Set loading state based on API call status
        if (isCurrentField) {
            setIsLoading(isLoadingCallApi);
            setIsLoadingBar({ [field.FieldName]: isLoadingCallApi });
            field.validationStatus = {
                ...field.validationStatus,
                isLoadingCallApi, isSuccessCallApi, isErrorCallApi
            }
        }
    
        // Early return if there is an error
        if (isErrorCallApi && isCurrentField) {
            let message = null;
            const fieldName = _.get(errorData, "meta.FieldName", field.FieldName)

            if (!isEmpty(errorData)) {
                message = _.get(errorData, "response.ReturnMessage", "")
            }

            const validateInput = async (message:string) => {
                
                removeQueue(fieldName)

                if (inputRef) {
                    await setInputFocus();  // Await focus before setting error
                }
                container!.formRef!.setError(fieldName, {
                    message: message || "",
                    type: 'manual'
                })
                field.validationStatus = {
                    message: message || "",
                    type: 'manual',
                    inputRef,
                    setInputFocus,
                }
                
            }
            validateInput(message);
            return ;
        };
    
    
        console.log(isLoadingCallApi, isSuccessCallApi, isErrorCallApi, successData, errorData, loadingData)
        // Early return if data is empty or if the API call was not successful
        if (isEmpty(data) && !isSuccessCallApi) {
            return;
        };

        clearErrors(FieldName || field.FieldName)

        removeQueue(FieldName || field.FieldName);

        field.validationStatus = null;
        // Determine the data to be published
        const publishData = Array.isArray(data) && data.length > 0 ? data[0] : data;
    
        // Publish the field value
        publishFieldValue(publishData);
    
        // Set loading to false after processing
        setIsLoading(false);
        setIsLoadingBar({ [field.FieldName]: false });

        setTimeout(() => {
            if (onValidated) {
                onValidated({valid: true})
            }
        }, 100);
    
    }, [panelItemSelector, setFocus]);
    

    const publishFieldValue = async (value: any) => {
        if (isEmpty(field.PublishComponent)) return;
    
        for (const p of field.PublishComponent) {
            if (p.DataName !== "FieldSelectedItem") continue;
    
            const dataToEmit:any[] = [];
    
            if (field.InputType === "Dropdown" && field?.ListOfValues?.length) {
                field.ListOfValues.forEach(listOfValues => {
                    if (Object.values(listOfValues)[0] === (value || "")) {
                        Object.keys(listOfValues).forEach(key => {
                            dataToEmit.push({
                                DataName: "FieldSelectedItem",
                                DataSource: key,
                                EmittedValue: listOfValues[key]
                            });
                        });
                    }
                });
            } else if (field.InputType === "InputText") {
                Object.keys(value).forEach(key => {
                    dataToEmit.push({
                        DataName: "FieldSelectedItem",
                        DataSource: key,
                        EmittedValue: value[key]
                    });
                });
            }
    
            if (dataToEmit.length > 0) {
                await emitData(dispatch, {
                    WidgetName: _.get(container, "widgetName", ""),
                    PanelName: _.get(container, "panelName", ""),
                    FieldName: field.FieldName
                }, dataToEmit);
            }
        }
    };    

    const handleBlur = useCallback(async () => {
        if (!isEmpty(field)) {

            if (inputRef && inputRef.current) {
                inputRef.current.classList.remove('p-invalid');
            }

            const currentValue = field.value

            if (isEmpty(currentValue)) {
                if (!field.Mandatory) {
                    field.validationStatus = null
                }
                setLastValue(currentValue);
                resetFieldState(false)
                await resetForm(dispatch, chartName);
                return;
            }

            if (isLoadingBar[field.FieldName]) return;
            
            if (currentValue !== lastValue || !isEmpty(field.validationStatus)) {
                
                setLastValue(currentValue);
                resetFieldState(false)
                if (!isEmpty(field.Actions)) {
                    addQueue(field.FieldName)
                    let inputParameterValues: any = [];
                    inputParameterValues.push({
                        fieldName: field.FieldName,
                        value: currentValue,
                        dataType: field.DataType
                    });
                    console.log(chartName, "chartName")
                    field.validationStatus = null;
                    for (let index = 0; index < field.Actions!.length; index++) {
                        const action = field.Actions![index];
                        const apiOption: IConsumeAPI = {
                            targetPanel: chartName,
                            meta: {
                                FieldName: field.FieldName
                            },
                            APIName: action.APIName,
                            inputParameterValues,
                            UseQueue: false,
                            isAPIForDataTable: false,
                            isCallApiOnly: true
                        }
                        consumeAPI(dispatch, apiOption, (response:any) => {
                            removeQueue(field.FieldName)
                        });
                        field.validationStatus = {};
                    }
                }
            }
        }

    }, [lastValue, field]);
 
    const closeDialog = () => {
        setVisible(false);
        setLovData(null);
    };

    const cssToRgb = (css: string): RGB | null => {
        if (css) {
            const rgbRegex = /rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)/;
            const match = css.match(rgbRegex);
            
            if (match) {
                const r = parseInt(match[1], 10);
                const g = parseInt(match[2], 10);
                const b = parseInt(match[3], 10);
                return { r, g, b };
            }
        }
        return null; 
    }

    const rgbToCss = (rgb: RGB): string => {
        const { r, g, b } = rgb;
        return `rgb(${r}, ${g}, ${b})`;
    }

    switch (field.InputType) {
        case "FloatingInputText":
        case "PositiveNumberInputText":
            return (
                <InputText
                    {...reference}
                    id={field.FieldName.toString().replace(",", "")}
                    ref={inputRef}
                    className={classNames({
                        "p-inputtext p-component": true,
                        "p-invalid": fieldState.invalid
                    })}
                    keyfilter={field.InputType === "PositiveNumberInputText" ? "pnum" : "num"}
                    defaultValue={field.value || ""}
                    disabled={field.disabled}
                    style={{ ...customProps?.style }}
                    onChange={(e) => {
                        field.value = isEmpty(e) ? null : e.target.value;
                        reference.onChange(isEmpty(e) ? null : e.target.value)
                        reference.value = isEmpty(e) ? null : e.target.value;
                    }}
                />
            );
        case "InputText":
            return (
                <>
                    <InputText
                        {...reference}
                        //className="p-inputtext p-component"
                        id={field.FieldName}
                        name={field.FieldName}
                        ref={inputRef}
                        className={classNames({
                            "p-inputtext p-component": true,
                            "p-invalid": fieldState.invalid
                        })}
                        defaultValue={field.value || ""}
                        disabled={field.disabled}
                        style={{ ...customProps?.style }}
                        onKeyPress={()=> {setLastValue(field.value)}}
                        onChange={(e) => {
                            field.value = isEmpty(e) ? null : e.target.value;
                            reference.onChange(isEmpty(e) ? null : e.target.value)
                            reference.value = isEmpty(e) ? null : e.target.value;
                        }}
                        onBlur={handleBlur}

                    />
                    {isLoadingBar[field.FieldName] && <ProgressBar mode="indeterminate" style={{ height: '2px' }}></ProgressBar>}
                </>

            );
        case "InputTextArea":
            return (
                <textarea
                    {...reference}
                    //className="p-inputtext p-component"
                    id={field.FieldName}
                    name={field.FieldName}
                    className={classNames({
                        "p-inputtext p-component": true,
                        "p-invalid": fieldState.invalid
                    })}
                    rows={5}
                    cols={33}
                    ref={inputRef}
                    defaultValue={field.value || ""}
                    disabled={field.disabled}
                    style={{ ...customProps?.style }}
                    onChange={(e) => {
                        field.value = e.target.value;
                        reference.onChange(e.target.value)
                        reference.value = e.target.value;
                    }}
                />
            );
        case "InputNumber":
            return (
                <>
                    <InputNumber
                        {...reference}
                        id={field.FieldName}
                        name={field.FieldName}
                        ref={inputRef}
                        value={field.value || ""}
                        disabled={field.disabled}
                        style={{ ...customProps?.style }}
                        {...(!isEmpty(field.InputNumberProps)) && { ...field.InputNumberProps }}
                        tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                        tooltipOptions={{
                            position: field.TooltipPosition || 'right'
                        }}
                        className={classNames({
                            "p-invalid": fieldState.invalid
                        })}
                        onValueChange={(e) => {
                            field.value = e.value;
                            reference.onChange(e.value)
                            reference.value = e.value;
                        }}
                    />
                </>

            );
        case "InputDecimal":
            if (!isEmpty(field.value)) {
                const parsedValue = numeral(field.value).value();
                if (parsedValue !== null) {
                    field.value = parsedValue;
                }
            }
            return (
                <>
                    <InputNumber
                        {...reference}
                        id={field.FieldName}
                        name={field.FieldName}
                        ref={inputRef}
                        value={field.value}
                        disabled={field.disabled}
                        style={{ ...customProps?.style }}
                        {...(!isEmpty(field.InputNumberProps)) && { ...field.InputNumberProps }}
                        tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                        tooltipOptions={{
                            position: field.TooltipPosition || 'right'
                        }}
                        className={classNames({
                            "p-invalid": fieldState.invalid
                        })}
                        mode="decimal"
                        onValueChange={(e) => {
                            let value = e.value;
                            if (!isEmpty(value)) {
                                const parsedValue = numeral(value).value();
                                if (parsedValue !== null) {
                                    value = parsedValue;
                                }
                            }
                            console.log("ON VALUE CHANGE", value);
                            field.value = value;
                            reference.onChange(value)
                            reference.value = value;
                        }}
                    />
                </>

            );
        case "InputPassword":
            return (
                <input
                    {...reference}
                    // className="p-inputtext p-component"
                    id={field.FieldName}
                    type="password"
                    name={field.FieldName}
                    ref={inputRef}
                    defaultValue={field.value || ""}
                    disabled={field.disabled}
                    className={classNames({
                        "p-inputtext p-component": true,
                        "p-invalid": fieldState.invalid
                    })}
                    style={{ ...customProps?.style }}
                    onChange={(e) => {
                        field.value = e.target.value;
                        reference.onChange(e.target.value)
                        reference.value = e.target.value;
                    }}
                />
            );
        case "InputLOV": 
            const footerContent = lovData ? (
                <div>
                    <Button label="Select" icon="pi pi-check" onClick={() => {
                        if (lovData) {
                            let fieldValue = null;
                            for (const emitedData of lovData) {
                                if (emitedData.DataSource === field.FieldName && field.InputType === "InputLOV") {
                                    fieldValue = emitedData.EmittedValue
                                    break;
                                }
                                if (!isEmpty(field.FieldConfiguration?.FieldValue) &&
                                    (emitedData.DataSource === field.FieldName && field.InputType === "InputLOV")) {
                                    fieldValue = emitedData.EmittedValue
                                    break;
                                }
                            }
                            setSelectedLovData({
                                FieldValue: fieldValue
                            })

                            const value = fieldValue
                            reference?.onChange(value)
                            reference!.value = value
                            
                            let data = appData;
                            if (isEmpty(data) || isEmpty(data[`${chartName}___LOV`])) {
                                data = {
                                    'LOV': []
                                };

                                Object.assign(data, {
                                    [`${chartName}___LOV`]: []
                                })
                            }
                            
                            const index = data['LOV'].findIndex((x: any) => {
                                return x && !isEmpty(x['LOV_' + field.FieldName])
                            })

                            if (index < 0) {
                                data[`${chartName}___LOV`].push({
                                    ['LOV_' + field.FieldName]: lovData
                                })
                            } else {
                                data[`${chartName}___LOV`][index] = {
                                    ['LOV_' + field.FieldName]: lovData
                                }
                            }

                            setAppData(data)
                            
                        }
                        // field.value = selectedLovData ? selectedLovData.FieldValue : null;
                        // reference.onChange(field.value ?? "")
                        // reference.value = field.value ?? "";
                        resetEmitLOVData(dispatch, 'RESET_LOV', field.FieldName).then(x => {
                            emitLOVData(dispatch, `${chartName}___LOV_DATA`, lovData, field.FieldName)
                            closeDialog();
                        });
                    }} autoFocus />
                </div>
            ) : undefined;
            return (
                <div className="">
                    <InputText
                        {...reference}
                        id={field.FieldName}
                        name={field.FieldName}
                        ref={inputRef}
                        className={classNames({
                            "p-inputtext p-component": true,
                            "p-invalid": fieldState.invalid
                        })}
                        // defaultValue={selectedLovData ? selectedLovData.FieldValue : ""}
                        // readOnly={true}
                        readOnly={field.EditableLOV === undefined || field.EditableLOV === null ? false : !field.EditableLOV}
                        style={{ ...customProps?.style }}
                    />
                    <Button disabled={field.disabled} icon="pi pi-search" className="p-button-grey" type="button" onClick={() => setVisible(true)} />
                    <Dialog
                        visible={visible}
                        onHide={closeDialog}
                        style={{
                            minWidth: "250px",
                            maxWidth: "100vw",
                            maxHeight: "90%",
                            width: isEmpty( field.FieldConfiguration?.DialogWidth) ? "60%" : field.FieldConfiguration?.DialogWidth,
                            overflow: "auto"
                        }}
                        showHeader={false}
                        footer={!isEmpty(lovData) ? footerContent : undefined}
                    >
                        <div
                            style={{
                                width: "100%",
                                height: "40px",
                                position: "relative"
                            }}
                        >
                            <Button
                                icon="pi pi-times"
                                iconPos="right"
                                className="p-button-secondary"
                                label="Close"
                                style={{
                                    position: "absolute",
                                    right: 0
                                }}
                                onClick={closeDialog}
                            />
                        </div>
                        {visible && <Workspace workspaceData={field.FieldConfiguration?.Workspace} />}
                    </Dialog>
                </div>
            )
        case "Dropdown":
            let showValue: boolean;

            const dropdownTemplate = (option: any) => {
                return (
                    <div>
                        {showValue ? (
                            <>
                                <span>{option.LabelValue}</span>
                                <br />
                            </>
                        ) : null}
                        {
                            field.DisplayAllField && option.Values ? option.Values.map((element: any, index: any) =>
                                index > 0 ? (<span> {element} </span>) : null
                            ) : <span>{option.LabelDescription}</span>
                        }
                    </div>
                );
            };

            const dropdownSelectedTemplate = (option: any) => {
                return (
                    <div>
                        {showValue && option ? (
                            <>
                                <span>{option.LabelValue}</span>
                                <br />
                            </>
                        ) : null}
                        <span>{option ? option.LabelDescription : ""}</span>
                    </div>
                );
            };

            return (
                <>
                    <input
                        {...reference}
                        ref={inputRef}
                        id={`input-${field.FieldName}`}
                        name={field.FieldName}
                        className="ui-helper-hidden-accessible"
                        hidden
                    />

                    {
                        (
                            isLoading
                        ) ? (
                            <div style={{display: 'grid'}}>
                                <div className="p-dropdown p-component p-inputwrapper p-dropdown-clearable">
                                    <div className="p-hidden-accessible">
                                    </div>
                                    <div className="p-hidden-accessible p-dropdown-select"></div>
                                    <span className="p-dropdown-label p-inputtext p-dropdown-label"><div><span>Loading...</span></div></span>
                                </div>
                            </div>
                        ) : <Dropdown
                            {...reference}
                            ref={inputRef}
                            name={field.FieldName}
                            value={field.value !== undefined && field.value !== null ? field.value : ""}
                            optionLabel="LabelDescription"
                            optionValue="LabelValue"
                            options={dropdownOptions}
                            disabled={field.disabled || isLoading}
                            virtualScrollerOptions={!isEmpty(field.VirtualScrollerOptions) && field.VirtualScrollerOptions?.Enable ? {
                                itemSize: field.VirtualScrollerOptions.ItemSize || 38
                            } : {
                                itemSize: 38
                            }}
                            style={{ ...customProps?.style }}
                            valueTemplate={dropdownSelectedTemplate}
                            itemTemplate={dropdownTemplate}
                            scrollHeight="100px"
                            showClear
                            filter
                            tooltip={isEmpty(field.Tooltip) ? undefined : field.Tooltip}
                            tooltipOptions={{
                                position: field.TooltipPosition || 'right'
                            }}
                            className={classNames({
                                "p-invalid": fieldState.invalid
                            })}

                            onChange={(e) => {
                                field.value = e.value;
                                setSelectedDropdown(e.value);
                                reference.onChange(e.value)
                                reference.value = e.value;
                                publishFieldValue(e.value);
                                const component = document.getElementById(`input-${field.FieldName}`) as HTMLInputElement
                                if (component) {
                                    component.value = `${e.target.value}`;
                                }
                            }}
                        />
                    }

                </>

            );
        case "Calendar":
            // const fieldFormat = isEmpty(field.FieldFormat) ? "MM/DD/YYYY" : field.FieldFormat!.toUpperCase().replace("yyyy", "yy"); // MM/DD/YYYY to mm/dd/yy
            // let valueDate = field.value;
            // if (moment(valueDate, fieldFormat).isValid()) {
            //     handleDateChange(moment(valueDate, fieldFormat).toDate())
            // }

            return (
                <>
                    {!isEmpty(field.Tooltip) ? <Tooltip target={`#Calendar-${field.FieldName.toString().replace(",", "")}`} position={field.TooltipPosition ?? 'right'} content={field.Tooltip} /> : null}
                    <KeyboardDatePicker
                        key={`date-${field.FieldName}`}
                        // variant="inline"
                        //className={classes.dateTimePicker}
                        {...reference}
                        id={`Calendar-${field.FieldName.toString().replace(",", "")}`}
                        name={field.FieldName}
                        value={selectedDate}
                        format={field.FieldFormat || "MM/DD/YYYY"}
                        disabled={field.disabled}
                        onChange={(e) => {
                            handleDateChange(isEmpty(e) ? null : e?.toDate().toLocaleDateString());
                            field.value = e;
                            reference.onChange(e)
                            reference.value = e;
                        }}
                        className={classNames({
                            "p-invalid": fieldState.invalid
                        })}
                        style={{ ...customProps?.style }}
                        // autoOk
                        InputProps={{
                            disableUnderline: true,
                        }}
                        showTodayButton
                        {
                        ...(
                            (
                                !isEmpty(field.MinDate)
                            ) &&
                            { minDate: getTodayDate(field.MinDate) }
                        )
                        }
                        {
                        ...(
                            (
                                !isEmpty(field.MaxDate)
                            ) &&
                            { maxDate: getTodayDate(field.MaxDate) }
                        )
                        }
                    />
                </>
            );
        case "Datetime":
            // const fieldFormatDateTime = isEmpty(field.FieldFormat) ? "MM/DD/YYYY hh:mm A" : field.FieldFormat!.toUpperCase().replace("yyyy", "yy"); // MM/DD/YYYY to mm/dd/yy
            // let valueDateTime = field.value;
            // if (moment(valueDateTime, fieldFormatDateTime).isValid()) {
            //     handleDateChange(moment(valueDateTime, fieldFormatDateTime).toDate())
            // }
            return (
                <>
                    <div data-tooltip={field.Tooltip ? (field.TooltipPosition || "right") : null} aria-label={field.Tooltip}>
                        <KeyboardDateTimePicker
                            key={`DateTime-${field.FieldName}`}
                            // variant="inline"
                            autoOk
                            //className={classes.dateTimePicker}
                            {...reference}
                            id={`DateTime-${field.FieldName.toString().replace(",", "")}`}
                            name={field.FieldName}
                            value={selectedDate}
                            disabled={field.disabled}
                            format={field.FieldFormat || "MM/DD/YYYY hh:mm A"}
                            onChange={(e) => {
                                handleDateChange(isEmpty(e) ? "" : e?.toDate());
                                field.value = e;
                                reference.onChange(e)
                                reference.value = e;
                            }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            className={classNames({
                                "p-invalid": fieldState.invalid
                            })}
                            style={{ ...customProps?.style }}
                            showTodayButton
                            {
                            ...(
                                (
                                    !isEmpty(field.MinDate)
                                ) &&
                                { minDate: getTodayDate(field.MinDate) }
                            )
                            }
                            {
                            ...(
                                (
                                    !isEmpty(field.MaxDate)
                                ) &&
                                { maxDate: getTodayDate(field.MaxDate) }
                            )
                            }
                        />
                    </div>
                </>
            );
        case "InputCheckbox":
            if (isNull(field.value)) {
                field.value = false;
            } else {
                if (parseInt(field.value ?? 0) === 1) {
                    field.value = true;
                }
                if (parseInt(field.value ?? 0) === 0) {
                    field.value = false;
                }
            }
            return (
                <input
                    {...reference}
                    {...(field.value === true) && { checked:true }}
                    type="checkbox"
                    id={field.FieldName}
                    name={field.FieldName}
                    ref={inputRef}
                    defaultChecked={field.value || false}
                    disabled={field.disabled}
                    className={classNames({
                        "p-invalid": fieldState.invalid
                    })}
                    onChange={(e) => {
                        field.value = e.target.checked;
                        reference.onChange(e.target.checked)
                        reference.value = e.target.checked;
                    }}
                    style={{ ...customProps?.style }}
                />
            );
        case "Editor":
            const style = {};
            if (field.EditorHeight) {
                Object.assign(style, { height: field.EditorHeight })
            }

            if (field.EditorWidth) {
                Object.assign(style, { width: field.EditorWidth })
            }

            return (
                <>
                    <Editor style={style}
                        value={field.value || ""}
                        className={classNames({
                            "p-invalid": fieldState.invalid
                        })}
                        onTextChange={(e) => {
                            const component = document.getElementById(`input-${field.FieldName}`)
                            if (component !== null) component.innerHTML = e.htmlValue || ''
                            if (onFieldChange) {
                                onFieldChange(e.htmlValue || '')
                            }
                        }} />

                    <textarea
                        {...reference}
                        className="p-inputtext p-component"
                        id={`input-${field.FieldName}`}
                        name={field.FieldName}
                        ref={inputRef}
                        value={field.value}
                        hidden
                    >
                    </textarea>
                </>
            );
        case "FileUpload":
            return (
                <FileUpload field={field} register={reference} onFileChange={(file:File | null) => {}}
                ></FileUpload>
            )
        case "ColorRadioButton":
            const colorValue = field.value 
            ? (field.value) 
            : (field.DefaultValue 
                ? field.DefaultValue
                : field.ColorPickType === "hex" ? "#ff0000" : "rgb(255, 0, 0)");
            field.value = colorValue
            return (
                <ColorPicker 
                    value={field.ColorPickType === "hex" ? colorValue : cssToRgb(colorValue)} 
                    onChange={(e) => {
                        if (e.value) {
                            if (field.ColorPickType === "hex") {
                                field.value = `#${e.value}`;
                                reference.onChange(`#${e.value}`);
                                reference.value = `#${e.value}`;
                            } else {
                                if (typeof e.value === "object" && 'r' in e.value && 'g' in e.value && 'b' in e.value) {
                                    const rgbString = rgbToCss(e.value);
                                    field.value = rgbString;
                                    reference.onChange(rgbString);
                                    reference.value = rgbString;
                                }
                            }
                        }
                    }}
                    disabled={!field.Editable}
                    hidden={field.Hidden}
                    format={field.ColorPickType ? field.ColorPickType : "rgb"}
                />
            )
        default:
            return <span>Unsupported field: {field.InputType}</span>
    }
};

export default FieldGenerator;