/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import { isEmpty } from "lodash";
import { IAPIOption } from "../models/interfaces/IAPIOption";
import { removeToken } from "../utils/jwt";

const api = (options: IAPIOption, callback?: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let { path, method, headers, signal, data, responseType } = options;
        method = method || "GET";

        let hasFile = !isEmpty((data?.inputParameterValues ?? []).find((i: any) => !isEmpty(i) && !isEmpty(i.dataType) && i.dataType!.toLowerCase() === 'file'));

        if (!isEmpty(data) && (data.UseArray ?? false)) {
            if (!isEmpty(data?.inputParameterValues) && Array.isArray(data?.inputParameterValues[0])) {
                hasFile = !isEmpty((data?.inputParameterValues[0] ?? []).find((i: any) => !isEmpty(i) && !isEmpty(i.dataType) && i.dataType!.toLowerCase() === 'file'));
            }
        }

        if (process.env.REACT_APP_API_URL) {
            const url = `${process.env.REACT_APP_API_URL}/${path}`;
            const requestOptions: any = { method };
            const jwtToken = window.localStorage.getItem("jwtToken");

            if (signal) requestOptions["signal"] = signal;
            requestOptions["headers"] = {};

            if (method === "POST") {
                if (hasFile && !isEmpty(data)) {
                    const formData = new FormData();
                    formData.append('APIName', data.APIName);
                    formData.append('UseQueue', data.UseQueue.toString());
                    formData.append('UseArray', (data.UseArray ?? false)!.toString());

                    if ((data.UseArray ?? false)) {
                        for (const key in data.inputParameterValues) {
                            if (Array.isArray(data.inputParameterValues[key])) {
                                data.inputParameterValues[key].forEach((element: any, index: number) => {
                                    for (const subKey in element) {
                                        formData.append(`inputParameterValues[${key}][${index}][${subKey}]`, element[subKey]);
                                    }
                                });
                            } else {
                                data.inputParameterValues[key].forEach((element: any, index: number) => {
                                    for (const subKey in element) {
                                        formData.append(`inputParameterValues[${index}][${subKey}]`, element[subKey]);
                                    }
                                });
                            }
                        }
                    } else {
                        if (Array.isArray(data.inputParameterValues)) {
                            data.inputParameterValues.forEach((element: any, index: number) => {
                                for (const subKey in element) {
                                    formData.append(`inputParameterValues[${index}][${subKey}]`, element[subKey]);
                                }
                            });
                        }
                    }

                    requestOptions["body"] = formData;
                } else {
                    requestOptions["headers"]["Content-Type"] = "application/json";
                    requestOptions["body"] = JSON.stringify(data);
                }
            }

            if (jwtToken) requestOptions["headers"]["Authorization"] = `Bearer ${jwtToken}`;
            if (headers) {
                requestOptions.headers = { ...requestOptions.headers, ...headers };
            }

            const makeRequest = async (retry: boolean = false): Promise<void> => {
                try {
                    const response = await fetch(url, requestOptions);

                    if (response.status === 401 && !retry) {
                        const refreshTokenResponse = await fetch(`${process.env.REACT_APP_API_URL}/users/refresh-token`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                refreshToken: localStorage.getItem('jwtRefreshToken')
                            })
                        });

                        if (refreshTokenResponse.ok) {
                            const refreshData = await refreshTokenResponse.json();
                            const { token, refreshToken } = refreshData.data.data;

                            localStorage.setItem('jwtToken', token);
                            localStorage.setItem('jwtRefreshToken', refreshToken);

                            requestOptions.headers.Authorization = `Bearer ${token}`;
                            return makeRequest(true);
                        } else {
                            console.error('Refresh token failed');
                            removeToken();
                            return reject(); //No need to display error message
                        }
                    } else {
                        switch (responseType) {
                            case "json":
                                resolve(response.json());
                                break;
                            case "text":
                                resolve(response.text());
                                break;
                            case "blob":
                                
                                const contentDisposition = response.headers.get('Content-Disposition');
                                let filename = 'default-filename.xlsx'; // Default filename
                                
                                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                const matches = filenameRegex.exec(contentDisposition || filename);
                              
                                if (matches != null && matches[1]) {
                                    filename = matches[1].replace(/['"]/g, ''); // Remove any quotes around the filename
                                }

                                resolve({ filename, data: response.blob() });
                                break;
                            case "arraybuffer":
                                resolve(response.arrayBuffer());
                                break;
                            default:
                                resolve(response.json());
                                break;
                        }
                        if (callback) {
                            callback(response);
                        }
                    }
                } catch (error) {
                    reject(`Fetch data error ${error}`);
                }
            };

            makeRequest();
        } else {
            alert("Please configure the app's API URL!");
        }
    });
};

export default api;