/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

import { LOGIN, LOGIN_CONFIG, LOGIN_CONFIG_ERROR, LOGIN_CONFIG_SUCCESS, LOGIN_ERROR, LOGIN_SUCCESS } from "../constants/auth";
import { IAPIOption } from "../../models/interfaces/IAPIOption";
import api from "../../common/api";

export const login = (dispatch: Function, userData: any) => {
    dispatch({
        type: LOGIN,
        payload: {username: userData.Username}
    });

    const apiOption: IAPIOption = {
        path: "users/signin",
        method: "POST",
        data: userData
    };

    api(apiOption)
        .then((response: any) => {
            if (response) {
                if (response.success) {
                    dispatch(loginSuccess(response.data))
                } else {
                    dispatch(loginError(response.message))
                }
            }
        }).catch(err => dispatch(loginError(err)));
};

export const verify = (dispatch: Function, token: any) => {
    
    dispatch({
        type: LOGIN,
        payload: {}
    });

    const apiOption: IAPIOption = {
        path: "users/verify",
        method: "GET",
        headers: {
            'Authorization' : 'Bearer ' + token
        }
    };

    api(apiOption)
        .then((response: any) => {
            if (response) {
                if (response.success) {
                    dispatch(loginSuccess(response.data))
                } else {
                    dispatch(loginError(response.message))
                }
            }
        }).catch(err => dispatch(loginError(err)));
};



export const googleCallback = (dispatch: Function, token: any) => {
    
    dispatch({
        type: LOGIN,
        payload: {}
    });

    const apiOption: IAPIOption = {
        path: "users/google/callback?access_token="+ token,
        method: "GET"
    };

    api(apiOption)
        .then((response: any) => {
            if (response) {
                if (response.success) {
                    dispatch(loginSuccess(response.data))
                } else {
                    dispatch(loginError(response.message))
                }
            }
        }).catch(err => dispatch(loginError(err)));
};


export const samlCallback = (dispatch: Function, token: any) => {
    
    dispatch({
        type: LOGIN,
        payload: {}
    });

    const apiOption: IAPIOption = {
        path: "users/saml/verify",
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {
            secure: true
        }
    };

    api(apiOption)
        .then((response: any) => {
            if (response) {
                if (response.success) {
                    dispatch(loginSuccess(response.data))
                } else {
                    dispatch(loginError(response.message))
                }
            }
        }).catch(err => dispatch(loginError(err)));
};


export const authConfig = (dispatch: Function): Promise<any> => {

    dispatch({
        type: LOGIN_CONFIG,
        payload: {}
    });

    const apiOption: IAPIOption = {
        path: "users/oauth-config",
        method: "GET"
    };

    return api(apiOption)
        .then((response: any) => {
            if (response) {
                if (response.success) {
                   if(dispatch) dispatch(loginConfigSuccess(response.data));
                } else {
                   if(dispatch) dispatch(loginConfigError(response.message));
                }
                return response;
            }
        }).catch(err => {if(dispatch) dispatch(loginConfigError(err))});
}

export const loginSuccess = (data: any) => {
    return {
        type: LOGIN_SUCCESS,
        payload: data
    };
};

export const loginError = (message: any) => {
    return {
        type: LOGIN_ERROR,
        payload: message
    };
};


export const loginConfigSuccess = (data: any) => {
    return {
        type: LOGIN_CONFIG_SUCCESS,
        payload: data
    };
};

export const loginConfigError = (message: any) => {
    return {
        type: LOGIN_CONFIG_ERROR,
        payload: message
    };
};

