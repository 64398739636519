/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

import { IPublishComponent, ISubscribeComponent,  } from "./PanelData";
import {PanelFieldData} from "./PanelFieldData";
import { WorkspaceData } from "./WorkspaceData";

export class DialogField extends PanelFieldData {
    constructor(tableField: PanelFieldData, disabled: boolean, value: any) {
        super();
        this.disabled = disabled;
        this.value = value;
        this.DefaultValue = tableField.DefaultValue;
        this.FieldName = tableField.FieldName;
        this.FieldLabel = tableField.FieldLabel;
        this.DataType = tableField.DataType;
        this.FieldFormat = tableField.FieldFormat;
        this.FieldWidth = tableField.FieldWidth;
        this.FilterType = tableField.FilterType;
        this.FilterItems = tableField.FilterItems;
        this.GroupLabel = tableField.GroupLabel;
        this.Hidden = tableField.Hidden;
        this.InputType = tableField.InputType;
        this.IsBatchField = tableField.IsBatchField;
        this.DefaultValue = tableField.DefaultValue;
        this.Mandatory = tableField.Mandatory;
        this.Editable = tableField.Editable;
        this.EditableLOV = tableField.EditableLOV;
        this.DisplayPosition = tableField.DisplayPosition;
        this.ListOfValues = tableField.ListOfValues;
        this.IntRange = tableField.IntRange;
        this.EditorHeight = tableField.EditorHeight;
        this.EditorWidth = tableField.EditorWidth;
        this.InputNumberProps = tableField.InputNumberProps;
        this.MinDate = tableField.MinDate;
        this.MaxDate = tableField.MaxDate;
        this.Locale = tableField.Locale;
        this.FieldConfiguration = tableField.FieldConfiguration;
        this.Actions = tableField.Actions;
        this.ColorPickType = tableField.ColorPickType;
    }
    disabled: boolean = true;
    value: any = null;
    validationStatus: any = null;

    PublishComponent: IPublishComponent[] = [];
    SubscribeComponent: ISubscribeComponent[] = [];
    FieldConfiguration?: {
        FieldValue: string,
        DialogWidth: string,
        Workspace: WorkspaceData 
    };
}