/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

import React, { useRef } from "react";
import ContextMenuItemList from "./components/ContextMenuItemList";

import "./index.css";

interface IContextMenu {
    contextMenuItems: any;
    children?: any;
}

function ContextMenu(props: IContextMenu) {
    const { contextMenuItems, children } = props;

    const containerRef = useRef(null);

    return (
        <div className="context-menu-wrapper" ref={containerRef}>
            {children}

            <ContextMenuItemList
                parentRef={containerRef}
                contextMenuItems={contextMenuItems}
            />
        </div>
    );
};

export default ContextMenu;