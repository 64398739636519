/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

import {
    TOGGLE_SIDEBAR_MENU,
    API_GET_SIDEBAR_MENU,
    API_GET_SIDEBAR_MENU_ERROR,
    API_GET_SIDEBAR_MENU_SUCCESS
} from "../constants/sidebarItem";

import {IAction} from '../../models/interfaces/IAction';

import {
    errorGetSidebarData,
    startGetSidebarData,
    successGetSidebarData
} from "../utils/sidebarItem";

export default (state = {}, action: IAction) => {
    let loadingData, successData, errorData;
    switch (action.type) {
        case TOGGLE_SIDEBAR_MENU:
            return {
                isSidebarVisible: action.payload?.isSidebarVisible
            };
        case API_GET_SIDEBAR_MENU:
            loadingData = action.payload;
            return Object.assign({}, startGetSidebarData(), {loadingData});
        case API_GET_SIDEBAR_MENU_ERROR:
            errorData = action.payload;
            return Object.assign({}, errorGetSidebarData(), {errorData});
        case API_GET_SIDEBAR_MENU_SUCCESS:
            successData = action.payload;
            return Object.assign({}, successGetSidebarData(), {successData});
        default:
            return state;
    }
}