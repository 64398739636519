/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import {
    HashRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './styles/primeflex.css';
import './styles/prime_custom_style.css';
import './styles/react-datetime.css';

import store from './redux';

import Dashboard from './pages/Dashboard';
import Signin from './pages/Signin';
import { removeToken } from './utils/jwt';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { createGlobalState } from 'react-hooks-global-state';
import { isEmpty } from 'lodash';

const initialState = {appData: {
    'LOV': [{}]
} as any};
export const { useGlobalState } = createGlobalState(initialState);

const App: React.FC = () => {
    const [loginType, setLoginType] = useState<String>('FORM');
    const [token, setToken] = useState<String | undefined>()

    useEffect(() => {
        console.log("Version 0.8.0");
        console.log('API requests are forwarded to: ', process.env.REACT_APP_API_URL);
        const jwtToken: string | null = window.localStorage.getItem("jwtToken");

        console.log("LOGIN TYPE", process.env.REACT_APP_LOGIN_TYPE)
        setLoginType(process.env.REACT_APP_LOGIN_TYPE || "FORM")


        const url = new URL(window.location.origin + "/" + window.location.hash);        
        const searchParams = new URLSearchParams(url.hash);
        let paramToken = ""
        searchParams.forEach((value: string, key: string, parent: URLSearchParams) => {
            if (key.includes('#/signin?token')) {
                paramToken = value;
                setLoginType('SAML')
            }
        });

        if (process.env.REACT_APP_ROWS_PER_PAGE) {
            if (window.localStorage.getItem("REACT_APP_ROWS_PER_PAGE") !== process.env.REACT_APP_ROWS_PER_PAGE) {
                window.localStorage.setItem("REACT_APP_ROWS_PER_PAGE", process.env.REACT_APP_ROWS_PER_PAGE)
            }
        }

        if (process.env.REACT_APP_EXPORT_TYPE) {
            window.localStorage.setItem("REACT_APP_EXPORT_TYPE", process.env.REACT_APP_EXPORT_TYPE)
        } else {
            window.localStorage.setItem("REACT_APP_EXPORT_TYPE", "csv")
        }

        if (jwtToken) {
            // If the URL includes "signin" or is exactly "#/", redirect to the Dashboard
            if (window.location.hash.includes("signin") || window.location.hash === "#/") {
                window.location.hash = "Dashboard";
            }
        } else {
            // Check if the parameter token is not empty
            if (!isEmpty(paramToken)) { 
                setLoginType('SAML');
                setToken(paramToken);
            } else {
                // If no token is available, clear session storage and redirect to signin
                window.sessionStorage.clear();
                window.location.hash = "/signin";
            }
        }        
    }, []);

    return (
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router>
                    <Switch>
                        <Route exact path="/signin">
                            <Signin loginType={loginType} token={token} />
                        </Route>
                        <Route path="/Dashboard">
                            <Dashboard />
                        </Route>
                    </Switch>
                </Router>
            </MuiPickersUtilsProvider>
        </Provider>
    );
};

export default App;
