import React, { useState } from 'react';
import classNames from 'classnames';

import "./styles/index.css";

const TabPanel = (props: {header: string, children: React.ReactNode}) => {
  return (
    <>
      { props.children }
    </>
  );
};

const TabView = (props: {className?: string, children: React.ReactNode}) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <ul className={classNames("tab-view", props.className)}>
        {
          React.Children.map(props.children, (element: any, index: number) => {
            if (element?.props) {
              const { header } = element.props;

              return (
                <li>
                  <button
                    key={index}
                    style={{
                      borderColor: activeTab === index? "#2196F3": "transparent transparent #dee2e6 transparent",
                      color: activeTab === index? "#2196F3": "#6c757d",
                    }}
                    onClick={() => setActiveTab(index)}
                  >
                    { header }
                  </button>
                </li>
              );
            }
          })
        }
      </ul>

      {
        React.Children.map(props.children, (element: any, index: number) => (
          <div
            key={index}
            className="tab-panel"
            style={{
              display: activeTab === index? "block": "none"
            }}
          >
            { element }
          </div>
        ))
      }
    </>
  );
};

export { TabPanel, TabView };
