/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */
import { IHelpButton } from '../../models/classes/PanelData';
import { IAction } from '../../models/interfaces/IAction';

export default (state = [], action: IAction) => {
    switch (action.type) {
        case 'TOGGLE_HELP_BUTTON':
            return action.payload as IHelpButton[];
        case 'TOGGLE_SAVE_CONFIG_BUTTON':
            return action.payload as IHelpButton[];
        case 'ON_SAVE_CONFIG_BUTTON':
            return action.payload as any;
        default:
            return state;
    }
}