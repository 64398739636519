import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from "../../redux/reducers";
import { PanelData } from '../../models/classes/PanelData';
import { WidgetData } from '../../models/classes/WidgetData';
import ReactMapboxGl, { Feature, GeoJSONLayer, Layer, Popup } from "react-mapbox-gl-extended";
import "./index.css";
import * as turf from "@turf/turf";
import { isEmpty } from 'lodash';


const Map = ReactMapboxGl({
    accessToken:
        process.env.REACT_APP_MAP_BOX_TOKEN || ""
});

interface Props {
    mapData: any[];
    center: {
        Latitude: number | 0,
        Longitude: number | 0
    } | undefined,
    panelName: string;
    onClick?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
    silentLoad: boolean;
    widgetData: WidgetData;
    panelData: PanelData;
    panelWidth: number;
    hideMapPanel: boolean | undefined
}

const MapChart: React.FC<Props> = (props) => {

    const { hideMapPanel, panelData, widgetData, panelName, panelWidth, mapData, center, onClick, silentLoad } = props;
    const beaconSelector = useSelector((state: State) => state.beaconItem);
    const panelItemSelector = useSelector((state: State) => state.panelItem);

    const { WidgetName } = widgetData;
    const chartName = `${WidgetName}_${panelData.PanelName}`;

    const mapboxElRef = useRef(null); // DOM element to render map

    const [mounted, setMounted] = useState(false)
    const [markers, setMarkers] = useState<any[]>([])
    const [marker, setMarker] = useState<any>(undefined)
    const [selectedMarker, setSelectedMarker] = useState<any>(null)
    const [getCenter, setCenter] = useState<any>()
    const [getNearBy, setNearBy] = useState<any>(null)

    const styles = {
        "londonCycle": "mapbox://styles/mapbox/light-v9",
        "light": "mapbox://styles/mapbox/light-v9",
        "dark": "mapbox://styles/mapbox/dark-v9",
        "basic": "mapbox://styles/mapbox/basic-v9",
        "outdoor": "mapbox://styles/mapbox/outdoors-v10"
    }

    var radius = 100;
    if (!mounted) {
        sessionStorage.removeItem(chartName);
    }

    useEffect(() => {

        setMounted(true)
    }, [])


    useEffect(() => {
        if (!silentLoad) {
            setMarker(null)
            setSelectedMarker(null)
            setNearBy(null)
            setCenter([center?.Longitude as number, center?.Latitude as number])
        }
        setMarkers(mapData);
    }, [center?.Latitude, center?.Longitude, mapData, silentLoad])

    const markerClick = (station: any, { feature }: { feature: any }) => {
        setMarker(feature);
        var data: any = {}
        var eventLngLat = [feature.Longitude as number, feature.Latitude as number];
        feature.Selected = true
        const nearPlaces: any[] = [feature]

        for (var i = 0; i < mapData.length; i++) {
            var line = turf.lineString([eventLngLat, [mapData[i].Longitude as number, mapData[i].Latitude as number]]);
            var distanceResult = turf.length(line, { units: 'miles' });
            if (distanceResult > 0 && distanceResult <= radius) {
                mapData[i].Selected = false
                nearPlaces.push(mapData[i])
            }
        }


        data.points = [
            {
                fullData: {
                    type: "scattermapbox"
                },
                customdata: nearPlaces
            }
        ]

        setNearBy(nearPlaces)

        var circle = turf.circle(eventLngLat, radius, { steps: 80, units: 'miles' });
        setSelectedMarker(circle)
        if (onClick) onClick(data);
    };


    const onPositionChange = (e: any) => {
        if (e._moving && e._easeOptions) {
            setCenter(e._easeOptions.center)
        }
    };


    const mapStyle = {
        height: parseInt(panelData.PanelHeight?.toString().replace("px", "").replace("%", "")) - 30,
        width: '100%',
        top: 0,
        bottom: 0,
    };


    return (
        <div style={{ width: '100%', height: '100%' }}>
            {!isEmpty(markers) && markers.length > 0 ? <Map key={chartName}
                ref={mapboxElRef}
                style={styles['basic']}
                containerStyle={mapStyle}
                center={getCenter}
                onMoveEnd={onPositionChange}
            >
                <>
                    {
                        !isEmpty(markers) && markers.length > 0 ? markers.map((d, index) =>
                            <Layer
                                type="circle"
                                id={"marker" + index}
                                key={"LAYER" + index}
                                paint={{
                                    "circle-color": d.Color || "red",
                                    'circle-radius': parseInt(d.MarkerSize || 5),
                                    "circle-stroke-width": 5,
                                    "circle-stroke-color": d.BorderColor || "#fff",
                                    "circle-stroke-opacity": 1
                                }}
                            >
                                <Feature onClick={() => markerClick(this, { feature: markers[index] })} coordinates={[d.Longitude, d.Latitude]} />

                            </Layer>) : null

                    }

                    {!isEmpty(marker) ? (<Popup key={"popup" + marker.Longitude} coordinates={[marker.Longitude, marker.Latitude]}>
                        <div style={{
                            background: "white", color: "#3f618c", padding: "5px",
                            borderRadius: "2px"
                        }}>

                            <div dangerouslySetInnerHTML={{__html: marker.DataText || ""}} />
                        </div>
                    </Popup>) : null}
                    <div>
                        {

                            !isEmpty(selectedMarker) ? <GeoJSONLayer
                                key={
                                    "2"
                                }
                                data={selectedMarker}
                                fillPaint={{
                                    'fill-color': 'blue',
                                    'fill-opacity': .2
                                }}

                            /> : null
                        }
                    </div>
                </>

            </Map>
                : null}

            {
                (hideMapPanel === false) && !isEmpty(getNearBy) ? <div style={{ width: '250px', display: 'flex', backgroundColor: 'white', position: 'absolute', right: 5, top: 5, bottom: 5 }}>
                    {
                        <button style={{
                            position: 'absolute', background: "#bf0909",
                            border: "none",
                            color: "white"
                        }} onClick={() => {
                            setMarker(null)
                            setSelectedMarker(null)
                            setNearBy(null)
                        }}>X</button>
                    }
                    {
                        <ul style={{ marginTop: '35px' }}>
                            {getNearBy.map((m: any, index: number) => <> <li key={index}><label style={{ color: m.Selected ? 'green' : 'blue' }} > {m.Longitude} - {m.Latitude} </label></li></>)}
                        </ul>

                    }
                </div> : null
            }
        </div>

    );

}

export default MapChart;