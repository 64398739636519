/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { Tree } from 'primereact/tree';
import { ObjectID } from 'bson';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { consumeAPI } from '../../../../../../redux/actions/panelItem';
import { State } from '../../../../../../redux/reducers';

import { DialogField } from '../../../../../../models/classes/DialogField';
import { PanelData } from '../../../../../../models/classes/PanelData';
import { PanelFieldData } from '../../../../../../models/classes/PanelFieldData';

import { IConsumeAPI } from '../../../../../../models/interfaces/IConsumeAPI';
import { IFieldValue } from '../../../../../../models/interfaces/IFieldValue';

import FieldGenerator from '../../../../../../utils/field_generator';

import { isEmpty } from '../../../../../../utils/validation';

interface IPanelTree {
    widgetName: string;
    panelData: PanelData;
}

interface ITreeNodeEditor {
    panelFields: PanelFieldData[];
    nodes: any;
    setNodes: Function;
    selectedNode: any;
    setSelectedNode: Function;
    setHasSaved: Function;
}

const renameTreeIdentifier = (list: any, from: string, to: string) => {
    list = JSON.stringify(list);
    list = list.split(from).join(to);
    return JSON.parse(list);
};

const nullEmptyTreeChildren = (list: any) => {
    list = JSON.stringify(list);
    list = list.split('"children":[]').join('"children":null');
    return JSON.parse(list);
};

const getNodeFromTree = (tree: any, id: string) => {
    if (!isEmpty(tree)) {
        let selectedNode = null;
        for (let i=0; i<tree.length; i++) {
            const node = tree[i];
            if (!isEmpty(node.children)) {
                const item: any = getNodeFromTree(node.children, id);
                node.children = item.tree;
                selectedNode = item.selectedNode;
                if (selectedNode) {
                    return {tree, selectedNode};
                }
            }

            if (JSON.stringify(node.key) === JSON.stringify(id)) {
                selectedNode = node;
                return {tree, selectedNode};
            }
        }
        return {tree, selectedNode};
    }
    return {tree, selectedNode: null};
};

const removeFromTree = (tree: any, id: string) => {
    if (!isEmpty(tree)) {
        let newTree: any = [];
        tree.forEach((node: any) => {
            if (!isEmpty(node.children)) node.children = removeFromTree(node.children, id);
            if (node.key !== id) newTree.push(node);
        });
        return newTree;
    }
    return null;
};

const updateTreeData = (tree: any, newNode: any) => {
    if (!isEmpty(tree)) {
        let newTree: any = [];
        for (let i=0; i<tree.length; i++) {
            const node = tree[i];
            if (!isEmpty(node.children)) node.children = updateTreeData(node.children, newNode);
            if (JSON.stringify(node.key) !== JSON.stringify(newNode.key)) newTree.push(node);
            else {
                let newData = node;
                for (let [key, value] of Object.entries(newNode)) {
                    newData[key] = value;
                }
                newTree.push(newData);
            }
        }
        return newTree;
    }
    return null;
};

const addTreeOrderAttribute = (tree: any) => {
    if (!isEmpty(tree)) {
        let newTree: any = [];
        tree.forEach((node: any, nodeIndex: number) => {
            if (!isEmpty(node.children)) node.children = addTreeOrderAttribute(node.children);

            node["order"] = nodeIndex;
            newTree.push(node);
        });
        return newTree;
    }
    return null;
};

const TreeNodeEditor: React.FC<ITreeNodeEditor> = (props) => {
    const {
        panelFields,
        nodes,
        setNodes,
        selectedNode,
        setSelectedNode,
        setHasSaved
    } = props;
    const form = useForm();
    const {control, register, handleSubmit} = form;

    return (
        <div className="p-col">
            <div className="p-grid p-dir-col">
                <div className="p-col">
                    <Card>
                        <form
                            className="p-grid p-dir-col"
                            style={{width: "50rem"}}
                            onSubmit={handleSubmit((formData) => {                                
                                setNodes((nodes: any) => {
                                    setHasSaved(false);
                                    return updateTreeData(nodes, formData);
                                });
                            })}
                        >
                            {panelFields.map((field: PanelFieldData, fieldIndex: number) => {
                                let selectedNodeData: any = getNodeFromTree(nodes, selectedNode)!.selectedNode;
                                let value = "";

                                if (field.DefaultValue) value = field.DefaultValue;

                                if (selectedNodeData?.hasOwnProperty(field.FieldName))
                                    value = selectedNodeData[field.FieldName];

                                const dialogField: DialogField = new DialogField(
                                    field,
                                    false,
                                    value
                                );

                                let customProps = {
                                    defaultValue: field?.DefaultValue,
                                    container: {
                                        form
                                    },
                                    style: {
                                        width: field?.FieldWidth? field.FieldWidth: "initial"
                                    }
                                };

                                return (
                                    <div key={fieldIndex} className="p-col">
                                        <div
                                            key={fieldIndex}
                                            style={{
                                                whiteSpace: "nowrap",
                                                display: field.Hidden? "none": "flex",
                                                flexDirection: field?.InputType === "DisplayText" || field?.InputType === "FileUpload" ? "row" : process.env.REACT_APP_LAYOUT_INPUT === "grid" ? "column" : "row",
                                                alignItems: field?.InputType === "FileUpload" ? undefined : "flex-start",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <label htmlFor={field.FieldName}>{field.FieldLabel}</label>
                                            <Controller
                                                name={dialogField.FieldName}
                                                control={control}
                                                defaultValue={dialogField.value}
                                                render={({ field, fieldState }) => {  //render={(reference: ControllerRenderProps<Record<string, any>>, state: any) => {
                                                    return (<FieldGenerator field={dialogField} reference={field} customProps={customProps} fieldState={fieldState} />);
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="p-grid">
                                <div className="p-col">
                                    <Button
                                        className="p-button-success"
                                        icon="pi pi-save"
                                        label="Save Data"
                                        style={{width: "100%"}}
                                        type="submit"
                                    />
                                </div>
                                <div className="p-col">
                                    <Button
                                        className="p-button-danger"
                                        icon="pi pi-trash"
                                        label="Delete Data"
                                        style={{width: "100%"}}
                                        onClick={() => {
                                            setNodes((nodes: any) => {
                                                setSelectedNode(null);
                                                setHasSaved(false);
                                                return removeFromTree(nodes, selectedNode);
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="p-col">
                                <Button
                                    className="p-button-secondary"
                                    label="Close"
                                    style={{width: "100%"}}
                                    onClick={() => setSelectedNode(null)}
                                />
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        </div>
    );
};

const PanelTreeComponent: React.FC<IPanelTree> = (props) => {
    const { widgetName, panelData } = props;
    const {
        PanelName,
        Fields,
        TreeSaveAPI
    } = panelData;

    const name = `${widgetName}_${PanelName}`;
    const [nodes, setNodes] = useState<any>([]);
    const [selectedNode, setSelectedNode] = useState<any>(null);
    const [hasSaved, setHasSaved] = useState<boolean>(true);

    const panelItemSelector = useSelector((state: State) => state.panelItem);
    const dispatch = useDispatch();

    const addNewNode = () => {
        setHasSaved(false);
        const key = new ObjectID();
        const newData = {
            key: key.toString(),
            label: "New Data"
        };
        setNodes((nodes: any) => [...nodes, newData]);
    };

    const saveTreeData = () => {
        setSelectedNode(null);
        let parsedNodes: any = renameTreeIdentifier(nodes, "key", "_id");
        parsedNodes = nullEmptyTreeChildren(parsedNodes);
        parsedNodes = addTreeOrderAttribute(parsedNodes);

        const inputParameterValue: IFieldValue = {
            fieldName: "NewTree",
            fieldType: "TreeNodes",
            dataType: "TreeNodes",
            value: {nodes: parsedNodes}
        };

        const options: IConsumeAPI = {
            targetPanel: name,
            APIName: TreeSaveAPI,
            inputParameterValues: [inputParameterValue],
            UseQueue: false,
            isAPIForDataTable: false,
        };

        consumeAPI(
            dispatch,
            options
        );

        setHasSaved(true);
    };

    useEffect(() => {
        // Load table data
        if (panelData?.LoadData?.APIName) {
            let inputParameterValues: IFieldValue[] = [];

            panelData?.LoadData?.APIParameters?.forEach(
                (APIParam: PanelFieldData) => {
                    inputParameterValues.push({
                        fieldName: APIParam.FieldName,
                        dataType: APIParam.DataType,
                        value: APIParam.Value,
                    } as IFieldValue);
                }
            );

            const options: IConsumeAPI = {
                targetPanel: name,
                APIName: panelData.LoadData.APIName,
                inputParameterValues,
                UseQueue: false,
                isAPIForDataTable: true
            };

            setTimeout(() => {
                consumeAPI(dispatch, options);
            }, 500);

            if (panelData.LoadData?.RefreshInterval) {
                // RefreshInterval is in second, so we need to times it with 1000
                setInterval(() => {
                    consumeAPI(dispatch, options);
                }, panelData.LoadData?.RefreshInterval * 1000);
            }
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isEmpty(panelItemSelector)) {
            if (
                panelItemSelector.isLoadingGetTableData &&
                panelItemSelector.loadingData.targetPanel.includes(name)
            ) {
                setNodes([]);
            }
            if (
                panelItemSelector.isSuccessGetTableData &&
                panelItemSelector.successData.targetPanel.includes(name)
            ) {
                const data: any = renameTreeIdentifier(panelItemSelector.successData.data, "_id", "key");
                setNodes(data);
            }
        }
        // eslint-disable-next-line
    }, [panelItemSelector, PanelName]);

    return !isEmpty(nodes)?
        <div>
            <div className="p-grid">
                <div className="p-col">
                    <Tree
                        value={nodes}
                        dragdropScope="demo"
                        selectionMode="single"
                        selectionKeys={selectedNode}
                        onSelectionChange={(e) => {
                            setSelectedNode(null);
                            //setSelectedNode(e.value)
                            setTimeout(()=>setSelectedNode(e.value), 0.1);
                        }}
                        onDragDrop={event => {
                            setHasSaved(false);
                            setNodes(event.value);
                        }}
                    />
                </div>
                {
                    selectedNode?
                        <TreeNodeEditor
                            panelFields={Fields}
                            nodes={nodes}
                            selectedNode={selectedNode}
                            setHasSaved={setHasSaved}
                            setNodes={setNodes}
                            setSelectedNode={setSelectedNode}
                        />: null
                }
            </div>
            <div className="p-grid">
                <div className="p-col">
                    <Button
                        className="p-button-info"
                        icon="pi pi-plus"
                        label="New data"
                        style={{width: "21.5rem"}}
                        onClick={addNewNode}
                    />
                </div>
                {
                    !hasSaved ?
                        <div className="p-col">
                            <Button
                                className="p-button-success"
                                icon="pi pi-save"
                                label="Save"
                                style={{width: "21.5rem"}}
                                onClick={saveTreeData}
                            />
                        </div>: null
                }
            </div>
        </div>: null;
};

export default PanelTreeComponent;