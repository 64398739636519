/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

import { IHelpButton } from './PanelData';
import { WidgetData } from './WidgetData';

export class WorkspaceData {
    Title: string = "";
    WorkspaceName: string = "";
    NumberOfColumns: number = 0;
    NumberOfRows: number = 0;
    HelpCenter?: Partial<IHelpButton[]> = [];
    Widgets: WidgetData[] = [new WidgetData()];
}
