import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';

interface FileUploadProps {
    field: any;
    register?: any;
    onFileChange?: (file: File | null) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ field, register, onFileChange }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [label, setLabel] = useState('Choose File');

    const chooseFile = () => {
        if (register?.ref?.current) {
            register.ref.current.value = '';
            register.ref.current.click();
        } else if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.click();
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files ? e.target.files[0] : null;
        console.log("file", file)
        if (file) {
            setLabel(file.name);
            setSelectedFile(file);
            if (onFileChange) {
                onFileChange(file);
            }
        } else {
            setLabel('Choose File');
            setSelectedFile(null);
            if (onFileChange) {
                onFileChange(null);
            }
        }
    };

    const clearFile = () => {
        setLabel('Choose File');
        setSelectedFile(null);
        if (onFileChange) {
            onFileChange(null);
        }
    };

    return (
        <div>
            <span className="p-buttonset">
                <Button onClick={chooseFile} label={label} />
                {selectedFile && (
                    <Button label="" onClick={clearFile} icon="pi pi-times" />
                )}
            </span>
            <input
                ref={inputRef ?? register?.ref}
                id={field.FieldName.replace(",", "")}
                type="file"
                hidden
                onChange={onChange}
            />
        </div>
    );
};

export default FileUpload;
