/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useState, useRef, useCallback } from "react";
import { Panel } from "primereact/panel";
import ReactResizeDetector from "react-resize-detector";

import "./styles/index.css";

import { TabView, TabPanel } from "../../components/Tab";

import PanelContainer from "../PanelContainer";

import { PanelData } from "../../models/classes/PanelData";
import { WidgetData } from "../../models/classes/WidgetData";

import api from "../../common/api";
import {
    getDropdownValues,
    getDefaultValues,
} from "../PanelContainer/components/PanelDataComponent/common/action";
import { isNull, isEmpty } from "../../utils/validation";
import { isUrlParamExist, getUrlParam } from "../../utils/url";
import { getTodayDate } from "../../utils/date";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import HtmlViewer from "../../components/HtmlViewer";
import { useSelector } from "react-redux";
import { State } from "../../redux/reducers";
import { createGlobalStyle } from "styled-components";
import __t from "../../utils/translation";
import { useGlobalState } from "../../App";
import { PanelFieldData } from "../../models/classes/PanelFieldData";
import EventEmitter from "../../common/EventEmitter";
interface Props {
    widgetData: WidgetData;
    Width: number;
}

const WidgetContainer: React.FC<Props> = (props) => {
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [widgetData, setWidgetData] = useState<WidgetData | null>(null);
    const [widgetDataTemp, setWidgetDataTemp] = useState<WidgetData | null>(null);
    const [panelLayout, setPanelLayout] = useState<JSX.Element | JSX.Element[] | undefined>();
    const widgetRef = useRef(null);
    const widgetDivRef = useRef<HTMLDivElement>(null);
    const columnRef = useRef<HTMLDivElement>(null);

    const [displayBasic, setDisplayBasic] = useState(false);
    const [position, setPosition] = useState('center');
    const [contentWidth, setContentWidth] = useState("100%");
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const sidebarItemSelector = useSelector((state: State) => state.sidebarItem);
    const beaconSelector = useSelector((state: State) => state.beaconItem);
    const [appData, ] = useGlobalState('appData');


    const parseLOVParameters = (APIParameters: PanelFieldData[] | undefined) => {
        if (!isEmpty(props.widgetData.SubscribeComponent)) {
            if (isEmpty(APIParameters)) {
               APIParameters = []
            }

            props.widgetData.SubscribeComponent?.forEach((sub) => {

                const keySource = `${sub?.Source.WidgetName!}_${sub?.Source.PanelName!}`;

                const lov =(appData[`${keySource}___LOV`]??[]).find((item: any) => {
                    return item && item["LOV_" + sub!.Source.FieldName]
                }) as any ?? []
                
                if (!isEmpty(beaconSelector) && !isEmpty(sub?.Source) && !isEmpty(sub?.Source.APIParameters) && isEmpty(lov)) {

                    if (!isEmpty(beaconSelector[keySource])) {
                        const data = beaconSelector[keySource].data

                        if (Array.isArray(data)) {
                            sub?.Source.APIParameters.forEach(param => {
                                const fetchedData = data.find((emittedData: any) => (
                                    //emittedData.DataName === param.ValueSource &&
                                    emittedData.DataSource === param.FieldName
                                ));

                                if (fetchedData) {
                                    param.Value = fetchedData.EmittedValue;
                                    if (param.DefaultValue) {
                                        param.Value = param.DefaultValue;
                                    }
                                    APIParameters!.push(param);
                                }

                            });
                        }
                    }
                }

                if (!isEmpty(lov)) {
                    const lovParentParameters = lov!["LOV_" + sub!.Source.FieldName] as any[] ?? []

                    if (!isEmpty(lovParentParameters)) {
                        for (const item of lovParentParameters) {
                            if (sub?.Source.APIParameters) {
                                sub?.Source.APIParameters.forEach(param => {
                                    if (param.FieldName === item.DataSource) {
                                        param.Value = item.EmittedValue;
                                        APIParameters!.push(param!)
                                    }
                                });
                            }
                        }
                    }
                }
            })
            return APIParameters;
        }
    }
    const loadWidgetData = (firstLoad:boolean = true) => {
        if (props.widgetData.WidgetName !== "") {
            setLoadingMessage("Loading widget");

            return api({
                path: `dashboard/widget/${props.widgetData.WidgetName}/${props.widgetData.WorkspaceName}`,
            })
                .then(async (response: any) => {
                    if (isNull(widgetRef.current)) return null;
                    if (response?.data) {
                        let widgetDataJson: WidgetData = response.data; 
                        renderWidgets(widgetDataJson, firstLoad);
                    }
                    return true;
                })
                .catch((err: any) => {
                    console.info("widget load error :", err);
                    setLoadingMessage(null);
                    return null;
                });
        }
        return null;
    };

    const renderWidgets = async (widgetDataJson: WidgetData | null, firstLoad: boolean = true) => {
        if (widgetDataJson && widgetDataJson.Panels) {

            setLoadingMessage("Fetching widget data");
            widgetDataJson.WorkspaceName = props.widgetData.WorkspaceName;
            widgetDataJson.Panels = widgetDataJson.Panels.filter((panel) => !isEmpty(panel));
            // Preload data
            for (
                let index = 0;
                index < widgetDataJson.Panels.length;
                index++
            ) {
                const panel: PanelData =
                    widgetDataJson.Panels[index];

                // #region Read override panel data
                let overrideWidgetConfig: any = null;
                if (isUrlParamExist("OverrideWidgetConfig")) {
                    overrideWidgetConfig = getUrlParam("OverrideWidgetConfig");
                    overrideWidgetConfig = decodeURIComponent(overrideWidgetConfig);
                    overrideWidgetConfig = JSON.parse(overrideWidgetConfig);
                } else {
                    overrideWidgetConfig = sessionStorage.getItem("OverrideWidgetConfig");
                    if (!isEmpty(overrideWidgetConfig)) {
                        overrideWidgetConfig = JSON.parse(
                            overrideWidgetConfig
                        );
                    }
                }

                if (overrideWidgetConfig) {
                    let overrideWidgetConfigCopy = JSON.parse(JSON.stringify(overrideWidgetConfig));
                    delete overrideWidgetConfigCopy.Panels;
                    Object.assign(widgetDataJson, overrideWidgetConfigCopy);
                }

                if (overrideWidgetConfig?.Panels) {
                    for (const overridePanel of overrideWidgetConfig?.Panels) {
                        if (panel?.PanelName === overridePanel?.PanelName) {
                            let overridePanelCopy = JSON.parse(JSON.stringify(overridePanel));
                            delete overridePanelCopy.Fields;
                            delete overridePanelCopy.Buttons;
                            Object.assign(panel, overridePanelCopy);

                            // Fields
                            if (overridePanel.Fields) {
                                if (isEmpty(panel.Fields)) panel.Fields = [];

                                for (const overridePanelField of overridePanel?.Fields) {
                                    const field = panel.Fields.find(
                                        (panelField) =>
                                            panelField.FieldName ===
                                            overridePanelField.FieldName
                                    );

                                    if (field) Object.assign(field, overridePanelField);
                                    else panel.Fields.push(overridePanelField);
                                }
                            }

                            // Buttons
                            if (overridePanel.Buttons) {
                                if (isEmpty(panel.Buttons))
                                    panel.Buttons = [];
                                for (const overridePanelButton of overridePanel?.Buttons) {
                                    const button = panel.Buttons.find(
                                        (panelButton) =>
                                            panelButton.ButtonName ===
                                            overridePanelButton.ButtonName
                                    );

                                    if (button) Object.assign(button, overridePanelButton);
                                    else panel.Buttons.push(overridePanelButton);
                                }
                            }
                        }
                    }
                }
                // #endregion
            }

            for (let index = 0; index < widgetDataJson.Panels.length; index++) {
                const panel: PanelData = widgetDataJson.Panels[index];

                if (panel?.LayoutFormat === "Form") {
                    if (panel.Fields) {
                        for (let index = 0; index < panel.Fields.length; index++) {
                            const field = panel.Fields[index];

                            // dropdown prefetch data
                            if (
                                (field.InputType === "Dropdown" || field.InputType === "MultiSelect") &&
                                field.ListOfValuesAPI
                            ) {
                                const response: any = await getDropdownValues(field.ListOfValuesAPI, false);
                                if (isNull(widgetRef.current)) return null;

                                if (response?.success && response?.data) {
                                    if (Array.isArray(response.data) && response.data.length > 0) {
                                        field["ListOfValues"] = response.data;
                                    } else if (response.data.ListOfValues) {
                                        field["ListOfValues"] = response.data.ListOfValues;
                                    }
                                }
                            }

                            if (field.DataType === "Date" && !isEmpty(field.DefaultValue)) {
                                field.DefaultValue = getTodayDate(field.DefaultValue);
                            }

                            if (field.DataType === "Datetime" && !isEmpty(field.DefaultValue)) {
                                field.DefaultValue = getTodayDate(field.DefaultValue);
                            }

                            if (field.DefaultValueAPI) {
                                const response: any = await getDefaultValues(
                                    field.DefaultValueAPI,
                                    field.DefaultValueAPIParams
                                );
                                if (isNull(widgetRef.current)) return null;
                                if (response?.success) {
                                    if (!isNull(response?.data)) {
                                        field["DefaultValue"] = response.data;
                                    }
                                }
                            }
                        }
                    }

                    if (panel.Buttons) {
                        for (const button of panel.Buttons) {
                            const targetPanelName: string | string[] = button.TargetPanel;

                            // Checks if the target panel is a lazy loading table.
                            const targetPanel: PanelData | undefined = widgetDataJson.Panels.find((panel) => panel.PanelName === targetPanelName);

                            button.isTargetTableUseLazyLoad = !!targetPanel
                                ?.TableOption?.UseLazyLoad;
                        }
                    }
                } else if (panel?.LayoutFormat === "Table") {
                    if (isEmpty(panel.Actions)) panel.Actions = [];
                    if (isEmpty(panel.LoadData)) {
                        panel.LoadData = {} as any;
                    }
                    if (isEmpty(panel.LoadData.APIParameters)) {
                        panel.LoadData.APIParameters = []
                    }
                    panel.LoadData.APIParameters = parseLOVParameters(panel.LoadData.APIParameters)
                }
            }

            if (isNull(widgetRef.current)) return null;
            else {
                console.log("Widget data:");
                console.table(widgetDataJson);
                setWidgetData(widgetDataJson);
                if (firstLoad) {
                    setWidgetDataTemp(widgetDataJson);    
                }
                
                setLoadingMessage(null);
            }
        }
    }

    useEffect(() => {
        loadWidgetData(true);
        return () => {
            widgetRef.current = null;
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isEmpty(sidebarItemSelector)) {
            if (!isEmpty(sidebarItemSelector.isSidebarVisible)) {
                sidebarItemSelector.isSidebarVisible ? setIsSidebarVisible(true) : setIsSidebarVisible(false);
            }
        }        
    }, [sidebarItemSelector]);

    const handleMessage = useCallback(async (action: any) => {
        if (Array.isArray(action)) {
            setPanelLayout(undefined);
            widgetRef.current = null;
            // Filter the action array to include only objects with a matching TargetWidget
            const matchingActions = action.filter(a => a.TargetWidget === widgetDataTemp?.WidgetName);
            if (matchingActions && matchingActions.length > 0) {
                // Extract TargetPanel values from the filtered action array
                const panelDatas = matchingActions.map(a => a.TargetPanel);
                const panels = widgetDataTemp?.Panels.filter(p => panelDatas.includes(p.PanelName));

                setWidgetData((prevData: any) => {
                    const updatedWidgetData = {
                      ...prevData,
                      Panels: panels ?? [],
                    };
                    
                    renderWidgets(updatedWidgetData, false)
                    
                    return updatedWidgetData;
                  });
            }
        } else {
            if (widgetData?.WidgetName === action.TargetWidget) {
                setPanelLayout(undefined);
                const panels = widgetDataTemp?.Panels.filter(p => p.PanelName === action.TargetPanel) ?? [];
                setWidgetData({
                    ...widgetData,
                    Panels: panels
                } as any);
                setPanelLayout(renderPanel(props.Width));
            }
        }
    }, [widgetData, widgetDataTemp]);

    useEffect(() => {
        EventEmitter.on('reload_widget', handleMessage);
        return () => {
            EventEmitter.off('reload_widget', handleMessage);
        };
    }, [handleMessage]);


    useEffect(() => {
        if (widgetData) {
            setPanelLayout(renderPanel(props.Width));
        }
        return () => {
            setPanelLayout(undefined);
        };
    }, [widgetData, props])

    const renderPanel = (width: number) => {

        const columns = widgetData!.NumberOfColumns || 1
        const rows = widgetData!.NumberOfRows || 1
        let gridTemplateColumnsValue = ""
        const panelWidth = widgetDivRef?.current?.offsetWidth || width
        if (widgetDivRef.current && !isEmpty(columns)) {
            gridTemplateColumnsValue = widgetDivRef.current?.offsetWidth?
                `repeat(${columns}, calc(${((panelWidth - (columns > 4 ? 30 : 30)) / columns)}px ))`:
                `repeat(${columns}, auto)`            
        } 

        const renderNormalPanelLayout = (panels: PanelData[], widgetData: WidgetData) => {
            return panels.map((panel, index) => {

                if (!isEmpty(panel.DisplayPosition)) {
                    
                    let col: number,
                        row: number,
                        colSpan: number = 1,
                        rowSpan: number = 1;
    
                    if (typeof panel.DisplayPosition === "string") {
                        const displayPosition = panel.DisplayPosition.split(",")
                        col = parseInt(displayPosition[0]) + 1
                        row = parseInt(displayPosition[1]) + 1
                    } else {
                        col = panel.DisplayPosition.Column + 1
                        row = panel.DisplayPosition.Row + 1
                        colSpan = panel.DisplayPosition.ColumnSpan? panel.DisplayPosition.ColumnSpan: 1
                        rowSpan = panel.DisplayPosition.RowSpan? panel.DisplayPosition.RowSpan: 1
                    }
    
                    return (
                        <div
                            ref={columnRef}
                            key={index}
                            style={{
                                gridColumn: `${col} / span ${colSpan}`,
                                gridRow: `${row} / span ${rowSpan}`,
                                background: panel.Background,
                                border: panel.Border,
                                flexDirection: "row",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                                //placeSelf: "center"
                            }}
                        >
                             {isEmpty(panel) ? null :
                                <PanelContainer
                                    widgetData={widgetData}
                                    panelData={panel}
                                    widgetWidth={columnRef.current?.offsetWidth || panelWidth}
                                />
                        }
                        </div>
                    )
                } else {
                    return (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                background: panel.Background,
                                border: panel.Border
                                // justifyContent: "center"
                            }}
                        >
                            {isEmpty(panel) ? null :
                                <PanelContainer
                                    widgetData={widgetData}
                                    panelData={panel}
                                    widgetWidth={panelWidth}
                                />
                            }
                        </div>
                    )
                }
            });
        };

        if (isEmpty(widgetData?.PanelType)) widgetData!.PanelType = "Normal";

        if (widgetData?.PanelType === "Normal") {
            if (rows > 1) {
                return (
                    <div
                    className="grid"
                        style={{
                            display: "grid",
                            width: '100%',
                            gridGap: widgetData?.GridGap ?? '10px',
                            //gridTemplateColumns: gridTemplateColumnsValue,
                            gridTemplateRows: `repeat(${rows}, auto)`
                        }}
                    >
                        {renderNormalPanelLayout(widgetData.Panels, widgetData)}
                    </div>
                )     
            } else {
                return renderNormalPanelLayout(widgetData.Panels, widgetData)
            }
        }

        if (widgetData?.PanelType === "Tabbed") {
            let allPanels = widgetData.Panels;
            let tabbedPanels = widgetData.Panels;

            if (!isEmpty(widgetData?.PanelListInTabFormat)) {
                allPanels = allPanels.filter(panel => !isEmpty(panel) && !widgetData.PanelListInTabFormat?.includes(panel.PanelName));
                tabbedPanels = tabbedPanels.filter(panel => !isEmpty(panel) && widgetData.PanelListInTabFormat?.includes(panel.PanelName));
            }

            return (
                <>
                    {
                        !isEmpty(widgetData?.PanelListInTabFormat) && renderNormalPanelLayout(allPanels, widgetData)
                    }
                    <TabView>
                        {tabbedPanels.map((panel, index) => (
                            <TabPanel
                                key={index}
                                header={
                                    panel?.PanelLabel !== ""
                                        ? __t(panel, "PanelLabel")
                                        : panel?.PanelName
                                }
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        // justifyContent: "center"
                                    }}
                                >
                                    <PanelContainer
                                        widgetData={widgetData}
                                        panelData={panel}
                                        widgetWidth={width}
                                    />
                                </div>
                            </TabPanel>
                        ))}
                    </TabView>
                </>
            );
        }
    };

    const onClick = () => {
        setDisplayBasic(true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = () => {
        setDisplayBasic(false);
    }

    if (loadingMessage) {
        return <span ref={widgetRef}>{loadingMessage}...</span>;
    } else {
        if (widgetData) {
            const tooltip = !isEmpty(widgetData.Tooltip) ? <Tooltip target={`#${widgetData.WidgetName}`} position={widgetData.TooltipPosition ?? 'right'} content={widgetData.Tooltip} /> : null;
            const GlobalStyle = createGlobalStyle`.panel-${widgetData.WidgetName} {
                max-width: ${contentWidth} !important;
            }`
            return (
                <ReactResizeDetector handleWidth>
                    {({ width }: any) => {
                        setContentWidth(isSidebarVisible ? props.Width + "px" : `calc(${props.Width}px)`);
                        return (
                            <>
                                {tooltip}
                                {(widgetData.HelpCenter && widgetData.HelpCenter?.length > 0) ?
                                    <Dialog header={"Help Center"} className="p-dialog-content-help" visible={displayBasic} style={{ width: '50vw' }} onHide={() => onHide()}>
                                        <TabView>
                                            {widgetData.HelpCenter?.filter(x => x?.HelpEnable).map((help, index) => (
                                                <TabPanel
                                                    key={index}
                                                    header={
                                                        help?.HelpTitle !== ""
                                                            ? (help?.HelpTitle || '')
                                                            : `Help Content ${index + 1}`
                                                    }
                                                >
                                                    <HtmlViewer Code={help?.HelpCode || ''} />
                                                </TabPanel>
                                            ))}
                                        </TabView>
                                    </Dialog> : null}
                                <GlobalStyle/>

                                <div
                                    ref={widgetDivRef}
                                    id="widget"
                                    style={{
                                        width: "100%",
                                        justifyContent: "left",
                                        display: "flex",
                                    }}
                                >
                                    <Panel
                                        className={"panel-" + widgetData.WidgetName}
                                        ref={widgetRef}
                                        toggleable={widgetData.Toggleable}
                                        style={{
                                            ...((!isEmpty(widgetData.WidgetWidth)) ? {
                                                width: widgetData.WidgetWidth
                                            } : {
                                                ...{
                                                    width: "100%"
                                                }
                                            })
                                        }}
                                        header={
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }} id={widgetData.WidgetName}>
                                                <div>{__t(widgetData, "Title")}</div>
                                                {(widgetData.HelpCenter && widgetData.HelpCenter?.filter(x => x?.HelpEnable).length > 0) ? <div onClick={() => onClick()} style={{ position: "absolute", right: '60px', cursor: 'pointer' }}>Help</div> : null}
                                            </div>}
                                    >

                                        { panelLayout }
                                    </Panel>   
                                </div>
                                </>
                        )
                    }}
                </ReactResizeDetector>
            );
        } else {
            return <span>No widget data</span>;
        }
    }
};

export default WidgetContainer;
