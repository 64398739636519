/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

import {
    ACTION_CALL_API,
    ACTION_CALL_API_ERROR,
    ACTION_CALL_API_SUCCESS,
    ACTION_SUBMIT_DATA, ACTION_SUBMIT_DATA_ERROR, ACTION_SUBMIT_DATA_SUCCESS,
    API_GET_TABLE_DATA,
    API_GET_TABLE_DATA_ERROR,
    API_GET_TABLE_DATA_SUCCESS,
    API_RESET_TABLE_DATA,
    CANCEL_DATA_FETCH,
    EXPORT_TABLE_DATA,
    EXPORT_TABLE_DATA_ERROR,
    EXPORT_TABLE_DATA_SUCCESS,
    ON_BATCH_ACTION_BUTTON_CLICKED,
    RESET_FORM,
} from '../constants/panelItem';

import {
    errorExportTableData,
    errorGetTableData, errorSubmitActionData,
    startExportTableData,
    startGetTableData, startSubmitActionData,
    successExportTableData,
    successGetTableData, successSubmitActionData, resetTableData,
    startCallApi,
    successCallApi,
    errorCallApi,
    requestCanceled
} from '../utils/panelItem';

import {IAction} from '../../models/interfaces/IAction';

let initialState: any = {};

const reducer = (state = initialState, action: IAction) => {
    let loadingData, successData, errorData;
    switch (action.type) {
        case API_GET_TABLE_DATA:
            loadingData = action.payload;
            return Object.assign({}, resetTableData(), startGetTableData(), { loadingData });
        case API_GET_TABLE_DATA_SUCCESS:
            successData = action.payload;
            return Object.assign({}, successGetTableData(), { successData });
        case API_GET_TABLE_DATA_ERROR:
            errorData = action.payload;
            return Object.assign({}, errorGetTableData(), { errorData });

        case EXPORT_TABLE_DATA:
            loadingData = action.payload;
            return Object.assign({}, startExportTableData(), { loadingData });
        case EXPORT_TABLE_DATA_SUCCESS:
            successData = action.payload;
            return Object.assign({}, successExportTableData(), { successData });
        case EXPORT_TABLE_DATA_ERROR:
            errorData = action.payload;
            return Object.assign({}, errorExportTableData(), { errorData });

        case ACTION_SUBMIT_DATA:
            loadingData = action.payload;
            return Object.assign({}, startSubmitActionData(), { loadingData });
        case ACTION_SUBMIT_DATA_SUCCESS:
            successData = action.payload;
            return Object.assign({}, successSubmitActionData(), { successData });
        case ACTION_SUBMIT_DATA_ERROR:
            errorData = action.payload;
            return Object.assign({}, errorSubmitActionData(), { errorData });
        case CANCEL_DATA_FETCH:
            errorData = action.payload;
            return Object.assign({}, requestCanceled(), { errorData });

        case ACTION_CALL_API:
            loadingData = action.payload;
            return Object.assign({}, startCallApi(), { loadingData });
        case ACTION_CALL_API_SUCCESS:
            successData = action.payload;
            return Object.assign({}, successCallApi(), { successData });
        case ACTION_CALL_API_ERROR:
            errorData = action.payload;
            return Object.assign({}, errorCallApi(), { errorData });

        case API_RESET_TABLE_DATA:
            const resetData = action.payload;
            return Object.assign({}, resetTableData(), { resetData });

        case RESET_FORM:
            return action.payload;

        case ON_BATCH_ACTION_BUTTON_CLICKED:
            return Object.assign({ isBatchActionClicked: true }, action.payload);

        default:
            return state;
    }
};

export default reducer;