import { IFieldValue } from "../../models/interfaces/IFieldValue";
import { isEmpty } from '../../utils/validation';

export const parseInputParameterValues = (
  searchFieldValues: IFieldValue[] | null | undefined
) => {
  let parsedSearchFieldValue: IFieldValue[] = [];
  const hasFile = searchFieldValues?.find((field) => field.dataType?.toLowerCase() === 'file')
  
  if (hasFile) {
    searchFieldValues?.forEach(
      (searchFieldValue: IFieldValue) => {
        if (searchFieldValue.value) {
          if (searchFieldValue.fieldType === "Dropdown") {
  
            if (typeof searchFieldValue.value  === 'object' && searchFieldValue.value.hasOwnProperty('LabelValue')) {
              searchFieldValue.value = searchFieldValue.value.LabelValue;
            }
  
          }
  
          if (searchFieldValue.fieldType === "Datetime" && !isEmpty(searchFieldValue.value)) {
            if (!isEmpty(searchFieldValue.value)) {
              const date = new Date(searchFieldValue.value);
  
              const day: string = date.getDate() > 9? date.getDate().toString(): `0${date.getDate()}`;
              const month: string = date.getMonth() + 1 > 9? (date.getMonth() + 1).toString(): `0${date.getMonth() + 1}`;
              const year: string = date.getFullYear().toString();
              const hour: string = date.getHours() > 9? date.getHours().toString(): `0${date.getHours()}`;
              const minute: string = date.getMinutes() > 9? date.getMinutes().toString(): `0${date.getMinutes()}`;
              const second: string = "00";
  
              const formattedString: string = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  
              searchFieldValue.value = formattedString;
            }
          }
  
          if (
            searchFieldValue.fieldType === "MultiSelect" &&
            searchFieldValue.value?.length > 0
          ) {
            let values: any = [];
            searchFieldValue.value.forEach((value: any) => {
              values.push(value);
            });
  
            searchFieldValue.value = values.join(",");
          }
        }
        parsedSearchFieldValue.push(searchFieldValue);
      }
    );
  } else {
    JSON.parse(JSON.stringify(searchFieldValues))?.forEach(
      (searchFieldValue: IFieldValue) => {
        if (searchFieldValue.value) {
          if (searchFieldValue.fieldType === "Dropdown") {
  
            if (typeof searchFieldValue.value  === 'object' && searchFieldValue.value.hasOwnProperty('LabelValue')) {
              searchFieldValue.value = searchFieldValue.value.LabelValue;
            }
  
          }
  
          if (searchFieldValue.fieldType === "Datetime" && !isEmpty(searchFieldValue.value)) {
            if (!isEmpty(searchFieldValue.value)) {
              const date = new Date(searchFieldValue.value);
  
              const day: string = date.getDate() > 9? date.getDate().toString(): `0${date.getDate()}`;
              const month: string = date.getMonth() + 1 > 9? (date.getMonth() + 1).toString(): `0${date.getMonth() + 1}`;
              const year: string = date.getFullYear().toString();
              const hour: string = date.getHours() > 9? date.getHours().toString(): `0${date.getHours()}`;
              const minute: string = date.getMinutes() > 9? date.getMinutes().toString(): `0${date.getMinutes()}`;
              const second: string = "00";
  
              const formattedString: string = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  
              searchFieldValue.value = formattedString;
            }
          }
  
          if (
            searchFieldValue.fieldType === "MultiSelect" &&
            searchFieldValue.value?.length > 0
          ) {
            let values: any = [];
            searchFieldValue.value.forEach((value: any) => {
              values.push(value);
            });
  
            searchFieldValue.value = values.join(",");
          }
        }
        parsedSearchFieldValue.push(searchFieldValue);
      }
    );
  }
  
  return parsedSearchFieldValue;
};
