/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

export const TOGGLE_SIDEBAR_MENU = "TOGGLE_SIDEBAR_MENU";

export const API_GET_SIDEBAR_MENU = "API_GET_SIDEBAR_MENU";
export const API_GET_SIDEBAR_MENU_SUCCESS = "API_GET_SIDEBAR_MENU_SUCCESS";
export const API_GET_SIDEBAR_MENU_ERROR = "API_GET_SIDEBAR_MENU_ERROR";