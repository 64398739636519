import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment'
import * as d3 from 'd3';
import "./styles/index.css";
import ReactResizeDetector from 'react-resize-detector';

interface Props {
  treeData: any;
  panelName: string;
  panelWidth: number;
  valueType: "Number" | "DateTime" | undefined,
  onClick?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
}

const TreeChart: React.FC<Props> = (props) => {
  let ref: SVGGElement;
  const containerRef = useRef<HTMLDivElement>(null);
  const { panelName, panelWidth, treeData, valueType, onClick } = props;


  const drawTree = (data: any) => {

    let _valueType = valueType || "DateTime"
    var margin = {
      top: 20,
      right: 60,
      bottom: 80,
      left: 10
    }, 
    width = panelWidth - margin.right - margin.left,
    height = 500 - margin.top - margin.bottom;
  
    var ymax = Number.MIN_VALUE;
    var ymin = Number.MAX_VALUE;
    var xmax = Number.MIN_VALUE;
    var xmin = Number.MAX_VALUE;

    let cluster = d3.layout.cluster()
      .size([height, width - 100]);

    d3.select(`#${panelName} > svg`).remove()
    d3.selectAll(`#${panelName} > .tooltip`).remove();

    let svg = d3.select(ref).append("svg")
      .attr("width", width)
      .attr("height", height)
      .call(responsivefy)
      .append("g")
      .attr("transform", "translate(50,-50)");

    let xs: any = [];
    let ys: any = [];

    const getXYfromJSONTree = (node: any) => {
      if (node) {
        if (_valueType === "DateTime") {
          node!.DataValue = new Date(node.DataValue).getTime()
        }
        xs.push(node!.DataValue);
        ys.push(node!.DataPosition);
        if (typeof node!.children != 'undefined') {
          for (let j in node!.children) {
            getXYfromJSONTree(node!.children[j]);
          }
        }
      }
    }

    getXYfromJSONTree(data);

    var nodes = cluster.nodes(data);

    var links = cluster.links(nodes);
    nodes.forEach(function (d, i) {
      if (typeof xs[i] != 'undefined') {
        d.x = xs[i];
      }
      if (typeof ys[i] != 'undefined') {
        d.y = ys[i];
      }
    });


    ymin = d3.min(nodes, (d: any): any => _valueType === "DateTime" ? moment(d.DataValue).add(-120, 'minute').toDate().getTime() : (d.DataValue - d.DataValue) < 0 ? d.DataValue : (d.DataValue - d.DataValue));
    ymax = d3.max(nodes, (d: any): any => _valueType === "DateTime" ? moment(d.DataValue).add(60, 'minute').toDate().getTime() : d.DataValue);
    xmin = d3.min(nodes, (d: any) => (d.DataPosition - d.DataPosition) < 0 ? d.DataPosition : (d.DataPosition - d.DataPosition))
    xmax = d3.max(nodes, (d: any): any => d.DataPosition);

    let x = d3.scale.linear().domain([ymin, ymax]).range([0, width - 250]);

    let xinv = d3.scale.linear().domain([ymin, ymax]).range([0, width - 250]);

    let y = d3.scale.linear().domain([xmin, xmax]).range([height - 20, 100]);

    let diagonal: any = d3.svg.diagonal()
      .projection(function (d: any): any {
        return [x(d.x), y(d.y)];
      });

    var link = svg.selectAll(".link")
      .data(links)
      .enter().append("path")
      .attr("class", "link")
      .attr("d", diagonal);

    var node = svg.selectAll(".node")
      .data(nodes)
      .enter().append("g")
      .attr("class", "node")
      .attr("transform", function (d: any) {
        return "translate(" + x(d.DataValue) + "," + y(d.DataPosition) + ")";
      });


    // create a tooltip
    // it's invisible and its position/contents are defined during mouseover
    var tooltip = d3.select(ref).append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    // tooltip mouseover event handler
    var tipMouseover = function (d: any) {
      const value = _valueType === "DateTime" ? moment(d.DataValue).format("hh:mm a") : d.DataValue

      const html = "Position : <span style='color:red;'>" + d.DataPosition + "</span><br/>" +
        "Value : <b>" + value + "</b><br>" + d.DataLabel || '';

      tooltip.html(html)
        .style("left", () => { return (x(d.x) + 15) + "px" })
        .style("top", () => { return (y(d.y) - 30) + "px" })
        .transition()
        .duration(200) // ms
        .style("opacity", .9) // started as 0!

    };

    // tooltip mouseout event handler
    var tipMouseout = function (d: any) {
      tooltip.transition()
        .duration(300) // ms
        .style("opacity", 0); // don't care about position!
    };


    node.append("circle")
      .style("fill", (d: any) => { return d.DataColor || "steelblue" })
      .attr("r", 6.5)
      .on("mouseover", tipMouseover)
      .on("mouseout", tipMouseout)
      .on("click", function (params:any) {
        if (onClick) {
          var data = {
            points: [
              {
                fullData: {
                  type: "tree",
                },
                customdata: [params]
              }
            ]
          } as any
          onClick(data)
        }
      });


    node.append("text")
      .attr("dx", function (d) { return d.children ? -8 : 8; })
      .attr("dy", 3)
      .style("text-anchor", function (d: any) { return d.children ? "end" : "start"; })
      .text(function (d: any) { return d.DataShortLabel || d.DataLabel; });

    // x-Axis
    var g = d3.select(`#${panelName} > svg`).append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(50,350)");

    g.append("line")
      .attr("x1", x(ymin))
      .attr("y1", -20)
      .attr("x2", x(ymax))
      .attr("y2", -20);

    g.selectAll(".xticks")
      .data(x.ticks(24))
      .enter().append("line")
      .attr("class", "ticks axis")
      .attr("x1", function (d) { return xinv(d); })
      .attr("y1", -20)
      .attr("x2", function (d) { return xinv(d); })
      .attr("y2", -30);


    var formatMillisecond = d3.time.format(".%L"),
      formatSecond = d3.time.format("%m/%d#%I:%M %p"),
      formatMinute = d3.time.format("#%I:%M %p"),
      formatHour = d3.time.format("#%I:%M %p"),
      formatDay = d3.time.format("%a %d"),
      formatWeek = d3.time.format("%b %d"),
      formatMonth = d3.time.format("%B"),
      formatYear = d3.time.format("%Y");

    function multiFormat(date: any) {
      return (d3.time.second(date) < date ? formatMillisecond
        : d3.time.minute(date) < date ? formatSecond
          : d3.time.hour(date) < date ? formatMinute
            : d3.time.day(date) < date ? formatHour
              : d3.time.month(date) < date ? (d3.time.week(date) < date ? formatDay : formatWeek)
                : d3.time.year(date) < date ? formatMonth
                  : formatYear)(date);
    }

    const xlabel = g.selectAll(".xlabel")
      .data(x.ticks(10))
      .enter().append("text")
      .attr("class", "label")
      .style("font-size", "12px")
      .text(function (d, i) {
        if (_valueType === "Number") {
          return d;
        }
        return ``
      })
      .attr("x", function (d) { return xinv(d); })
      .attr("y", -5)
      .attr("text-anchor", "middle");

    if (_valueType === "DateTime") {
      xlabel.append("tspan")
        .text((d: any) => {
          const text = multiFormat(moment(d).toDate());
          const textSplited = text.split('#')
          return textSplited[1]
        })
        .attr("class", "tree-chart-title");

      xlabel.append("tspan")
        .text((d: any) => {
          const text = multiFormat(moment(d).toDate());
          const textSplited = text.split('#')
          return textSplited[0] || ''
        })
        .attr("class", "tree-chart-title")
        .attr("x", function (d) { return xinv(d); })
        .attr("dx", 0)
        .attr("dy", 15);
    }


    // y-Axis

    var g = svg.append("g")
      .attr("class", "y axis")


    g.append("line")
      .attr("x1", 0)
      .attr("y1", y(xmin))
      .attr("x2", 0)
      .attr("y2", y(xmax));

    g.selectAll(".yticks")
      .data(y.ticks(10))
      .enter().append("line")
      .attr("class", "ticks")
      .attr("x1", -20)
      .attr("y1", function (d) { return y(d); })
      .attr("x2", 10)
      .attr("y2", function (d) { return y(d); });

    g.selectAll(".ylabel")
      .data(y.ticks(10))
      .enter().append("text")
      .attr("class", "label")
      .style("font-size", "12px")
      .text(String)
      .attr("x", -10)
      .attr("y", function (d) { return y(d) - 2; })
      .attr("text-anchor", "middle");

  };


  //Source : https://benclinkinbeard.com/d3tips/make-any-chart-responsive-with-one-function/
  function responsivefy(svg: any) {
    // container will be the DOM element the svg is appended to
    // we then measure the container and find its aspect ratio
    const container = d3.select(svg.node().parentNode),
      width = parseInt(svg.style('width'), 10),
      height = parseInt(svg.style('height'), 10),
      aspect = width / height;

    // add viewBox attribute and set its value to the initial size
    // add preserveAspectRatio attribute to specify how to scale
    // and call resize so that svg resizes on inital page load
    svg.attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'xMinYMid')
      .call(resize);

    // add a listener so the chart will be resized when the window resizes
    // to register multiple listeners for same event type,
    // you need to add namespace, i.e., 'click.foo'
    // necessary if you invoke this function for multiple svgs
    // api docs: https://github.com/mbostock/d3/wiki/Selections#on
    d3.select(window).on('resize.' + container.attr('id'), resize);

    // this is the code that actually resizes the chart
    // and will be called on load and in response to window resize
    // gets the width of the container and proportionally resizes the svg to fit
    function resize() {
      const targetWidth = container.style('width') == "auto" ? width : parseInt(container.style('width'));
      svg.attr('width', targetWidth);
      svg.attr('height', Math.round(targetWidth / aspect));
    }
  }


  useEffect(() => {
    if (treeData != null) {
      if (Array.isArray(treeData) && treeData.length > 0) {
        drawTree(treeData[0]);
      } else {
        drawTree(treeData);
      }
    }
  }, [treeData, panelWidth]);

  return (
    <div id={"container-" + panelName} ref={containerRef}>
      <g id={panelName} ref={(refx: SVGGElement) => ref = refx}></g>
    </div>
  );
};

export default TreeChart;