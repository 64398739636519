/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import { ADD_DIALOG_ITEM, REMOVE_DIALOG_ITEM } from "../constants/dialog";

export const addDialogQueueItem = (dispatch: Function, dialogData: any) => {
    dispatch({
        type: ADD_DIALOG_ITEM,
        payload: dialogData
    });
};

export const removeDialogQueueItem = (dispatch: Function, dialogQueueItem: any, itemToRemove: number) => {
    dialogQueueItem = JSON.parse(JSON.stringify(dialogQueueItem));
    dialogQueueItem.splice(itemToRemove, 1);
    dispatch({
        type: REMOVE_DIALOG_ITEM,
        payload: dialogQueueItem
    });
};

export const emptyDialogQueueItem = (dispatch: Function) => {
    const dialogQueueItem: any = [];
    dispatch({
        type: REMOVE_DIALOG_ITEM,
        payload: dialogQueueItem
    });
};
