/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useState, useEffect } from "react";

import "./styles/index.css";

import userLogo from "../../assets/user_logo.png";
import ProfilePicture from "../ProfilePicture";

import { useDispatch, useSelector } from "react-redux";
import { toggleSidebarMenu } from "../../redux/actions/sidebarItem";
import { State } from "../../redux/reducers";
import { Dialog } from "primereact/dialog";
import { getDefaultValues } from "../../containers/PanelContainer/components/PanelDataComponent/common/action";
import { IHelpButton } from "../../models/classes/PanelData";
import HtmlViewer from "../HtmlViewer";
import { TabView, TabPanel } from "../Tab";
import { Button } from "primereact/button";
import { Gradient } from "@material-ui/icons";
import { isEmpty } from "../../utils/validation";

const Navbar: React.FC = (props) => {
    const dispatch = useDispatch();
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [isHelpButtonVisible, setIsHelpButtonVisible] = useState(false);
    const [isLoadingHelp, setIsLoadingHelp] = useState<boolean>(false);
    const [getHelpData, setHelpData] = useState<any>();
    const [displayBasic, setDisplayBasic] = useState(false);
    const [position, setPosition] = useState('center');
    const navbarItemSelector = useSelector((state: State) => state.navbarItem as IHelpButton[]);

    const onClick = () => {
        setDisplayBasic(true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = () => {
        setDisplayBasic(false);
    }

    const handleMenuClick = () => {
        if (window.innerWidth < 991) {
            setIsSidebarVisible(true);
            toggleSidebarMenu(dispatch, true);
            return
        }
        setIsSidebarVisible(!isSidebarVisible);
        toggleSidebarMenu(dispatch, !isSidebarVisible);
    };

    useEffect(() => {
        // init state
        setTimeout(() => {
            toggleSidebarMenu(dispatch, window.innerWidth < 991 ? false : true);
        }, 100);
        // eslint-disable-next-line
    }, []);

    const onSave = () => {

        dispatch({
            type: 'TOGGLE_SAVE_CONFIG_BUTTON',
            payload: [
                {
                    SaveConfigEnable : false
                }
            ]
        })

        dispatch({
            type: 'ON_SAVE_CONFIG_BUTTON',
            payload: [{
                PerformSave : true
            }]
        })

    }
    

    return (
        <div className="navbar" style={isSidebarVisible ? { marginLeft: "250px", width: `${window.innerWidth < 991 ? 'calc(100%)' : 'calc(100% - 250px)'}`, background: !isEmpty( process.env.REACT_APP_COLOR_HEADER) ? process.env.REACT_APP_COLOR_HEADER : undefined } : {background: !isEmpty( process.env.REACT_APP_COLOR_HEADER) ? process.env.REACT_APP_COLOR_HEADER : undefined } }>
            <div className="menu" onClick={handleMenuClick}>
                <i className="pi pi-bars" />
            </div>
            {navbarItemSelector && navbarItemSelector?.filter(x => x?.HelpEnable).length > 0 ? <div onClick={() => onClick()} style={{ position: 'absolute', right: '70px', top: '15px', cursor: 'pointer' }}>Help</div> : <></>}
            {navbarItemSelector && navbarItemSelector?.filter(x => x?.SaveConfigEnable).length > 0 ? <div onClick={() => onSave()} style={{ position: 'absolute', right: '70px', top: '15px', cursor: 'pointer' }}>Save Config</div> : <></>}
            {(navbarItemSelector && navbarItemSelector?.filter(x => x?.HelpEnable).length > 0) ?
                <Dialog header={"Help Center"} className="p-dialog-content-help" visible={displayBasic} style={{ width: '50vw' }} onHide={() => onHide()}>
                    <TabView>
                        {navbarItemSelector?.filter(x => x?.HelpEnable).map((help, index) => (
                            <TabPanel
                                key={index}
                                header={
                                    help?.HelpTitle !== ""
                                        ? (help?.HelpTitle || '')
                                        : `Help Content ${index + 1}`
                                }
                            >
                                <HtmlViewer Code={help?.HelpCode || ''} />
                            </TabPanel>
                        ))}
                    </TabView>
                </Dialog> : null}
            <ProfilePicture userLogo={userLogo} />
        </div>
    );
};

export default Navbar;
