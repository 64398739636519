/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

export const addIfNotExist = (
    array: any[],
    propertyToCheck: string,
    propertyToCheckValue: string,
    valueToAddIntoArray: any,
    index: number,
) => {
    const found = array.some(el => el[propertyToCheck] === propertyToCheckValue);
    if (!found) array.splice(index, 0, valueToAddIntoArray);
    return array;
};