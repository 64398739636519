export const startGetSidebarData = () => ({
    isLoadingGetSidebarData: true,
    isSuccessGetSidebarData: false,
    isErrorGetSidebarData: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const successGetSidebarData = () => ({
    isLoadingGetSidebarData: false,
    isSuccessGetSidebarData: true,
    isErrorGetSidebarData: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const errorGetSidebarData = () => ({
    isLoadingGetSidebarData: false,
    isSuccessGetSidebarData: false,
    isErrorGetSidebarData: true,
    loadingData: null,
    successData: null,
    errorData: null
});