/* ------------------------------------------------------------------------------------------------------------  */

import { Permissionable } from "../models/classes/Permissionable";

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

export function checkPermission(permission: Permissionable): boolean {
    const permissionItem : string | null = window.localStorage.getItem("permissionItem");
    
    if (permission.RequirePermission) {
        if (permissionItem != null) {
            const permissionItems: string[] = JSON.parse(permissionItem)
            return !permissionItems.includes(permission.Permission)
        }
    }

    return permission.Hidden;
}