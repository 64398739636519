/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

import React from 'react';
import { isEmpty } from '../../../../utils/validation';
import './styles/index.css';

interface Props {
    companyLogo: string;
}

const backgroundStyle = !isEmpty(process.env.REACT_APP_LOGO_BACKGROUND_COLOR) ? {background: process.env.REACT_APP_LOGO_BACKGROUND_COLOR} : {};

const CompanyLogo: React.FC<Props> = (props) => {
    const dataLogo = !isEmpty(process.env.REACT_APP_LOGO_APP) ? <img src={process.env.REACT_APP_LOGO_APP} alt='Company Logo'/> : <img src={props.companyLogo} alt='Company Logo'/>;
    
    return (
        <div className='company-logo' style={backgroundStyle}>
            <div className="logo-wrapper">
                {dataLogo}
                {/* ORIGINAL CODE BELOW */}
                {/* <img src={props.companyLogo} alt='Company Logo'/> */}
                {/* <img src={process.env.REACT_APP_LOGO_APP} alt='Company Logo'/> */}
            </div>
        </div>
    );
};

export default CompanyLogo;
