/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

import React, { useEffect, useState } from "react";

import "./index.css";

interface IContextMenuItemList {
    parentRef: any;
    contextMenuItems: any;
}

function ContextMenuItemList(props: IContextMenuItemList) {
    const { parentRef, contextMenuItems } = props;

    const [isVisible, setVisibility ] = useState(false);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    useEffect(() => {
        const parent = parentRef.current;
        if (!parent) {
            return;
        }

        const showMenu = (event: MouseEvent) => {
            event.preventDefault();

            setVisibility(true);
            setX(event.clientX);
            setY(event.clientY);
        };

        const closeMenu = () => {
            setVisibility(false);
        };

        parent.addEventListener('contextmenu', showMenu);
        window.addEventListener('click', closeMenu);

        return function cleanup() {
            parent.removeEventListener('contextmenu', showMenu);
            window.removeEventListener('click', closeMenu);
        };
    });

    return (
        <div 
            className="context-menu-items"
            style={{
                top: y,
                left: x,
                visibility: isVisible? "visible": "hidden"
            }}
        >
            {contextMenuItems.map((item: any, index: number) => {
                return (
                    <div
                        key={index}
                        onClick={item.onClick}
                        className="context-menu-item"
                    >
                        {item.text}
                    </div>
                );
            })}
        </div>
    );
}

export default ContextMenuItemList;