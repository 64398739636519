/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

import React, {useEffect, useState} from "react";
import {isEmpty} from "../../../../../../../../utils/validation";
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {useDispatch} from "react-redux";
import {cancelDataFetch} from "../../../../../../../../redux/actions/panelItem";

interface Props {
    panelData: any;
    panelInLoading: string;
    isTableInLoadingState: boolean;
    setIsTableInLoadingState: Function;
}

const PanelDataLoadingComponent: React.FC<Props> = (props) => {
    const { 
        panelInLoading,
        panelData,
        isTableInLoadingState,
        setIsTableInLoadingState
    } = props;

    const [isInLoading, setIsInLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isEmptyData, setIsEmptyData] = useState<boolean>(false);

    const dispatch = useDispatch();

    // Get data table
    useEffect(() => {
        if (!isEmpty(panelData)) {
            if (panelData.isLoadingGetTableData)
                if (panelData.loadingData.targetPanel === panelInLoading) {
                    setIsInLoading(true);
                    setErrorMessage("");
                    setIsEmptyData(false);
                }
            if (panelData.isErrorGetTableData)
                if (panelData.errorData.targetPanel === panelInLoading) {
                    setIsInLoading(false);
                    if (panelData.errorData.hasOwnProperty("message")) {
                        if (typeof panelData.errorData.message === "string")
                        setErrorMessage(panelData.errorData.message);
                        else {
                            if (panelData.errorData.message.hasOwnProperty("ReturnMessage"))
                                setErrorMessage(panelData.errorData.message.ReturnMessage);
                            else
                                setErrorMessage(JSON.stringify(panelData.errorData.message));
                        }
                    } else {
                        setErrorMessage("Internal Server Error, ERROR CODE : 501");
                    }
                }
            if (panelData.isSuccessGetTableData) {
                if (panelData.successData.targetPanel === panelInLoading) {
                    setIsInLoading(false);
                    if (panelData.successData.hasOwnProperty("data") && !isEmpty(panelData.successData.data)) {
                        if (panelData.successData.data.length) {
                            if (isEmpty(panelData.successData.data[0])) {
                                setIsEmptyData(true);
                            }
                        } else {
                            if (
                                !panelData.successData.data.hasOwnProperty("Documents") &&
                                !panelData.successData.data.hasOwnProperty("TotalDocuments")
                            ) {
                                setIsEmptyData(true);
                            }
                        }
                    } else {
                        setIsEmptyData(true);
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [panelData]);

    useEffect(() => {
        if (isTableInLoadingState) {
            setIsInLoading(true);
            setErrorMessage("");
            setIsEmptyData(false);
        } else setIsInLoading(false);
    }, [isTableInLoadingState]);

    if (!isEmpty(panelData))
        return (
            <div
                className="loading-component"
                style={{textAlign: "center"}}
            >
                {
                    isInLoading?
                        <React.Fragment>
                            <ProgressSpinner style={{width: "50px", height: "50px"}} /><br/>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <span>Loading...</span>
                                <Button
                                    className="p-button-raised p-button-danger"
                                    label="Cancel"
                                    onClick={() => {
                                        setIsInLoading(false);
                                        setIsTableInLoadingState(false);
                                        cancelDataFetch(dispatch, panelData.loadingData?.targetPanel);
                                    }}
                                />
                            </div>
                        </React.Fragment> : null
                }

                {
                    !isInLoading && errorMessage !== "" ?
                        <span>Failed to fetch data.<br/>Error message: {errorMessage}</span> : null
                }

                {/* {
                    !isInLoading && isEmptyData ?  <span>Empty data.</span> : null
                } */}
            </div>
        );
    return <div/>;
};

export default PanelDataLoadingComponent;