/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

export const isUrlParamExist = (val: any): boolean => {
    const url = window.location.href;
    if(url.indexOf('?' + val + '=') !== -1)
        return true;
    else if(url.indexOf('&' + val + '=') !== -1)
        return true;
    return false;
};

export const getUrlParam = (val: any): string => {
    const url = window.location.href;
     // eslint-disable-next-line
    val = val.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+val+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( url );
    if(isUrlParamExist(val)) {
        return results![1];
    }
    return "";
};

export const removeParam = (key: string, sourceURL: string): string => {
    let params = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";

    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        params = params + "?" + params_arr.join("&");
    }

    if (params === "?") params = "";
    return params;
}