/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import { IAction } from "../../models/interfaces/IAction";
import { ADD_DIALOG_ITEM, REMOVE_DIALOG_ITEM } from "../constants/dialog";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = [], action: IAction) => {
    switch (action.type) {
        case ADD_DIALOG_ITEM:
            return [...state, ...[action.payload]];
        case REMOVE_DIALOG_ITEM:
            return action.payload;
        default:
            return state;
    }
};
