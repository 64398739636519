import { useRef } from 'react';

const useFocus = <T extends HTMLElement>(): [React.RefObject<T>, () => Promise<void>] => {
    const htmlElRef = useRef<T>(null);
    const setFocus = () => {
        return new Promise<void>((resolve) => {
            if (htmlElRef.current) {
                htmlElRef.current.focus();
            }
            resolve();
        });
    };

    return [htmlElRef, setFocus];
};

export default useFocus;
