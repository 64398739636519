/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React from 'react';
import {InputText} from 'primereact/inputtext';
import {SplitButton} from 'primereact/splitbutton';

const TableHeaderElement = (multipleSelectActionButtons: any[], setGlobalFilter: Function): JSX.Element => {
    return (
        <div style={{display: "flex"}}>
            <div
                className="p-inputgroup"
                style={{
                    textAlign: "left",
                    flexGrow: 1,
                    width: "auto"
                }}
            >
                <span className="p-inputgroup-addon">
                    <i className="pi pi-search" style={{margin: "4px 4px 0 0"}}/>
                </span>
                <InputText
                    style={{width: "100%"}}
                    type="search"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => setGlobalFilter(e.target.value)}
                    placeholder="Global Search"/>
            </div>
            {
                multipleSelectActionButtons.length > 0 ?
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "5px"
                        }}
                    >
                        <SplitButton label="Batch Action" model={multipleSelectActionButtons}/>
                    </div> :
                    null
            }
        </div>
    )
};

export default TableHeaderElement;
