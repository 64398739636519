export const startGetTableData = () => ({
    isLoadingGetTableData: true,
    isSuccessGetTableData: false,
    isErrorGetTableData: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const successGetTableData = () => ({
    isLoadingGetTableData: false,
    isSuccessGetTableData: true,
    isErrorGetTableData: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const errorGetTableData = () => ({
    isLoadingGetTableData: false,
    isSuccessGetTableData: false,
    isErrorGetTableData: true,
    loadingData: null,
    successData: null,
    errorData: null
});

export const startExportTableData = () => ({
    isExporting: true,
    isExportSuccess: false,
    isExportError: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const successExportTableData = () => ({
    isExporting: false,
    isExportSuccess: true,
    isExportError: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const errorExportTableData = () => ({
    isExporting: false,
    isExportSuccess: false,
    isExportError: true,
    loadingData: null,
    successData: null,
    errorData: null
});

export const startSubmitActionData = () => ({
    isLoadingSubmitActionData: true,
    isSuccessSubmitActionData: false,
    isErrorSubmitActionData: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const successSubmitActionData = () => ({
    isLoadingSubmitActionData: false,
    isSuccessSubmitActionData: true,
    isErrorSubmitActionData: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const errorSubmitActionData = () => ({
    isLoadingSubmitActionData: false,
    isSuccessSubmitActionData: false,
    isErrorSubmitActionData: true,
    loadingData: null,
    successData: null,
    errorData: null
});

export const requestCanceled = () => ({
    isLoadingSubmitActionData: false,
    isSuccessSubmitActionData: false,
    isErrorSubmitActionData: false,
    loadingData: null,
    successData: null,
    errorData: null
});


export const startCallApi = () => ({
    isLoadingSubmitActionData: false,
    isSuccessSubmitActionData: false,
    isErrorSubmitActionData: false,
    isLoadingCallApi: true,
    loadingData: null,
    successData: null,
    errorData: null
});

export const successCallApi = () => ({
    isLoadingSubmitActionData: false,
    isSuccessSubmitActionData: false,
    isErrorSubmitActionData: false,
    isLoadingCallApi: false,
    isSuccessCallApi: true,
    isErrorCallApi: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const errorCallApi = () => ({
    isLoadingSubmitActionData: false,
    isSuccessSubmitActionData: false,
    isErrorSubmitActionData: false,
    isLoadingCallApi: false,
    isSuccessCallApi: false,
    isErrorCallApi: true,
    loadingData: null,
    successData: null,
    errorData: null
});

export const resetTableData = () => ({
    isResettingGetTableData: true,
    resetData: null
});