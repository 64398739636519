/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

export const ADD_DIALOG_ITEM = "ADD_DIALOG_ITEM";
export const REMOVE_DIALOG_ITEM = "REMOVE_DIALOG_ITEM";