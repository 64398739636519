export const startLogin = () => ({
    isLoadingLogin: true,
    isSuccessLogin: false,
    isErrorLogin: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const successLogin = () => ({
    isLoadingLogin: false,
    isSuccessLogin: true,
    isErrorLogin: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const errorLogin = () => ({
    isLoadingLogin: false,
    isSuccessLogin: false,
    isErrorLogin: true,
    loadingData: null,
    successData: null,
    errorData: null
});


export const startLoginConfig = () => ({
    isLoadingLoginConfig: true,
    isSuccessLoginConfig: false,
    isErrorLoginConfig: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const successLoginConfig = () => ({
    isLoadingLoginConfig: false,
    isSuccessLoginConfig: true,
    isErrorLoginConfig: false,
    loadingData: null,
    successData: null,
    errorData: null
});

export const errorLoginConfig = () => ({
    isLoadingLoginConfig: false,
    isSuccessLoginConfig: false,
    isErrorLoginConfig: true,
    loadingData: null,
    successData: null,
    errorData: null
});