/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

export const API_GET_TABLE_DATA = "API_GET_TABLE_DATA";
export const API_GET_TABLE_DATA_SUCCESS = "API_GET_TABLE_DATA_SUCCESS";
export const API_GET_TABLE_DATA_ERROR = "API_GET_TABLE_DATA_ERROR";

export const API_RESET_TABLE_DATA = "API_RESET_TABLE_DATA";

export const EXPORT_TABLE_DATA = "EXPORT_TABLE_DATA";
export const EXPORT_TABLE_DATA_SUCCESS = "EXPORT_TABLE_DATA_SUCCESS";
export const EXPORT_TABLE_DATA_ERROR = "EXPORT_TABLE_DATA_ERROR";

export const ACTION_SUBMIT_DATA = "ACTION_SUBMIT_DATA";
export const ACTION_SUBMIT_DATA_SUCCESS = "ACTION_SUBMIT_DATA_SUCCESS";
export const ACTION_SUBMIT_DATA_ERROR = "ACTION_SUBMIT_DATA_ERROR";

export const ACTION_CALL_API = "ACTION_CALL_API";
export const ACTION_CALL_API_SUCCESS = "ACTION_CALL_API_SUCCESS";
export const ACTION_CALL_API_ERROR = "ACTION_CALL_API_ERROR";

export const CANCEL_DATA_FETCH = "CANCEL_DATA_FETCH";

export const ON_BATCH_ACTION_BUTTON_CLICKED = "ON_BATCH_ACTION_BUTTON_CLICKED";

export const RESET_FORM = "RESET_FORM";
