/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

import api from "../../common/api";
import {
    TOGGLE_SIDEBAR_MENU,
    API_GET_SIDEBAR_MENU,
    API_GET_SIDEBAR_MENU_ERROR,
    API_GET_SIDEBAR_MENU_SUCCESS
} from "../constants/sidebarItem";
import { IAPIOption } from "../../models/interfaces/IAPIOption";

export const toggleSidebarMenu = (dispatch: Function, isSidebarVisible: boolean) => {
    dispatch({
        type: TOGGLE_SIDEBAR_MENU,
        payload: {isSidebarVisible}
    })
};

export const getSidebarMenu = (dispatch: Function) => {
    dispatch({
        type: API_GET_SIDEBAR_MENU
    });

    const apiOption: IAPIOption = {path: "sidebar"};
    api(apiOption).then((response: any) => {
        if (response.success) dispatch(getSidebarMenuSuccess(response.data));
        else dispatch(
            getSidebarMenuError(
                response.message,
                response.hasOwnProperty("data")? response.data : null
            )
        );
    }).catch(error => dispatch(
        getSidebarMenuError(
            JSON.stringify(error),
            null
        )
    ));
};

export const getSidebarMenuSuccess = (data: any) => {
    return {
        type: API_GET_SIDEBAR_MENU_SUCCESS,
        payload: {data}
    }
};

export const getSidebarMenuError = (message: any, data: any) => {
    return {
        type: API_GET_SIDEBAR_MENU_ERROR,
        payload: {message, data}
    }
};

