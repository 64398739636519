import React, { useEffect, useState } from "react";
import { getDefaultValues } from "../../containers/PanelContainer/components/PanelDataComponent/common/action";

const HtmlViewer: React.FC<{ Code: string }> = (props) => {
    const [isLoadingHelp, setIsLoadingHelp] = useState<boolean>(false);
    const [getHelpData, setHelpData] = useState<any>();
    const { Code } = props;

    const getHelpContent = async (code: string) => {
        setIsLoadingHelp(true);
        const result = await getDefaultValues('GetHelpContentAPI', [{
            FieldName: 'Code',
            Value: code ?? '',
            DataType: 'Text'
        }])
        if (result.success && result.data.length > 0) {
            setHelpData(result.data[0]);
        }
        setIsLoadingHelp(false);
    }

    useEffect(() => {
        getHelpContent(Code)
    }, []);

    return (
        <div>
            {
                isLoadingHelp ? 'Loading....' : getHelpData ? <div dangerouslySetInnerHTML={{ __html: getHelpData.Content ?? '' }} /> : ''
            }
        </div>
    );
}

export default HtmlViewer;