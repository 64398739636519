/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import { combineReducers } from "redux";

import authReducer from "./auth";
import beaconReducer from "./beacon";
import dialogReducer from "./dialog";
import panelReducer from "./panelItem";
import sidebarReducer from "./sidebarItem";
import msalReducer from "./msal";
import navBarReducer from "./navbarItem"
import formFieldReducer from "./formFieldItem";
export class State {
    authItem: any = authReducer;
    beaconItem: any = beaconReducer;
    dialogItem: any = dialogReducer;
    panelItem: any = panelReducer;
    sidebarItem: any = sidebarReducer;
    msalItem: any = msalReducer;
    navbarItem: any = navBarReducer;
    formFieldItem: any = formFieldReducer;
}

export default combineReducers(new State());
