/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import moment from "moment";

import { getDropdownValues, getDropdownValuesByParams } from "../../../common/action";

import { ActionData } from "../../../../../../../models/classes/ActionData";
import { DialogField } from "../../../../../../../models/classes/DialogField";
import { PanelFieldData } from "../../../../../../../models/classes/PanelFieldData";

import { getTodayDate } from "../../../../../../../utils/date";
import { isEmpty } from '../../../../../../../utils/validation';
import api from "../../../../../../../common/api";
import { IAPIOption } from "../../../../../../../models/interfaces/IAPIOption";
import { IFieldValue } from "../../../../../../../models/interfaces/IFieldValue";
import __t from "../../../../../../../utils/translation";
import _ from "lodash";

export const openDialog = async (
    setDialog: Function,
    action: ActionData,
    tableFields: PanelFieldData[] | null,
    rowData: any,
    dialogQueueSelector?: any,
    appData?: any,
    setAppData?: any
) => {
    let fields: DialogField[] = [];
    let controlFields: DialogField[] = [];
    let actionCopy: ActionData = JSON.parse(JSON.stringify(action));
    let tableFieldsCopy: PanelFieldData[] = JSON.parse(JSON.stringify(tableFields));
    
    const compare = ( a:any, b:any ) => {
        if (a.DisplayPosition === "x,x") {
            return 1;
        }
        if ( a.DisplayPosition < b.DisplayPosition ){
          return -1;
        }
        if ( a.DisplayPosition > b.DisplayPosition){
          return 1;
        }
        return 0;
    }

    const resetAppData = () => {
        
        fields.forEach(field => {
            if (appData && setAppData) {
                const data = (appData.LOV??[]).filter((item: any) => (!isEmpty(item["LOV_" + field.FieldName])));
                if (!isEmpty(data)) {
                    field.value = null;
                    setAppData({
                        ...appData,
                        LOV: data
                    })
                }
            }
            const component = document.getElementById("LOV_" + field.FieldName) as HTMLInputElement
            if (component) {
                component.value = '';
            }
        });
    }


    if (action.Fields) if (action.Fields.length > 0) {
        
        setDialog({
            name: action.ActionName,
            header: __t(action, "ActionLabel"),
            width: action.ActionDialogWidth,
            height: action.ActionDialogHeight,
            loading: true
        });

        // parse table fields
        // if the dialog is batch, don't show fields from the table
        if (!dialogQueueSelector) if (tableFieldsCopy) if (tableFieldsCopy.length > 0) {            
            for (const tableField of tableFieldsCopy) {

                const overrideTableField = action.Fields.find(actionField => actionField.FieldName === tableField.FieldName);

                tableField["DisplayPosition"] = `x,x`;
                let disabled = overrideTableField?.hasOwnProperty("Editable") ? 
                                ((!isEmpty(overrideTableField) && !isEmpty(overrideTableField?.Editable) ? 
                                    overrideTableField?.Editable : tableField.Editable) ?? false) : true;
                
                let value = !!dialogQueueSelector? "": rowData?.hasOwnProperty(tableField.FieldName)?
                                                            rowData[tableField.FieldName]: null;

                fields.push(new DialogField(tableField, !disabled, value));
            }
        }

        // parse action fields
        for (const actionField of actionCopy.Fields) {
            let overrideTableFieldIndex = -1;
            let getDropdownJobs:any[] = []
            if (tableFieldsCopy) {
                overrideTableFieldIndex = tableFieldsCopy.findIndex(field => field.FieldName === actionField.FieldName);
            }


            if (actionField.SubscribeComponent) {
                for (const subcribe of actionField.SubscribeComponent!) {
                    if (_.get(subcribe, "Source", null)) {
                        if (_.get(rowData, subcribe.Source.FieldName, null)) {
                            if (subcribe?.Actions) {
                                subcribe.Actions.forEach(async (action) => {
                                    if (action.ActionType === "FetchDropdownData") {
                                        if (action?.FetchOption?.APIParameters) {
                                            let inputParameterValues: any = [];

                                            action.FetchOption.APIParameters.forEach((param) => {
                                                let value;

                                                if (param.DefaultValue) {
                                                    value = param.DefaultValue;
                                                }

                                                if (
                                                    param?.ValueSource === "FieldSelectedItem"
                                                ) {
                                                    value = _.get(rowData, subcribe.Source.FieldName, null)
                                                }

                                                inputParameterValues.push({
                                                    fieldName: param.FieldName,
                                                    value,
                                                    dataType: param.DataType
                                                });

                                            });

                                            getDropdownJobs.push(
                                                getDropdownValuesByParams(action.FetchOption?.APIName, inputParameterValues).then((response: any) => {

                                                    if (response) if (response.success) if (response.data) {
                                                        let ListOfValues = [];
                                                        if (Array.isArray(response.data) && response.data.length > 0) {
                                                            ListOfValues = response.data;
                                                        } else if (response.data.ListOfValues) {
                                                            ListOfValues = response.data.ListOfValues;
                                                        }

                                                        if (ListOfValues) {
                                                            actionField["ListOfValues"] = ListOfValues;
                                                            if (overrideTableFieldIndex < 0) {

                                                                let value = _.get(rowData, actionField.FieldName, null)
                                                                fields.push(new DialogField(actionField, false, value));
                                                            } else {

                                                                let value = _.get(rowData, actionField.FieldName, null)

                                                                if (!isEmpty(fields[overrideTableFieldIndex])) {
                                                                    fields[overrideTableFieldIndex]["ListOfValues"] = ListOfValues;
                                                                    fields[overrideTableFieldIndex]["ListOfValuesAPI"] = actionField.ListOfValuesAPI;
                                                                    fields[overrideTableFieldIndex]["value"] = value;    
                                                                }
                                                                
                                                            }
                                                        }
                                                    }

                                                })
                                            )

                                        }
                                    }
                                })
                            }
                        }
                    }
                }

                await Promise.all(getDropdownJobs);
            } else {

                if (actionField.ListOfValuesAPI) {
                    const response: any = await getDropdownValues(actionField.ListOfValuesAPI, true);
    
                    if (response) if (response.success) if (response.data) {
                        let ListOfValues = [];
                        if (Array.isArray(response.data) && response.data.length > 0) {
                            ListOfValues = response.data;
                        } else if (response.data.ListOfValues) {
                            ListOfValues = response.data.ListOfValues;
                        }
    
                        if (ListOfValues) {
                            actionField["ListOfValues"] = ListOfValues;
                            if (overrideTableFieldIndex < 0) {
                                let value = _.get(rowData, actionField.FieldName, null)
                                fields.push(new DialogField(actionField, false, value));
                            } else {
                                
                                let value = _.get(rowData, actionField.FieldName, null)
                                if (!isEmpty(fields[overrideTableFieldIndex])) {
                                    fields[overrideTableFieldIndex]["ListOfValues"] = ListOfValues;
                                    fields[overrideTableFieldIndex]["ListOfValuesAPI"] = actionField.ListOfValuesAPI;
                                    fields[overrideTableFieldIndex]["value"] = value;    
                                }
                            }
                        }
                    }
                }
            }

            if (!isEmpty(actionField?.DefaultValue) || isEmpty(actionField?.DefaultValue as DialogField)) {
                if (actionField?.DataType === "Date" && !isEmpty(actionField.DefaultValue)) {
                    const todayDate = getTodayDate(actionField.DefaultValue);
                    actionField.DefaultValue = !isEmpty(todayDate) ? moment(todayDate, "YYYY-MM-DD").format(actionField.FieldFormat) : null;
                }

                if (actionField?.DataType === "Datetime" && !isEmpty(actionField.DefaultValue)) {
                    const todayDate = getTodayDate(actionField.DefaultValue);
                    actionField.DefaultValue = !isEmpty(todayDate) ? moment(todayDate, "YYYY-MM-DD HH:mm:ss").format(actionField.FieldFormat) : null;
                }

                if (overrideTableFieldIndex < 0) {
                    actionField.Value = typeof actionField.DefaultValue !== 'object' ? (!isEmpty(actionField.DefaultValue)? actionField.DefaultValue:null) : null;
                } else {
                    if((!isEmpty(fields) && !isEmpty(fields![overrideTableFieldIndex])) && isEmpty(fields![overrideTableFieldIndex]?.value)) {
                        fields![overrideTableFieldIndex].value = typeof actionField.DefaultValue !== 'object' ? (!isEmpty(actionField.DefaultValue)? actionField.DefaultValue:null) : null;
                    }
                }
            }

            if (overrideTableFieldIndex < 0) {
                actionField["DisplayPosition"] = actionField.DisplayPosition ?? `0,${fields.length}`;

                let disabled = false
                if (actionField.hasOwnProperty("Editable")) {
                    disabled = ((!isEmpty(actionField?.Editable) ? !actionField?.Editable : false) ?? false);
                }
                let value = !isEmpty(actionField.Value)? actionField.Value: null;                

                fields.push(new DialogField(actionField, disabled, value));
            } else {
                
                Object.keys(actionField).forEach(key => {
                    if (isEmpty((fields[overrideTableFieldIndex] as any))) {

                        let value = !isEmpty(actionField.Value)? actionField.Value: null;
                        fields.push(new DialogField(actionField, false, value));
                    } else {
                        (fields[overrideTableFieldIndex] as any)[key] = (actionField as any)[key]
                    }

                });
                
                if (fields[overrideTableFieldIndex]?.hasOwnProperty("Hidden")) {
                    fields[overrideTableFieldIndex].Hidden = actionField.Hidden;
                }
                if (fields[overrideTableFieldIndex]?.hasOwnProperty("Editable")) {
                    fields[overrideTableFieldIndex].Editable = actionField.hasOwnProperty("Editable") ? !actionField.Editable : true;
                }
                //fields[overrideTableFieldIndex].DisplayPosition = actionField.DisplayPosition;
            }
        }

        if (!isEmpty(action.FieldControllerAPI)) {

            let fieldValues: IFieldValue[] = [];

            action.Fields.forEach(f => {
                fieldValues.push(
                    {
                        fieldName: f.FieldName,
                        dataType: f.DataType,
                        value: rowData.hasOwnProperty(f.FieldName) ? rowData[f.FieldName] : null
                    } as IFieldValue
                );
            });

            let requestBody = {
                APIName: action?.FieldControllerAPI,
                inputParameterValues: fieldValues
            };

            const apiOption: IAPIOption = {
                path: "query_management",
                method: "POST",
                data: requestBody
            }

            const response = await api(apiOption);
            if (response && response.success) {
                if (!isEmpty(response.data)) {
                    let data = response.data;
                    
                    if (Array.isArray(response.data)) {
                        data = response.data[0];
                    }

                    Object.keys(data).forEach(o => {                        
                        controlFields.push({
                            FieldName   : o.replace("_EDITABLE", ""),
                            disabled    : (data[o] === 'false' || data[o] === false || data[o] === 0 || data[o] === '0') ? true : false
                        } as DialogField);
                    });
                }
            }

        }
        
        // If the data source is from MongoDB
        if (rowData?._id) {
            const id = new PanelFieldData();
            id.FieldName = "_id";
            id.FilterType = "Text";
            id.DataType = "Text";
            id.InputType = "InputText";
            id.Hidden = true;
            id.Editable = true;
            id.DisplayPosition = `x,x`;
            fields.push(new DialogField(id, false, rowData._id));
        }

        const rows = actionCopy.Fields.map(x=>  parseInt((x.DisplayPosition ?? 'x,x').split(",")[1]) as number)
        for (let index = 0; index < fields.length; index++) {
            const element = fields[index];
            const field = actionCopy.Fields.find(field => field.FieldName === element.FieldName);
            if (isEmpty(field)) {
                element.DisplayPosition = "x,x";
            } else {
                if (isEmpty(element.DisplayPosition)) {
                    element.DisplayPosition = "x,x";
                }
                element.PublishComponent = isEmpty((field as any).PublishComponent) ? null : (field as any).PublishComponent;
                element.SubscribeComponent = isEmpty((field as any).SubscribeComponent) ? null : (field as any).SubscribeComponent;
                element.FieldConfiguration = field?.FieldConfiguration;
            }
        }

        fields = fields.sort( compare );
        
        let rowPosition = isNaN(Math.max(...rows)) ? 0 : Math.max(...rows);

        for (let index = 0; index < fields.length; index++) {
            const element = fields[index];
            if (element.DisplayPosition === "x,x") {
                rowPosition += 1;
                element.DisplayPosition = `0,${rowPosition}`
                
            }
        }

        let results: DialogField[] = []

        fields.forEach(f => {
            const controlIndex = controlFields.findIndex(field => field.FieldName === f.FieldName);
            if (controlIndex >=0 ) {
                f.disabled  = controlFields[controlIndex].disabled;
            }
            const index = results.findIndex(field => field.FieldName === f.FieldName);
            if (index < 0) {
                results.push(f);
            }
        });

        fields =[...results]

        const rowsFields = fields.map(x=>  parseInt(x.DisplayPosition.split(",")[1]) as number)
        const maxRow = Math.max(...rowsFields);
        if (action.NumberOfRows < maxRow) {
            action.NumberOfRows = maxRow + 1;
        }

        if (fields.length > action.NumberOfRows) {
            action.NumberOfRows = fields.length
        }
        
        resetAppData();

        setDialog({
            name: action.ActionName,
            header: __t(action, "ActionLabel"),
            fields,
            width: action.ActionDialogWidth || process.env.REACT_APP_LAYOUT_INPUT === "grid" ? "fit-content" : '500px',
            height: action.ActionDialogHeight || '700px',
            numberOfColumns: action.NumberOfColumns || 1,
            numberOfRows: action.NumberOfRows || fields.length,
            APIName: action.APIName,
            dialogQueueSelector,
            rowData,
            postActions: actionCopy?.PostActions,
            requiredConfirmation: action.RequiredConfirmation,
            isNeedResubmit: false,
            isLoadingCallApi: false, 
            isSuccessCallApi: false, 
            isErrorCallApi: false,
            progressQueue: []
        });
    } else {

        setDialog({
            name: action.ActionName,
            header: __t(action, "ActionLabel"),
            width: action.ActionDialogWidth,
            height: action.ActionDialogHeight,
            loading: true
        });
        
        for (let index = 0; index < tableFields!.length; index++) {
            const field = tableFields![index];
            let disabled = field?.hasOwnProperty("Editable") ? (!isEmpty(field) && !isEmpty(field?.Editable) ? field?.Editable : false) : true;
            
            if (isEmpty(field.DisplayPosition)) {
                field.DisplayPosition = "x,x"
            }
            
            let value = rowData?.hasOwnProperty(field.FieldName) ? rowData[field.FieldName]: null;

            fields.push(new DialogField(field, !disabled, value));
        };


        // parse action fields
        for (const actionField of tableFields!) {
            
            if (actionField.ListOfValuesAPI) {
                const response: any = await getDropdownValues(actionField.ListOfValuesAPI, true);

                if (response) if (response.success) if (response.data) {
                    let ListOfValues = [];
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        ListOfValues = response.data;
                    } else if (response.data.ListOfValues) {
                        ListOfValues = response.data.ListOfValues;
                    }

                    if (ListOfValues) {
                        actionField["ListOfValues"] = ListOfValues;
                    }
                }
            }

            if (!isEmpty(actionField?.DefaultValue) || isEmpty(actionField?.DefaultValue as DialogField)) {
                if (actionField?.DataType === "Date" && !isEmpty(actionField.DefaultValue)) {
                    const todayDate = getTodayDate(actionField.DefaultValue);
                    actionField.DefaultValue = moment(todayDate, "YYYY-MM-DD").format(actionField.FieldFormat);
                }

                if (actionField?.DataType === "Datetime" && !isEmpty(actionField.DefaultValue)) {
                    const todayDate = getTodayDate(actionField.DefaultValue);
                    actionField.DefaultValue = moment(todayDate, "YYYY-MM-DD HH:mm:ss").format(actionField.FieldFormat);
                }
            }
        }

        if (!isEmpty(action.FieldControllerAPI)) {

            let fieldValues: IFieldValue[] = [];

            action.Fields.forEach(f => {
                fieldValues.push(
                    {
                        fieldName: f.FieldName,
                        dataType: f.DataType,
                        value: rowData.hasOwnProperty(f.FieldName) ? rowData[f.FieldName] : null
                    } as IFieldValue
                );
            });

            let requestBody = {
                APIName: action?.FieldControllerAPI,
                inputParameterValues: fieldValues
            };

            const apiOption: IAPIOption = {
                path: "query_management",
                method: "POST",
                data: requestBody
            }

            const response = await api(apiOption);
            if (response && response.success) {
                if (!isEmpty(response.data)) {
                    let data = response.data;
                    
                    if (Array.isArray(response.data)) {
                        data = response.data[0];
                    }

                    Object.keys(data).forEach(o => {                        
                        controlFields.push({
                            FieldName   : o.replace("_EDITABLE", ""),
                            disabled    : (data[o] === 'false' || data[o] === false || data[o] === 0 || data[o] === '0') ? true : false
                        } as DialogField);
                    });
                }
            }

        }
        
        
        // If the data source is from MongoDB
        if (rowData?._id) {
            const id = new PanelFieldData();
            id.FieldName = "_id";
            id.FilterType = "Text";
            id.DataType = "Text";
            id.InputType = "InputText";
            id.Hidden = true;
            id.Editable = true;
            id.DisplayPosition = `x,x`;
            fields.push(new DialogField(id, false, rowData._id));
        }

        const rows = tableFields!.map(x=>  parseInt((x.DisplayPosition ?? 'x,x').split(",")[1]) as number)

        for (let index = 0; index < fields.length; index++) {
            const element = fields[index];
            const field = tableFields!.find(field => field.FieldName === element.FieldName);
            if (isEmpty(field)) {
                element.DisplayPosition = "x,x";
            } else {
                if (isEmpty(element.DisplayPosition)) {
                    element.DisplayPosition = "x,x";
                }
                element.PublishComponent = isEmpty((field as any).PublishComponent) ? null : (field as any).PublishComponent;
                element.SubscribeComponent = isEmpty((field as any).SubscribeComponent) ? null : (field as any).SubscribeComponent;
                element.FieldConfiguration = field?.FieldConfiguration;
            }
        }

        fields = fields.sort( compare );
        
        let rowPosition = isNaN(Math.max(...rows)) ? 0 : Math.max(...rows);

        for (let index = 0; index < fields.length; index++) {
            const element = fields[index];
            if (element.DisplayPosition === "x,x") {
                rowPosition += 1;
                element.DisplayPosition = `0,${rowPosition}`
                
            }
        }

        let results: DialogField[] = []

        fields.forEach(f => {
            const controlIndex = controlFields.findIndex(field => field.FieldName == f.FieldName);
            if (controlIndex >=0 ) {
                f.disabled  = controlFields[controlIndex].disabled;
            }
            const index = results.findIndex(field => field.FieldName === f.FieldName);
            if (index < 0) {
                results.push(f);
            }
        });

        fields =[...results]

        const rowsFields = fields.map(x=>  parseInt(x.DisplayPosition.split(",")[1]) as number)
        const maxRow = Math.max(...rowsFields);
        if (action.NumberOfRows < maxRow) {
            action.NumberOfRows = maxRow + 1;
        }

        if (fields.length > action.NumberOfRows) {
            action.NumberOfRows = fields.length
        }

        resetAppData();

        setDialog({
            name: action.ActionName,
            header: __t(action, "ActionLabel"),
            fields,
            width: action.ActionDialogWidth || process.env.REACT_APP_LAYOUT_INPUT === "grid" ? "fit-content" : '500px',
            height: action.ActionDialogHeight || '700px',
            numberOfColumns: action.NumberOfColumns || 1,
            numberOfRows: action.NumberOfRows || fields.length,
            APIName: action.APIName,
            dialogQueueSelector,
            rowData,
            postActions: action?.PostActions,
            requiredConfirmation: action.RequiredConfirmation,
            isNeedResubmit: false,
            isLoadingCallApi: false, 
            isSuccessCallApi: false, 
            isErrorCallApi: false,
            progressQueue: []
        });

    }
};
