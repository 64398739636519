/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import { ObjectID } from 'bson';
import { EMIT_DATA, RESET_EMIT_DATA } from "../constants/beacon";

const initialState = {
    type: EMIT_DATA,
    payload: {
    } as any
}

export function emitData(dispatch: Function, sourcePanel: string, data: any) {
    return new Promise(async (resolve, reject) => {

        const newData = {
            [sourcePanel]: {
                emitKey: new ObjectID().toString(),
                source: sourcePanel,
                data
            }
        }

        Object.assign(initialState, {
            type: EMIT_DATA,
            payload: {
                ...initialState.payload,
                emitKey: new ObjectID().toString(),
                source: sourcePanel,
                data,
                ...newData,
            }
        })

        resolve(dispatch(initialState))

        // resolve(
        //     dispatch({
        //     type: EMIT_DATA,
        //     payload: {
        //         emitKey: new ObjectID().toString(),
        //         source: sourcePanel,
        //         data,
        //         [sourcePanel]: {
        //             emitKey: new ObjectID().toString(),
        //             source: sourcePanel,
        //             data
        //         }
        //     }
        // }))
    });
}

export const resetEmitData = (dispatch: Function, sourcePanel?: string) : Promise<any> => {

    console.log("initialState Reset", initialState);
    
    return new Promise(async (resolve, reject) => {
        resolve(
            dispatch({
                ...{type: RESET_EMIT_DATA },
                ...(sourcePanel && {
                    payload: {
                        emitKey: new ObjectID().toString(),
                        source: sourcePanel,
                        type: "RESET_DATA"
                    }
                })
            })
        )
    });
};
