/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, {useEffect, useState} from 'react';

import {PanelFieldData} from '../../../../../models/classes/PanelFieldData';
import {IFieldValue} from '../../../../../models/interfaces/IFieldValue';

import {ISubscribeComponent} from '../../../../../models/classes/PanelData';
import openSocket from 'socket.io-client';
import {isEmpty} from '../../../../../utils/validation';
import Field, { IFieldProps } from '../../../../../components/Field';
import __t from '../../../../../utils/translation';
import { Controller, useForm } from 'react-hook-form';

interface Props {
    numberOfRows: number;
    numberOfColumns: number;
    panelFields: PanelFieldData[];
    onSearchFieldValuesChanged: Function;
    onControlSet: Function;
    onSubmit?: Function;
    panelSubscribeComponents: ISubscribeComponent[];
    chartName?: string;
    container?: any;
}

const PanelSearchFieldComponent: React.FC<Props> = (props) => {
    const [fieldValues, setFieldValues] = useState<IFieldValue[]>([]);
    const [dummyState, triggerReRender] = useState(false);
    const { control, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        mode: 'all'
    });

    const {
        numberOfColumns,
        numberOfRows,
        panelFields,
        onSearchFieldValuesChanged,
        panelSubscribeComponents,
        onSubmit,
        onControlSet,
        chartName,
        container
    } = props;

    useEffect(() => {
        setFieldValues([]);
        panelFields.forEach(field => { // Init states value
            setFieldValues(fieldValues => [...fieldValues, {
                fieldName: field.FieldName,
                fieldType: field.InputType,
                value: null,
                dataType: field.DataType
            }]);
        });
    }, [panelFields]);

    useEffect(() => {
        if (fieldValues) onSearchFieldValuesChanged(fieldValues);
        // eslint-disable-next-line
    }, [fieldValues, props]);

    useEffect(()=> {
        let sockets: SocketIOClient.Socket[] = [];

        panelSubscribeComponents?.forEach((subscribeInfo) => {
            if (subscribeInfo?.SourceType === "Socket") {
                subscribeInfo?.SocketTopics?.forEach((topic) => {
                    const socket = openSocket(
                        process.env.REACT_APP_WS_URL!,
                        {
                            path: process.env.REACT_APP_WS_PATH ?? '/circlebi.io',
                            transports: ['websocket', 'polling'],
                            query: `TopicName=${topic}`
                        }
                    );

                    socket.on('connect', () => {
                        socket.emit('subscribeToEvent', 1000);
                    });

                    socket.on('RealTimeDataPayload', (data: any) => {
                        console.log("New Data", data);

                        subscribeInfo?.SocketOptions?.FieldsToReceiveData?.forEach((fieldToReceiveData) => {
                            const fieldIndex = fieldValues.findIndex(field => field.fieldName === fieldToReceiveData.FieldName);
                            let newFieldValues = fieldValues;

                            newFieldValues[fieldIndex].value = data;

                            console.table(newFieldValues);

                            setTimeout(() => {
                                setFieldValues(newFieldValues);
                            }, 200);
                            setTimeout(() => triggerReRender(!dummyState), 350);
                        });
                    });

                    sockets.push(socket);
                });
            }
        });

        return () => {
            // close socket on component unMount
            if (!isEmpty(sockets)) {
                sockets.forEach((socket) => {
                    try {
                        socket.close();
                    } catch (e) {
                        console.error("error closing socket", e);
                    }
                });
            }
        }

        // eslint-disable-next-line
    },[fieldValues, panelSubscribeComponents]);

    const renderFields = (x: number, y: number): JSX.Element | null => {
        const displayPosition: string = x.toString() + "," + y.toString();
        // Find field data inside panel's fields
        const fieldIndex: number = panelFields
            .findIndex(field => field.DisplayPosition === displayPosition);
        const formField: PanelFieldData = panelFields[fieldIndex];

        let fieldId = displayPosition;
        let fieldRef: any = React.createRef();
        let customProps = {
            chartName,
            container,
            defaultValue: formField?.DefaultValue,
            style: {
                width: formField?.FieldWidth? formField.FieldWidth: "initial",
                ...(!isEmpty(formField?.ValueFormatStyle)) && { ...formField?.ValueFormatStyle }
            },
            setError,
            clearErrors
        };

        if (fieldValues[fieldIndex]) {

            let props : IFieldProps = {
                fieldId, fieldRef, fieldIndex, field: formField, fieldValues, setFieldValues, customProps, container
            }
            
            if (onControlSet) {
                onControlSet(control)
            }

            return (
                <div style={{ display: 'grid' }}>
                    <div
                        ref={fieldRef}
                        style={{
                            whiteSpace: "nowrap",
                            display: "flex",
                            flexDirection: formField?.InputType === "DisplayText" || formField?.InputType === "FileUpload" ? "row" : process.env.REACT_APP_LAYOUT_INPUT === "grid" ? "column" : "row",
                            alignItems: formField?.InputType === "FileUpload" ? undefined : "flex-start",
                            justifyContent: formField?.JustifyContent ?? "space-between",
                            visibility: formField?.Hidden ? "hidden" : "visible"
                        }}
                    >
                        <label style={{...formField?.LabelFormatStyle}}>{__t(formField, "FieldLabel")}</label>
                        <Controller 
                            name={formField?.FieldName} 
                            control={control}
                            rules={formField?.Mandatory ? { required: (__t(formField, "FieldLabel") || formField?.FieldName) + ' is required.' } : undefined}
                            defaultValue={!isEmpty(formField?.DefaultValue) ? formField?.DefaultValue : fieldValues[fieldIndex].value}
                            render={({ field, fieldState }) => (    
                                    ///render={(field: ControllerRenderProps<Record<string, any>>, state: FieldState) => (
                                <Field {...props} register={field} state={fieldState} />
                            )} />
                    </div>
                    {errors[formField.FieldName] && <small className="p-error">{errors[formField.FieldName!]!.message}</small>}
                </div>

            );
        }

        return null
    };

    const renderColumns = (yIndex: number): JSX.Element[] => {
        return [...Array(numberOfColumns)].map((x, xIndex) => (
            <div key={xIndex.toString() + "," + yIndex.toString()} className="p-col">
                {renderFields(xIndex, yIndex)}
            </div>
        ));
    };

    const renderGrids = (): JSX.Element[] => {
        return (
            [...Array(numberOfRows)].map((y, yIndex) =>
                <div key={yIndex} className="p-grid p-justify-between">
                    {renderColumns(yIndex)}
                </div>
            )
        );
    };

    const onValidSubmit = (data: any) => {
        if (onSubmit) {
            onSubmit(data)
        }
    };

    return <form onSubmit={handleSubmit(onValidSubmit)}><div>{renderGrids()}</div></form>
};

export default PanelSearchFieldComponent;
