/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 
const has = Object.prototype.hasOwnProperty;
const toString = Object.prototype.toString;

export const isNull = (val: any): boolean => {
    return val == null;
};

export const isEmpty = (val: any): boolean => {
    // Null and Undefined...
    if (val == null) return true;

    // Booleans...
    if (typeof val === 'boolean') return false;

    // Numbers...
    if (typeof val === 'number') return false; // Changed from val === 0 to false

    // Strings...
    if (typeof val === 'string') return val.length === 0;

    // Functions...
    if (typeof val === 'function') return val.length === 0;

    // Arrays...
    if (Array.isArray(val)) return val.length === 0;

    // Errors...
    if (val instanceof Error) return val.message === '';

    // Objects...
    if (val.toString === toString) {
        switch (val.toString()) {
            // Maps, Sets, Files and Errors...
            case '[object File]':
            case '[object Map]':
            case '[object Set]': {
                return val.size === 0;
            }

            // Plain objects...
            case '[object Object]': {
                for (let key in val) {
                    if (has.call(val, key)) return false;
                }
                return true;
            }
        }
    }

    // Anything else...
    return false;
};
