import _ from "lodash";

export default function __t(source: any, find: string) {
    const lang = window.localStorage.getItem("Language");
    if (lang) {
        const localeData = _.get(source, "Locale", null) || _.get(source, "locale", {})
        if (localeData && localeData[lang]) {
            return _.get(localeData[lang], find)            
        }
        return _.get(source, find);
    }
    return _.get(source, find);
}