export class ArrayManipulator<T> {
    array: T[];

    constructor(array: T[]) {
        this.array = array || [];
    }

    // Function to move an object to the top of the array and return the last index of the item position
    moveToTop(item: T): number {
        if (!item || !this.array.includes(item)) return -1;
        const index = this.array.indexOf(item);
        this.array.splice(index, 1);
        this.array.unshift(item);
        return 0;
    }

    // Function to move an object to the last position of the array and return the last index of the item position
    moveToLast(item: T): number {
        if (!item || !this.array.includes(item)) return -1;
        const index = this.array.indexOf(item);
        this.array.splice(index, 1);
        this.array.push(item);
        return this.array.length - 1;
    }

    // Function to move an object up one row in the array and return the last index of the item position
    moveUpOneRow(item: T): number {
        if (!item || !this.array.includes(item)) return -1;
        const index = this.array.indexOf(item);
        if (index > 0) {
            const temp = this.array[index];
            this.array[index] = this.array[index - 1];
            this.array[index - 1] = temp;
        }
        return index > 0 ? index - 1 : index;
    }

    // Function to move an object down one row in the array and return the last index of the item position
    moveDownOneRow(item: T): number {
        if (!item || !this.array.includes(item)) return -1;
        const index = this.array.indexOf(item);
        if (index < this.array.length - 1) {
            const temp = this.array[index];
            this.array[index] = this.array[index + 1];
            this.array[index + 1] = temp;
        }
        return index < this.array.length - 1 ? index + 1 : index;
    }
}
